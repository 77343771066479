import { ChangeDetectorRef, Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

import { debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Farm } from 'app/core/models/admin/farms.model'
import { SalesService } from './sales.service';
import { UserService,User } from 'app/core/services/auth/user.service';
import { map, startWith } from 'rxjs/operators';
import { Observable,forkJoin } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortModule, MatSortHeader } from '@angular/material/sort';
import{SearchComponent} from'./search/search.component';
import { SalesDialogComponent } from './sales-dialog/sales-dialog.component';
import { FarmsService } from 'app/core/services/admin/farms.service';
import { FilterModel } from 'app/core/models/admin/filter.models';
import { filter, result } from 'lodash';
import { __awaiter } from 'tslib';
@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  vendas: any[] = [];
  vendedores: any[] = [];
  vendasFiltradas: any[] = [];
  vendedoresFiltrados: any[] = [];
  filtroForm: FormGroup;
  public statusSelecionado: string = '';
  loading: boolean = false;
  filteredVendas: Observable<any[]>;
  vendaControl = new FormControl();
  dataSource: MatTableDataSource<any>;
  vendedorLogado:string;
  ordenacaoCrescente = false;
  isClient = false;
  isComercial = false;
  isAdmin = false;
  ordenacaoCrescenteCardName:boolean = false;
  currentUser: User;
  ordenacaoCrescenteDocNum:boolean = false;
  farms$: Observable<Farm[]>;
  farms: Farm[];
  erpIds: any[] = [];
  linhasSelecionadas:  any;
  filteredFilters: FilterModel[] = []; 



  constructor(private salesServices:SalesService, public dialog: MatDialog, private userAuth: UserService,
    private _farmsService: FarmsService,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,) { 
    
    
  }

  async ngOnInit(): Promise<void> {
    await this.carregarVendas();
   
    this.changeDetectorRef.detectChanges();
    this.filtroForm = this.fb.group({
      DocNum: [''],
      DocDate: [''],
      CardName: [''],
      CardCode: [''],
      DocStatus: [''],
      CANCELED: [''],
      DocEntry: ['']
    });
  


  this.filteredVendas = this.vendaControl.valueChanges.pipe(
    startWith(''),
    map(value => typeof value === 'string' ? value : value?.CardName),
    map(nome => nome ? this._filterVendas(nome) : this.vendas.slice())
  );
}

private _filterVendas(nome: string): any[] {
  const filterValue = nome?.toLowerCase();
  return this.vendas.filter(venda => venda.CardName.toLowerCase().includes(filterValue));
}
  async carregarVendas(): Promise<void> {
    this.loading = true;
    try {
      const user = await this.userAuth.get().toPromise();
      this.currentUser = user;
      this.isClient = user.roles.some(r => r.scope === 'cliente');
      this.isComercial = user.roles.some(r => r.scope === 'comercial');
      this.isAdmin = user.roles.some(r => r.scope === 'admin');

      if (this.isClient) {
        this.farms = await this._farmsService.searchFarmByUser(user.id).toPromise();
        this.erpIds = this.farms.map(farm => farm.erp_id);
        await this.carregarVendasPorCliente();
      } else if (this.isComercial) {
        this.carregarVendedor();

      } else if (this.isAdmin) {
        await this.carregarVendasAdmin();
      } 
    } catch (error) {
      console.error('Erro ao carregar vendas:', error);
    } finally {
      
    }
  }

  async carregarVendasPorCliente(): Promise<void> {
    this.loading=true;
    const observables = this.farms.map(farm =>
      this.salesServices.filtrarPedidoPorCardCode(farm.erp_id)
    );

    try {
      const resultados = await forkJoin(observables).toPromise();
      this.vendas = resultados.reduce((acc, curr) => acc.concat(curr), []);
      this.vendasFiltradas = [...this.vendas];
      this.loading=false;
    } catch (error) {
      console.error('Erro ao carregar vendas por CardCode:', error);
    }
  }

  async carregarVendasAdmin(): Promise<void> {
    this.loading=true;
    try {
      this.vendas = await this.salesServices.carregarVendas().toPromise();
      this.vendasFiltradas = [...this.vendas];
      this.loading=false;
    } catch (error) {
     
    }
  }
  filtrarPedidosPorCliente(): void {
    const clienteFiltro = this.vendaControl.value;
    const filterValue = clienteFiltro ? clienteFiltro.toLowerCase() : '';
    if (filterValue && typeof filterValue === 'string') {
      this.vendasFiltradas = this.vendas.filter(venda => venda.CardName && venda.CardName.toLowerCase().includes(filterValue));
    } else {
      this.vendasFiltradas= [...this.vendas];
    }
  }

  async carregarVendedor(): Promise<void> {
    this.loading = true;


    this.salesServices.obterVendedores().subscribe(
        vendedores => {
           
            this.vendedores = vendedores;
            this.vendedoresFiltrados = [...this.vendedores];
            this.loading = false;

            const email = this.currentUser.email; 
            this.selecionarVendedor(email);
        },
        error => {
            console.error('Erro ao obter vendedores:', error);
            this.loading = false;
        }
    );
}

filtrarVendedores(): void {
 
    const { SlpCode, SlpName, Email } = this.filtroForm.value;


    this.vendedoresFiltrados = this.vendedores.filter(vendedor => {
        const matchSlpCode = SlpCode ? vendedor.SlpCode.toLowerCase().includes(SlpCode.toLowerCase()) : true;
        const matchSlpName = SlpName ? vendedor.SlpName.toLowerCase().includes(SlpName.toLowerCase()) : true;
        const matchEmail = Email ? (vendedor.Email || '').toLowerCase().includes(Email.toLowerCase()) : true;
       

        return matchSlpCode && matchSlpName && matchEmail;
    });


}

selecionarVendedor(email: string) {
    const emailSelecionado = email.trim().toLowerCase();
   

    const vendedor = this.vendedores.find(v => {
        const emailVendedor = v.Email?.trim().toLowerCase();
 
        return emailVendedor === emailSelecionado;
    });

    if (vendedor) {
        this.vendedorLogado = vendedor.Email;
    
        this.carregarVendasPorComercial();
    } else {
        console.warn('Nenhum vendedor encontrado para o e-mail:', emailSelecionado);
    }
}

async carregarVendasPorComercial(): Promise<void> {
    this.loading = true;
        this.vendas = await this.salesServices.filtrarPedidoPorVendedor(this.vendedorLogado).toPromise();
        this.vendasFiltradas = [...this.vendas];
        this.filtrarVendas();
        this.loading = false;
  
}


  filtrarVendas(): void {
    const { DocNum, DocDate, CardName, CardCode, DocStatus, CANCELED,ValorPendente,U_GrupoEconomico } = this.filtroForm.value;
    this.vendasFiltradas = this.vendas.filter(venda => {
      return (DocNum ? venda.DocNum.toLowerCase().includes(DocNum.toLowerCase()) : true) &&
             (DocDate ? venda.DocDate.toLowerCase().includes(DocDate.toLowerCase()) : true) &&
             (CardName ? (venda.CardName || '').toLowerCase().includes(CardName.toLowerCase()) : true) &&
             (CardCode ? (venda.CardCode || '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
             (DocStatus ? (venda.DocStatus || '').toLowerCase().includes(DocStatus.toLowerCase()) : true) &&
             (CANCELED ? (venda.CANCELED || '').toLowerCase().includes(CANCELED.toLowerCase()) : true)&&
             (ValorPendente?(venda.ValorPendente|| '').toLowerCase().includes(ValorPendente.toLowerCase()):true)&&
             (U_GrupoEconomico?(venda.U_GrupoEconomico|| '').toLowerCase().includes(U_GrupoEconomico.toLowerCase()):true);
    });
    
  }
 
  selecionarLinha(linha: any): void {
    this.linhasSelecionadas = linha;
    if (linha && linha.DocNum) {
      const vendaSelecionado = this.vendasFiltradas.find(venda => venda.DocNum === linha.DocNum);
      if (vendaSelecionado) {
        this.openDialog(vendaSelecionado);
      }
    }
  }

  openDialog(venda): void {
    this.loading = true;
    this.salesServices.vendaDetails(venda.DocEntry).subscribe(
      detalhes => {
        const dialogRef = this.dialog.open(SalesDialogComponent, { data: detalhes ,disableClose: true,});
        dialogRef.afterClosed().subscribe(() => {});
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
 

  searchDialog() {
    const dialogRef = this.dialog.open(SearchComponent, { disableClose: true,});
    

    dialogRef.afterClosed().subscribe((result: any[]) => {
      if (result) {
        this.loading = true; 
        this.aplicarFiltro(result); 

      }
    });
  

    dialogRef.componentInstance.filterApplied.subscribe((filters: FilterModel[]) => {

      this.loading = true; 
      this.aplicarFiltro(filters);
    });
  } 

 
  aplicarFiltro(filters: any) {
    if (!filters || filters.length === 0) {
      return; 
    }
  

    if (this.isComercial && this.vendedorLogado) {
      filters.push({
        field: 'nomeVendedor',
        filter: this.vendedorLogado,
        value: this.vendedorLogado
      });
    }
  
 
 
    const filtroCardCode = filters.find((filter: any) => filter.field === 'CardCode');
    const hasCardCodeFilter = !!filtroCardCode;
  
  
    let vendasFiltradasTotal: any[] = [];
  
    const selectedStatus = filters.find(f => f.field === 'status')?.value;

  
    if (this.isClient) {
      const cardCodes = hasCardCodeFilter ? [filtroCardCode.filter] : Array.from(new Set(this.vendas.map(venda => venda.CardCode)));
  
      const observables = cardCodes.map(cardCode => {
        let queryParams = '';
  
        filters.forEach((filter) => {
          if (filter.field !== 'CardCode' && filter.field !== 'status' && filter.value && filter.value !== '') {
            const encodedValue = encodeURIComponent(filter.filter);
            queryParams += `${filter.field}=${encodedValue}&`;
          }
        });
  
        queryParams += `CardCode=${encodeURIComponent(cardCode)}`;
        queryParams = queryParams.replace(/&$/, '');

  
        if (queryParams) {
          const url = `/api/consulta/listaPedidos?${queryParams}`;

          this.loading = true;
          return this.salesServices.buscarDados(url);
        }
      });
  
      if (observables.length > 0) {
        forkJoin(observables).subscribe(
          (responses: any[]) => {
            responses.forEach(response => {
              vendasFiltradasTotal = vendasFiltradasTotal.concat(response);
            });
  
            if (selectedStatus && selectedStatus.length > 0) {
              if (Array.isArray(selectedStatus)) {
                vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
                  selectedStatus.includes(this.getStatus(venda.DocStatus, venda.CANCELED))
                );
              } else {
                vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
                  this.getStatus(venda.DocStatus, venda.CANCELED) === selectedStatus
                );
              }
            }
  

            this.vendasFiltradas = vendasFiltradasTotal;
            this.loading = false;
          },
          error => {
            this.loading = false;
  
            vendasFiltradasTotal = this.vendas; 
            if (selectedStatus && selectedStatus.length > 0) {
              if (Array.isArray(selectedStatus)) {
                vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
                  selectedStatus.includes(this.getStatus(venda.DocStatus, venda.CANCELED))
                );
              } else {
                vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
                  this.getStatus(venda.DocStatus, venda.CANCELED) === selectedStatus
                );
              }
            }
 
            this.vendasFiltradas = vendasFiltradasTotal;
          }
        );
      } else {

        vendasFiltradasTotal = this.vendas; 
        if (selectedStatus && selectedStatus.length > 0) {
          if (Array.isArray(selectedStatus)) {
            vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
              selectedStatus.includes(this.getStatus(venda.DocStatus, venda.CANCELED))
            );
          } else {
            vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
              this.getStatus(venda.DocStatus, venda.CANCELED) === selectedStatus
            );
          }
        }

        this.vendasFiltradas = vendasFiltradasTotal;
      }
    } else {

      let queryParams = '';
  
      filters.forEach((filter) => {
        if (filter.field !== 'CardCode' && filter.field !== 'status' && filter.value && filter.value !== '') {
          const encodedValue = encodeURIComponent(filter.filter);
          queryParams += `${filter.field}=${encodedValue}&`;
        }
      });
  
      queryParams = queryParams.replace(/&$/, '');

  
      if (queryParams) {
        const url = `/api/consulta/listaPedidos?${queryParams}`;

        this.loading = true;
        this.salesServices.buscarDados(url).subscribe(
          (response: any) => {
            vendasFiltradasTotal = response;
  
            if (selectedStatus && selectedStatus.length > 0) {
              if (Array.isArray(selectedStatus)) {
                vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
                  selectedStatus.includes(this.getStatus(venda.DocStatus, venda.CANCELED))
                );
              } else {
                vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
                  this.getStatus(venda.DocStatus, venda.CANCELED) === selectedStatus
                );
              }
            }
  

            this.vendasFiltradas = vendasFiltradasTotal;
            this.loading = false;
          },
          error => {
            this.loading = false;
            console.error('Erro ao buscar dados da API:', error);
  
  
            vendasFiltradasTotal = this.vendas;  
            if (selectedStatus && selectedStatus.length > 0) {
              if (Array.isArray(selectedStatus)) {
                vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
                  selectedStatus.includes(this.getStatus(venda.DocStatus, venda.CANCELED))
                );
              } else {
                vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
                  this.getStatus(venda.DocStatus, venda.CANCELED) === selectedStatus
                );
              }
            }
    
            this.vendasFiltradas = vendasFiltradasTotal;
          }
        );
      } else {

        vendasFiltradasTotal = this.vendas; 
        if (selectedStatus && selectedStatus.length > 0) {
          if (Array.isArray(selectedStatus)) {
            vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
              selectedStatus.includes(this.getStatus(venda.DocStatus, venda.CANCELED))
            );
            this.loading=false;

          } else {
            vendasFiltradasTotal = vendasFiltradasTotal.filter(venda =>
              this.getStatus(venda.DocStatus, venda.CANCELED) === selectedStatus
            );
            this.loading=false;
          }
        }

        this.vendasFiltradas = vendasFiltradasTotal;
      }
    }
  }
  
  
  
 
  
  
  
  
  
  
  
  
  
  
  
  
  limparFiltros(): void {
    this.vendasFiltradas = [...this.vendas]; 
  }
  

  filtrarPorCampo(venda: any, campoFiltro: string, valorFiltro: string): boolean {
    let campoVendas: string;
  
 
    switch (campoFiltro) {
      case 'CardName':
        campoVendas = 'CardName';
        break;
      case 'CardCode':
        campoVendas = 'CardCode';
        break;
      case 'DocEntry':
        campoVendas = 'DocEntry';
        break;

      default:
        campoVendas = campoFiltro;
    }

  

    if (venda[campoVendas]) {
      return venda[campoVendas].toString().includes(valorFiltro);
    }
  
    return false;
  }
  

  ordenarPorData(): void {
    if (this.ordenacaoCrescente) {
      this.vendasFiltradas = this.vendas.sort((a, b) => new Date(b.DocDate).getTime() - new Date(a.DocDate).getTime());
    } else {
      this.vendasFiltradas = this.vendas.sort((a, b) => new Date(a.DocDate).getTime() - new Date(b.DocDate).getTime());
    }
    this.ordenacaoCrescente = !this.ordenacaoCrescente;
  }

  ordenarPorCardName(): void {
    if (this.ordenacaoCrescenteCardName) {
      this.vendasFiltradas = this.vendas.sort((a, b) => new String(b.CardName).localeCompare(a.CardName));
    } else {
      this.vendasFiltradas = this.vendas.sort((a, b) => new String(a.CardName).localeCompare(b.CardName));
    }
    this.loading = false;
    this.ordenacaoCrescenteCardName = !this.ordenacaoCrescenteCardName;
  }

  ordenarPorDocNum(): void {
    if (this.ordenacaoCrescenteDocNum) {
      this.vendasFiltradas = this.vendas.sort((a, b) => a.DocNum - b.DocNum);
    } else {
      this.vendasFiltradas = this.vendas.sort((a, b) => b.DocNum - a.DocNum);
    }
    this.ordenacaoCrescenteDocNum = !this.ordenacaoCrescenteDocNum;
  }

  getStatus(status: string, CANCELED: string): string {
    if (status === 'C') {
      if (CANCELED === 'Y') {
        return 'Cancelado';
      } else if (CANCELED === 'N') {
        return 'Concluido';
      } else {
        return 'Desconhecido';
      }
    } else if (status === 'O') {
      return 'Saldo a Entregar';
    } else {
      return 'Desconhecido';
    }
  }

  getStatusColor(status: string, CANCELED: string): string {
    if (status === 'C') {
      if (CANCELED === 'Y') {
        return 'red';
      } else if (CANCELED === 'N') {
        return 'green';
      } else {
        return 'gray';
      }
    } else if (status === 'O') {
      return 'blue';
    } else {
      return 'Desconhecido';
    }
  }

  filtrarPorStatus(status: string): void {
    this.statusSelecionado = status;
    if (status) {
      this.vendasFiltradas = this.vendas.filter(venda => this.getStatus(venda.DocStatus, venda.CANCELED) === status);
    } else {
      this.vendasFiltradas = [...this.vendas];
    }
  }

}