import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, map, Observable, Subject, throwError } from 'rxjs';
import { error } from 'console';
@Injectable({
  providedIn: 'root'
})
export class ChecklistService {
  private _selectPanel: Subject<any> = new Subject();
  apiUrl: string = '';
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.operHost;
  }
  get selectPanel$(): Observable<any> {
    return this._selectPanel.asObservable();
  }

  doSelectPanel(panel: string): any {
    this._selectPanel.next(panel);
  }

  carregarTipos():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+'/api/v1/checklistTypes').pipe(map(
      response =>{
        return response|| [];
      }),
      catchError(error=>{

        return throwError(error);
      })
  );
  }

  carregarQuestions():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+'/api/v1/question').pipe(map(
      response =>{
        return response|| [];
      }),
      catchError(error=>{

        return throwError(error);
      })
  );
  }

  carregarSecao():Observable<any[]>{

    return this.http.get<any>(this.apiUrl+'/api/v1/section').pipe(map(
      response =>{
        return response|| [];
      }),
      catchError(error=>{

        return throwError(error);
      })
  );
  }


  editarSessao(id:any,body: any):Observable<any>{

    const url = `${this.apiUrl}/api/v1/section/${id}`;
    return this.http.put(url, body);
  }


  editarQuestions(id:any,body: any):Observable<any> {

    const url = `${this.apiUrl}/api/v1/question/${id}`;
    return this.http.put(url, body);
  }


   editarTipo(id:any,body: any):Observable<any> {
    const url = `${this.apiUrl}/api/v1/checklistTypes/${id}`;
    return this.http.put(url, body);
  }

  criarType(body: any):Observable<any>{
    const url = `${this.apiUrl}/api/v1/checklistTypes`;
    return this.http.post(url, body);
  }
  criarQuestion(body: any):Observable<any>{
    const url = `${this.apiUrl}/api/v1/question`;
    return this.http.post(url, body);
  }

  criarCheckList(body: any):Observable<any>{
    const url = `${this.apiUrl}/api/v1/checklist`;
    return this.http.post(url, body);
  }
  criarSection(body: any):Observable<any>{
    const url = `${this.apiUrl}/api/v1/section`;
    return this.http.post(url, body);
  }

  questionsId(id:number):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/question/${id}`;
    return this.http.get<any>(url);
  }

  typeId(id:number):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/checklistTypes/${id}`;
    return this.http.get<any>(url);
  }

  sectionId(id:number):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/section/${id}`;
    return this.http.get<any>(url);
  }

  deleteSecion(id:number):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/section/${id}`;
    return this.http.delete<any>(url);
  }

  deleteQuestion(id:number):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/question/${id}`;
    return this.http.delete<any>(url);
  }

  deleteType(id:number):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/checklistTypes/${id}`;
    return this.http.delete<any>(url);
  }

  questionSectionId(id:number):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/question?section_id=${id}`;
    return this.http.get<any>(url);
  }
}
