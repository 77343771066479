<div class=" home ">
  <div *ngIf="loading" class="loading-overlay">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
  </div>
    <div class="header">

      <div class="title"> Orçamento De Venda <br>
        <div  class="title-cabecalho">
          Cotação de Venda 
        </div>
    </div>
  <div class="buttons">
    <button
    routerLink="/sales"
    class="px-4 py-2 border border-gray-200 bg-slate-100 hover:bg-slate-200 rounded-full font-bold text-gray-600">
    Voltar
    </button>
    <div class="buttons_search">
      <button mat-flat-button=""
          class="mat-focus-indicator ml-4 mat-flat-button mat-button-base text-green-200 bg-green-350 dark:text-green-50 dark:bg-green-500 ng-star-inserted"
          ng-reflect-color="primary" (click)="searchDialog()">
    <span class="mat-button-wrapper">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <span class="ml-2 mr-1 text-white">Filtrar</span>
    </span>
    <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
    </span>
    <span class="mat-button-focus-overlay">
    </span>
  </button>
  <button mat-flat-button class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" style="background-color: #6c757d; color: white;"
   (click)="limparFiltros()">
  <span class="mat-button-wrapper">
  
  <span class="ml-2 mr-1 text-white">Limpar Filtros</span>
  </span>
  <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
  </span>
  <span class="mat-button-focus-overlay">
  </span>
  </button>
      </div>
  </div>

    </div>
    <div class=" header-content">
        <div class="content" *ngIf="!isClient">
          <mat-form-field class="campos" appearance="outline">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <mat-label class="label">Cliente</mat-label>
           
            <input type="text" matInput [formControl]="orcamentoControl" (input)="filtrarOrcamentosPorCliente()">
          </mat-form-field>
              
          <button mat-flat-button="" 
         *ngIf="isComercial || isAdmin"
          class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" 
          ng-reflect-color="primary"
          (click)="createSalesOrder()" scrollStrategy role="dialog">
            <span class="mat-button-wrapper">
            <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="edit" data-mat-icon-namespace="heroicons_outline">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                  </svg>
                </mat-icon>
                <span class="ml-2 mr-1">
                    Nova Venda</span>
            </span>
            <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
          
            </span>
            <span class="mat-button-focus-overlay">
            </span>
          </button>
       
        </div>
    </div>
    <div class="table-vendas" appearance="">
      <table>
        <thead>
          <tr>
            <th>N Cliente</th>
            <th>
              <div class="tab-arrow">
                <button mat-icon-button (click)="ordenarPorCardName()">
                  <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                      <path d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z"/>
                    </svg>
                  </mat-icon>
                </button>  
                <span>Cliente</span>
              </div>
            </th>
            <th>
              <div class="tab-arrow">
                <button mat-icon-button (click)="ordenarPorData()">
                  <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                      <path d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z"/>
                    </svg>
                  </mat-icon>
                </button>  
                <span>Data Venda</span>
              </div>
            </th>
            <th>
              <div class="tab-arrow">
                <button mat-icon-button (click)="ordenarPorDocNum()">
                  <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                      <path d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z"/>
                    </svg>
                  </mat-icon>
                </button>  
                <span>N Orçamento</span>
              </div>
            </th>
            <th>
              <div class="tab-arrow">
                <button mat-icon-button [matMenuTriggerFor]="statusMenu">
                  <mat-icon
                                                    class="icon-size-2"
                                                    [svgIcon]="
                                                        'heroicons_solid:sort-descending'
                                                    "></mat-icon>
                </button>
                <mat-menu #statusMenu="matMenu">
                  <button mat-menu-item (click)="filtrarPorStatus('Cancelado')">Cancelado</button>
                  <button mat-menu-item (click)="filtrarPorStatus('Aprovado')">Aprovado</button>
                  <button mat-menu-item (click)="filtrarPorStatus('Modificações Solicitadas Pendentes')">Modificações Solicitadas Pendentes</button>
                  <button mat-menu-item (click)="filtrarPorStatus('Pendente de Aprovação')">Pendente de Aprovação</button>
                  <button mat-menu-item (click)="filtrarPorStatus('Desconhecido')">Desconhecido</button>
                  <button mat-menu-item (click)="filtrarPorStatus('')">Mostrar Todos</button>
                </mat-menu>
                <span>Status</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let orcamento of orcamentosFiltrados" (click)="selecionarLinha(orcamento)" [class.selected]="linhasSelecionadas === orcamento">
            <td>{{ orcamento.CardCode }}</td>
            <td>{{ orcamento.CardName }}</td>
            <td>{{ orcamento.DocDate | date: 'dd/MM/yyyy' }}</td>
            <td>{{ orcamento.DocNum }}</td>
            <td [style.color]="getStatusColor(orcamento.DocStatus, orcamento.CANCELED, orcamento.modificacoesSolicitadas)">
              {{ getStatus(orcamento.DocStatus, orcamento.CANCELED, orcamento.modificacoesSolicitadas) }}
            </td>
          </tr>
          <tr *ngIf="orcamentosFiltrados.length === 0">
            <td colspan="5" class="no-data">Nenhum Orçamento disponível</td>
          </tr>
        </tbody>
      </table>
    </div>
</div>

