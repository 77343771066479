import { Component, OnInit, EventEmitter, Inject, Output} from '@angular/core';
import{  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {  map, startWith } from 'rxjs';
import { FormGroup,FormControl,Validators, FormBuilder } from '@angular/forms';
import { Farm } from 'app/core/models/admin/farms.model';
import { FarmsService } from 'app/core/services/admin/farms.service';
import{  take} from 'rxjs/operators';
import { FilterModel } from 'app/core/models/admin/filter.models';
import { OpenSalesService } from '../open-sales.service';
import { UserService} from 'app/core/services/auth/user.service';
import { User } from 'app/core/models/auth/user.model';
import moment from 'moment';
@Component({
  selector: 'app-search-orcamento',
  templateUrl: './search-orcamento.component.html',
  styleUrls: ['./search-orcamento.component.scss']
})
export class SearchOrcamentoComponent implements OnInit {
  @Output() filterApplied= new EventEmitter();
  loading: boolean = false;
  cliente:any = null;
  clientes:any[]=[];
  vendas:any[]=[];
  vendasFiltradas: any[] = [];
  clientesFiltrados:any[]=[];
  clienteControl=new FormControl();
  orderNumberControl = new FormControl('');
  farmControl = new FormControl('');
  statusControl = new FormControl([]);
  orderFilter: FilterModel[] = [];
  filteredClientes: any;
  selectedClientName: string = '';
  formFilter: FormGroup;
  public statusSelecionado: string = '';
  filtroForm:FormGroup;
  protected farms: Farm[] = [];
  currentUser: User;
  orcamentosFiltrados:any[]=[];
  isClient = false;
  isComercial= false;
  clientFullName: string;

  orcamentos:any[]=[];
  constructor(public dialogRef:MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private openSales:OpenSalesService,
    private _farmService: FarmsService,
    private fb: FormBuilder,
    private userAuth: UserService,
  ) {  
 }


    async ngOnInit(): Promise<void> {
      this.filtroForm = this.fb.group({
        DocEntry: [null],
        CardName: [null],
        CardCode: [null],
        status: [null],
        DocDateStart: [null],
        DocDateEnd: [null]
      }); 
    
    await this.carregarVendas();
    await this.carregarClientes();
    await this.getCurrentUser();
    if (this.isClient) {
      this.filtroForm.patchValue({ client_name: this.clientFullName });
    }
    this.filteredClientes = this.filtroForm.get('CardName').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.Nome_do_PN),
      map(nome => nome ? this._filterClientes(nome) : this.clientes.slice())
    );
     
      this.filtroForm.get('CardName')!.valueChanges.subscribe(value => {
        this.filtrarClientesPorNome(value);
      });
    this._farmService
    .getFarmsMemory()
    .pipe(take(1))
    .subscribe(f => (this.farms = f));


    this._farmService.getFarms().subscribe(farms => {
      this.farms = farms;
    });
    this.openSales. carregarOrcamentos().pipe(take(1)).subscribe(orcamentos => {
      this.orcamentos = orcamentos;  
    });
  
  }

  filtrarClientesPorNome(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.clientes.filter(cliente => cliente.Nome_do_PN.toLowerCase().includes(filterValue));
  }
  getCurrentUser(): void {
    this.loading = true;
  
    if (this.userAuth.userData) {

      this.processUserData(this.userAuth.userData);
    } else {
      
      this.userAuth.get().toPromise()
        .then(user => {
          this.userAuth.userData = user; 
          this.processUserData(user);
        })
        .catch(error => {

          this.loading = false; 
        });
    }
  }
  private processUserData(user: any): void {
   
  
    this.isClient = user.roles.some(r => r.scope === 'cliente');
    this.isComercial=user.roles.some(r => r.scope === 'comercial');
    if (this.isClient) {
    if (user.firstname && user.lastname) {
       this.clientFullName = `${user.firstname} ${user.lastname}`;
      
      }
  }
  if (this.isComercial) {
    if (user.firstname && user.lastname) {
       this.clientFullName = `${user.firstname} ${user.lastname}`;
      
      }
  }
}
  private _filterClientes(nome: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.clientes.filter(cliente => cliente.Nome_do_PN.toLowerCase().includes(filterValue));
  }
  cancel(): void {
    this.dialogRef.close();
  }


  async carregarClientes(): Promise<void> {
    this.loading = true;
    try {
      const clientes = await this.openSales.obterClientes().toPromise();
      this.clientes = clientes;
      this.clientesFiltrados = [...this.clientes];
      this.loading = false;
    
    } catch (error) {
      
      this.loading = false;
    }
  }

  displayCliente(cliente: any): string {
    return cliente && cliente.Nome_do_PN ? cliente.Nome_do_PN : '';
  }

  filtrarClientes(): void {
    const { U_CADF_Fazenda, Nome_do_PN, CNPJ_CPF, Telefone, CardCode } = this.formFilter.value;
    this.clientesFiltrados = this.clientes.filter(cliente => {
      return (U_CADF_Fazenda ? cliente.U_CADF_Fazenda.toLowerCase().includes(U_CADF_Fazenda.toLowerCase()) : true) &&
        (Nome_do_PN ? (cliente.Nome_do_PN || '').toLowerCase().includes(Nome_do_PN.toLowerCase()) : true) &&
        (CardCode ? (cliente.CardCode || '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
        (CNPJ_CPF ? (cliente.CNPJ_CPF || '').toLowerCase().includes(CNPJ_CPF.toLowerCase()) : true) &&
        (Telefone ? (cliente.Telefone || '').toLowerCase().includes(Telefone.toLowerCase()) : true);
    });
  
  }

  selecionarCliente(event: any): void {
    this.cliente = event.option.value;
    this.filtroForm.get('CardName').setValue(this.cliente.Nome_do_PN);
  }

  carregarVendas(): void {
    this.openSales.carregarOrcamentos().subscribe(
      orcamentos => {
        this.orcamentos = orcamentos;
        this.orcamentosFiltrados = [...this.orcamentos];
      },
      error => {
     
      }
    );
  }
  limparCampos(): void {
    this.filtroForm.reset({
      CardCode: '',
      status: [],
      CardName: '',
      DocEntry: '',
      DocDateStart: '',
      DocDateEnd: '',
    });
  }
  
  filter(): void {
    if (this.filtroForm) {
    
      this.orderFilter = [];
  
     
      const orderNumber = this.filtroForm.get('DocEntry').value;
      if (orderNumber) {
        this.orderFilter.push({
          label: 'Número do Pedido',
          value: orderNumber,
          field: 'DocEntry',
          filter: orderNumber.toString(),
        });
      }
      if (this.isClient) {
        let clientName = this.clientFullName;
        this.orderFilter.push({
          label: 'Nome do Cliente',
          value: clientName,
          field: 'CardName',
          filter: clientName,
        });
      } else {
        let clientName = this.filtroForm.get('CardName')?.value;
        if (clientName) {
          this.orderFilter.push({
            label: 'Nome do Cliente',
            value: clientName,
            field: 'CardName',
            filter: clientName,
          });
          this.selecionarCliente = clientName;
        }
      }
  
     
      const farmId = this.filtroForm.get('CardCode').value;
      if (farmId) {
        const farm = this.farms.find(f => f.id === farmId);
        if (farm) {
          this.orderFilter.push({
            label: 'Fazenda',
            value: farm.erp_id.toString(), 
            field: 'CardCode',
            filter: farm.erp_id.toString(), 
          });
        }
      }
  
      
      const selectedStatus = this.filtroForm.get('status').value;
      if (selectedStatus && selectedStatus.length > 0) {
        for (let status of selectedStatus) {
          this.orderFilter.push({
            label: 'Status',
            value: status,
            field: 'status',
            filter: status,
          });
        }
      }
  
     
      if (this.filtroForm.get('DocDateStart').value ) {
        this.orderFilter.push({
          label: 'Registradas desde',
          value: moment(this.filtroForm.get('DocDateStart').value, moment.ISO_8601).format('DD/MM/yyyy'),
          field: 'DocDateStart',
          filter: moment(this.filtroForm.get('DocDateStart').value, moment.ISO_8601).format('yyyy-MM-DD'),
        });
      }
  
     
      if (this.filtroForm.get('DocDateEnd').value) {
        this.orderFilter.push({
          label: 'Registradas até',
          value: moment(this.filtroForm.get('DocDateEnd').value, moment.ISO_8601).format('DD/MM/yyyy'),
          field: 'DocDateEnd',
          filter: moment(this.filtroForm.get('DocDateEnd').value, moment.ISO_8601).format('yyyy-MM-DD'),
        });
      }
  
      this.filterExists();
      this.emitirFiltrosAplicados();
    }
  }
  
  filterExists() {
    if (this.data && this.data.filters && this.data.filters.length) {
      this.data.filters.forEach(element => {
        switch(element.field) {
          case 'DocDateStart':
            this.filtroForm.get('DocDateStart')?.setValue(element.filter);
            break;
          case 'DocDateEnd':
            this.filtroForm.get('DocDateEnd')?.setValue(element.filter);
            break;
          case 'DocEntry':
            this.filtroForm.get('DocEntry')?.setValue(element.filter);
            break;
          case 'CardName':
            this.filtroForm.get('CardName')?.setValue(element.filter);
            break;
          case 'CardCode':
            this.filtroForm.get('CardCode')?.setValue(element.filter);
            break;
          case 'status':
            this.filtroForm.get('status')?.setValue(element.filter);
            break;
          default:
            console.warn(`Campo desconhecido: ${element.field}`);
        }
      });
    }
  }
 
  
  emitirFiltrosAplicados(): void {
    const filters = this.prepareFilters();
    this.filterApplied.emit(filters);
    this.dialogRef.close(); 
  }
  
  prepareFilters(): FilterModel[] {
    const filters: FilterModel[] = [];
  
   
    this.orderFilter.forEach(filter => {
      filters.push({
        label: filter.label,
        value: filter.value,
        field: filter.field,
        filter: filter.filter,
      });
    });
  
    return filters;
  }
  filtrarPorDocNum(numeroPedido: number): void {
    this.vendasFiltradas = this.vendas.filter(venda => venda.DocNum === numeroPedido);
  }

  getStatus(status: string, CANCELED: string, modificacoesSolicitadas: string): string {
    if (status === 'C') {
      if (CANCELED === 'Y') {
        return 'Cancelado';
      } else if (CANCELED === 'N') {
        return 'Aprovado';
      } else {
        return 'Desconhecido';
      }
    } else if (status === 'O') {
      if (modificacoesSolicitadas === 'Y' || modificacoesSolicitadas === 'M') {
        return 'Modificações Solicitadas Pendentes';
      } else {
        return 'Pendente de Aprovação';
      }
    } else {
      return 'Desconhecido';
    }
  }
  
  filtrarPorStatus(status: string): void {
    this.statusSelecionado = status;
    if (this.statusSelecionado === '') {
      this.orcamentosFiltrados = this.orcamentos;
    } else {
      this.orcamentosFiltrados = this.orcamentos.filter(orcamento =>
        this.getStatus(orcamento.DocStatus, orcamento.CANCELED, orcamento.modificacoesSolicitadas) === this.statusSelecionado
      );
    }
  }

}