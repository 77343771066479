import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OpenSalesOrderComponent } from './open-sales-order/open-sales-order.component';
import { MatDialog } from '@angular/material/dialog';
import { OpenSalesService } from './open-sales.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, map, startWith,forkJoin } from 'rxjs';
import { Farm } from 'app/core/models/admin/farms.model';
import { FarmsService } from 'app/core/services/admin/farms.service';
import { OpenSalesDialogComponent } from './open-sales-dialog/open-sales-dialog.component';
import { result } from 'lodash';
import { Console } from 'console';
import { UserService} from 'app/core/services/auth/user.service';
import { User } from 'app/core/models/auth/user.model';
import { SearchOrcamentoComponent } from './search-orcamento/search-orcamento.component';
import { FilterModel } from 'app/core/models/admin/filter.models';

@Component({
  selector: 'app-open-sales',
  templateUrl: './open-sales.component.html',
  styleUrls: ['./open-sales.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OpenSalesComponent implements OnInit {
  ordenacaoCrescente = true;
  public statusSelecionado: string = '';
  ordenacaoCrescenteCardName:boolean = true;

  ordenacaoCrescenteDocNum:boolean = true;
  isAdmin = false;
  orcamentoControl= new FormControl();
  loading: boolean = false;
  filtroForm: FormGroup;
  orcamentosFiltrados:any[]=[];
  orcamentos:any[]=[];
  filteredOrcamentos: Observable<any[]>;
  currentUser: User;
  editarAtivo: boolean = false;
  isClient = false;
  isComercial = false;
  clicavel = true;
  linhasSelecionadas:  any;
  farms$: Observable<Farm[]>;
  farms: Farm[];
  erpIds: any[] = [];
  vendedorLogado:string;
  vendedores: any[] = [];
  vendedoresFiltrados: any[] = [];
  constructor(private _farmsService: FarmsService,
    private userAuth: UserService,
    private fb: FormBuilder,
    public dialog: MatDialog, 
    private orcamentosService:OpenSalesService,
    private changeDetectorRef: ChangeDetectorRef) { 

 
  }

  async ngOnInit(): Promise<void> {

   
    this.changeDetectorRef.detectChanges();

    this.orcamentoControl.valueChanges.subscribe(value => {
      this.filtrarOrcamentosPorCliente();
    });

    this.filteredOrcamentos = this.orcamentoControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value?.CardName),
      map(nome => nome ? this._filterOrcamentos(nome) : this.orcamentos.slice())
    );
  
  await  this.carregarOrcamentos();

    this.filtroForm = this.fb.group({
      DocNum: [''],
      DocDate: [''],
      CardName: [''],
      CardCode: [''],
      DocStatus: [''],
      CANCELED: [''],
      DocEntry: ['']
    });
  }
  async carregarOrcamentos(): Promise<void> {
    this.loading = true;
    try {
      const user = await this.userAuth.get().toPromise();
      this.currentUser = user;
      this.isClient = user.roles.some(r => r.scope === 'cliente');
      this.isComercial = user.roles.some(r => r.scope === 'comercial');
      this.isAdmin = user.roles.some(r => r.scope === 'admin');
  
      if (this.isClient) {
        this.farms = await this._farmsService.searchFarmByUser(user.id).toPromise();
        this.erpIds = this.farms.map(farm => farm.erp_id);
        await this.carregarPedidos();
      } else if (this.isComercial) {
        await this.carregarVendedor();
      } else if (this.isAdmin) {
        await this.carregarOrcamentosAdmin();
      } 
    } catch (error) {
     
    } finally {
      this.loading = false;
    }
  }

  verificarComentarios(comments: string): string {
    try {
      if (!comments) {
        return 'N'; 
      }
  
      const modificacoesIndex = comments.indexOf("Modificações solicitadas:");
      const alteracoesIndex = comments.indexOf("Alterações Realizadas");
  
      if (modificacoesIndex !== -1 && alteracoesIndex !== -1) {
        if (modificacoesIndex < alteracoesIndex) {
          return 'Y'; 
        } else {
          return 'P'; 
        }
      } else if (modificacoesIndex !== -1) {
        return 'M'; 
      } else {
        return 'N'; 
      }
    } catch (error) {
      
      return 'N'; 
    }
  }


  async carregarOrcamentosPorComercial(): Promise<void> {
    try {
      this.orcamentos = await this.orcamentosService.filtrarPorNomeUsuario(this.vendedorLogado).toPromise();
      this.orcamentosFiltrados = [...this.orcamentos];
     
      this.orcamentos = this.orcamentos.map(orcamento => ({
        ...orcamento,
        modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments),
      }));
      this.filtrarOrcamentos();
    } catch (error) {
      console.error('Erro ao carregar orçamentos por comercial:', error);
    }
  }


carregarPedidos(): void {
  this.loading = true;
  const observables = this.farms.map(farm =>
    this.orcamentosService.filtrarPorCardCode(farm.erp_id)
  );

  this.loading = true;

  forkJoin(observables).subscribe(
    resultados => {
      this.orcamentos = resultados.flat();
      this.orcamentos = this.orcamentos.map(orcamento => ({
        ...orcamento,
        modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments),
      }));
      this.filtrarOrcamentos();
      this.loading = false;
    },
    error => {

      this.loading = false;
    }
  );
}
carregarOrcamentosAdmin():void{
  this.loading = true;
  this.orcamentosService.carregarOrcamentos().toPromise().then(orcamentos => {
    this.orcamentos = orcamentos.map(orcamento => ({
      ...orcamento,
      modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments)
    }));
    this.orcamentosFiltrados = [...this.orcamentos];
    this.filtrarOrcamentos();
    this.loading = false;
  },
  error => {
    this.loading = false;
  }
);
}

async carregarVendedor(): Promise<void> {
  if (this.currentUser && this.currentUser.email) {
    try {
      this.vendedores = await this.orcamentosService.obterVendedores().toPromise();
      this.vendedoresFiltrados = [...this.vendedores];
      const email = this.currentUser.email; 
      this.selecionarVendedor(email);
    } catch (error) {
  
    }
  } 
}
selecionarVendedor(email: string) {
  const emailSelecionado = email.trim().toLowerCase(); 

  const vendedor = this.vendedores.find(v => {
    const emailVendedor = v.Email?.trim().toLowerCase(); 
    return emailVendedor === emailSelecionado;
  });

  if (vendedor) {
    this.vendedorLogado = vendedor.SlpName;
    this.carregarOrcamentosPorComercial();
  }
}
filtrarVendedores(): void {
  const { SlpCode, SlpName, Email } = this.filtroForm.value;
  this.vendedoresFiltrados = this.vendedores.filter(vendedor => {
    return (SlpCode ? vendedor.SlpCode.toLowerCase().includes(SlpCode.toLowerCase()) : true) &&
           (SlpName ? vendedor.SlpName.toLowerCase().includes(SlpName.toLowerCase()) : true) &&
           (Email  ? (vendedor.Email  || '').toLowerCase().includes(Email .toLowerCase()) : true);
          
  });
  
}

  private _filterOrcamentos(nome: string): any[] {
    const filterValue = nome ? nome.toLowerCase() : '';
    return this.orcamentos.filter(orcamento => orcamento.CardName.toLowerCase().includes(filterValue));
  }

  filtrarOrcamentosPorCliente(): void {
    const clienteFiltro = this.orcamentoControl.value;
    const filterValue = clienteFiltro ? clienteFiltro.toLowerCase() : '';
    if (filterValue && typeof filterValue === 'string') {
      this.orcamentosFiltrados = this.orcamentos.filter(orcamento => orcamento.CardName && orcamento.CardName.toLowerCase().includes(filterValue));
    } else {
      this.orcamentosFiltrados = [...this.orcamentos];
    }
  }

 
  filtrarOrcamentos(): void {
    const { DocNum, DocDate, CardName, CardCode, DocStatus, CANCELED, DocEntry, NumAtCard, Comments } = this.filtroForm.value;
  
    this.orcamentosFiltrados = this.orcamentos.filter(orcamento => {
      return (DocNum ? orcamento.DocNum.toLowerCase().includes(DocNum.toLowerCase()) : true) &&
             (DocDate ? orcamento.DocDate.toLowerCase().includes(DocDate.toLowerCase()) : true) &&
             (CardName ? (orcamento.CardName || '').toLowerCase().includes(CardName.toLowerCase()) : true) &&
             (CardCode ? (orcamento.CardCode || '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
             (DocStatus ? (orcamento.DocStatus || '').toLowerCase().includes(DocStatus.toLowerCase()) : true) &&
             (CANCELED ? (orcamento.CANCELED || '').toLowerCase().includes(CANCELED.toLowerCase()) : true) &&
             (DocEntry ? (orcamento.DocEntry || '').toLowerCase().includes(DocEntry.toLowerCase()) : true) &&
             (NumAtCard ? (orcamento.NumAtCard || '').toLowerCase().includes(NumAtCard.toLowerCase()) : true) &&
             (Comments ? (orcamento.Comments || '').toLowerCase().includes(Comments.toLowerCase()) : true);
    });
  
    this.loading = false;
    if (this.orcamentosFiltrados.length === 0) {
    }
  }
  createSalesOrder(): void {
    const dialogRef = this.dialog.open(OpenSalesOrderComponent, {
      autoFocus: true,
    
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
   
      if (result) {
       
      this.carregarOrcamentos();
      }

      this.changeDetectorRef.detectChanges();
    });
  }


  selecionarLinha(linha: any): void {
    this.linhasSelecionadas = linha;
    this.editarItem();
}


editarItem(): void {
  if (this.linhasSelecionadas && this.linhasSelecionadas.DocNum) { 
      this.editarAtivo = true;

      if (this.linhasSelecionadas.DocEntry) {
          this.openDialog(this.linhasSelecionadas.DocEntry);
      } else {
          this.editarAtivo = false;
      }
  }
}


openDialog(DocEntry: any): void {
  this.loading = true;

  this.orcamentosService.orcamentoDetails(DocEntry).subscribe(
      detalhes => {

          const dialogRef = this.dialog.open(OpenSalesDialogComponent, {
              data: detalhes, 
              disableClose: true,
              autoFocus: false,
          });
          this.clicavel = true;
          dialogRef.afterClosed().subscribe(result => {
              this.editarAtivo = false; 
              this.clicavel = true; 
              if (result) {
                 
                  this.recargarOrcamentos();
              }
              this.changeDetectorRef.detectChanges();
              this.loading = false;
          });
      },
      error => {
          this.clicavel = true; 
          this.loading = false;
          this.editarAtivo = false; 
      }
  );
}





searchDialog() {
  const dialogRef = this.dialog.open(SearchOrcamentoComponent, {disableClose: true,});
  dialogRef.afterClosed().subscribe((result: any[]) => {
    if (result) {
      this.aplicarFiltro(result); 
    }
  });
  
  dialogRef.componentInstance.filterApplied.subscribe((filters: FilterModel[]) => {

   
    
    this.aplicarFiltro(filters);
  });
}

aplicarFiltro(filters: any) {
  if (!filters || filters.length === 0) {
    return; 
  }

  if (this.isComercial && this.vendedorLogado) {
    filters.push({
      field: 'nomeVendedor',
      filter: this.vendedorLogado,
      value: this.vendedorLogado
    });
  }



  const filtroCardCode = filters.find((filter: any) => filter.field === 'CardCode');
  const hasCardCodeFilter = !!filtroCardCode;


  let orcamentosFiltradosTotal: any[] = [];

  const selectedStatus = filters.find(f => f.field === 'status')?.value;

  if (this.isClient) {
    const cardCodes = hasCardCodeFilter ? [filtroCardCode.filter] : Array.from(new Set(this.orcamentos.map(orcamento => orcamento.CardCode)));

    const observables = cardCodes.map(cardCode => {
      let queryParams = '';

      filters.forEach((filter) => {
        if (filter.field !== 'CardCode' && filter.field !== 'status' && filter.value && filter.value !== '') {
          const encodedValue = encodeURIComponent(filter.filter);
          queryParams += `${filter.field}=${encodedValue}&`;
        }
      });

      queryParams += `CardCode=${encodeURIComponent(cardCode)}`;
      queryParams = queryParams.replace(/&$/, '');

      if (queryParams) {
        const url = `/api/consulta/listaOrcamentos?${queryParams}`;

        this.loading = true;
        return this.orcamentosService.buscarDados(url);
      }
      return null; 
    }).filter(obs => obs !== null);

    if (observables.length > 0) {
      forkJoin(observables).subscribe(
        (responses: any[]) => {
          responses.forEach(response => {
            orcamentosFiltradosTotal = orcamentosFiltradosTotal.concat(response);
          });

          if (selectedStatus && selectedStatus.length > 0) {
            if (Array.isArray(selectedStatus)) {
              orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
                selectedStatus.includes(this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas))
              );
            } else {
              orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
                this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas) === selectedStatus
              );
            }
          }
          this.orcamentosFiltrados = orcamentosFiltradosTotal;
          this.loading = false;
        },
        error => {
          this.loading = false;

          orcamentosFiltradosTotal = this.orcamentos; 
          if (selectedStatus && selectedStatus.length > 0) {
            if (Array.isArray(selectedStatus)) {
              orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
                selectedStatus.includes(this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas))
              );
            } else {
              orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
                this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas) === selectedStatus
              );
            }
          }
                    this.orcamentosFiltrados = orcamentosFiltradosTotal;
        }
      );
    } else {
    
      orcamentosFiltradosTotal = this.orcamentos; 
      if (selectedStatus && selectedStatus.length > 0) {
        if (Array.isArray(selectedStatus)) {
          orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
            selectedStatus.includes(this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas))
          );
        } else {
          orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
            this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas) === selectedStatus
          );
        }
      }
      this.orcamentosFiltrados = orcamentosFiltradosTotal;
    }
  } else {

    let queryParams = '';

    filters.forEach((filter) => {
      if (filter.field !== 'CardCode' && filter.field !== 'status' && filter.value && filter.value !== '') {
        const encodedValue = encodeURIComponent(filter.filter);
        queryParams += `${filter.field}=${encodedValue}&`;
      }
    });

    queryParams = queryParams.replace(/&$/, '');


    if (queryParams) {
      const url = `/api/consulta/listaOrcamentos?${queryParams}`;

      this.loading = true;
      this.orcamentosService.buscarDados(url).subscribe(
        (response: any) => {
          orcamentosFiltradosTotal = response;

          if (selectedStatus && selectedStatus.length > 0) {
            if (Array.isArray(selectedStatus)) {
              orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
                selectedStatus.includes(this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas))
              );
            } else {
              orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
                this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas) === selectedStatus
              );
            }
          }


          this.orcamentosFiltrados = orcamentosFiltradosTotal;
          this.loading = false;
        },
        error => {
          this.loading = false;

          orcamentosFiltradosTotal = this.orcamentos; 
          if (selectedStatus && selectedStatus.length > 0) {
            if (Array.isArray(selectedStatus)) {
              orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
                selectedStatus.includes(this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas))
              );
            } else {
              orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
                this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas) === selectedStatus
              );
            }
          }

          this.orcamentosFiltrados = orcamentosFiltradosTotal;
        }
      );
    } else {
    
      orcamentosFiltradosTotal = this.orcamentos; 
      if (selectedStatus && selectedStatus.length > 0) {
        if (Array.isArray(selectedStatus)) {
          orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
            selectedStatus.includes(this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas))
          );
        } else {
          orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(orcamento =>
            this.getStatus(orcamento.DocStatus, orcamento.CANCELED,orcamento.modificacoesSolicitadas) === selectedStatus
          );
        }
      }
    
      this.orcamentosFiltrados = orcamentosFiltradosTotal;
    }
  }
}





limparFiltros():void {
  this.orcamentosFiltrados = [...this.orcamentos]; 
}
  

  filtrarPorCampo(orcamento: any, campoFiltro: string, valorFiltro: string): boolean {
    let campoVendas: string;
  
     switch (campoFiltro) {
      case 'CardName':
        campoVendas = 'CardName';
        break;
      case 'CardCode':
        campoVendas = 'CardCode';
        break;
      case 'DocEntry':
        campoVendas = 'DocEntry';
        break;
      
      default:
        campoVendas = campoFiltro;
    }
  
 
    if (orcamento[campoVendas]) {
      return orcamento[campoVendas].toString().includes(valorFiltro);
    }
  
    return false;
  }


 
  ordenarPorData():void {
    if (this.ordenacaoCrescente) {
      
      this.orcamentosFiltrados = this.orcamentos.sort((a, b) => new Date(b.DocDate).getTime() - new Date(a.DocDate).getTime());
      
    } else {
      
      this.orcamentosFiltrados = this.orcamentos.sort((a, b) => new Date(a.DocDate).getTime() - new Date(b.DocDate).getTime());
    }
   
    this.ordenacaoCrescente = !this.ordenacaoCrescente;
  }

  ordenarPorCardName(): void {
    
    
    if (this.ordenacaoCrescenteCardName) {
      
      this.orcamentosFiltrados = this.orcamentos.sort((a, b) => new String(b.CardName).localeCompare(a.CardName));
    
    } else {
      
      this.orcamentosFiltrados = this.orcamentos.sort((a, b) => new String(a.CardName).localeCompare(b.CardName));
    }
   
    this.ordenacaoCrescenteCardName = !this.ordenacaoCrescenteCardName;

}




ordenarPorDocNum(): void {
 
  if (this.ordenacaoCrescenteDocNum) {
   
    this.orcamentosFiltrados = this.orcamentos.sort((a, b) => a.DocNum - b.DocNum);
  } else {
   
    this.orcamentosFiltrados = this.orcamentos.sort((a, b) => b.DocNum - a.DocNum);
  }

  this.ordenacaoCrescenteDocNum = !this.ordenacaoCrescenteDocNum;
}


recargarOrcamentos(): void {
  this.loading = true;

  if (this.isClient) {

    this.carregarPedidos();
  } else if (this.isComercial) {

    this.carregarVendedor();
  } else if (this.isAdmin) {

    this.carregarOrcamentosAdmin();
  } else {
   
    this.loading = false;
  }
}

getStatus(status: string, CANCELED: string, modificacoesSolicitadas: string): string {
  if (status === 'C') {
    if (CANCELED === 'Y') {
      return 'Cancelado';
    } else if (CANCELED === 'N') {
      return 'Aprovado';
    } else {
      return 'Desconhecido';
    }
  } else if (status === 'O') {
    if ( modificacoesSolicitadas === 'M') {
      return 'Modificações Solicitadas Pendentes';
    } else if (modificacoesSolicitadas === 'Y' || modificacoesSolicitadas === 'P') {
      return 'Pendente de Aprovação';
    } else {
      return 'Pendente de Aprovação';
    }
  } else {
    return 'Desconhecido';
  }
}

      
getStatusColor(status: string, CANCELED: string, modificacoesSolicitadas: string): string {
  if (status === 'C') {
    if (CANCELED === 'Y') {
      return 'red';
    } else if (CANCELED === 'N') {
      return 'green';
    } else {
      return 'gray';
    }
  } else if (status === 'O') {
    if ( modificacoesSolicitadas === 'M') {
      return 'orange';
    } else if (modificacoesSolicitadas === 'Y' || modificacoesSolicitadas === 'P'){
      return 'blue';
    } else {
      return 'blue';    
  } 
}   
}   
      filtrarPorStatus(status: string): void {
        this.statusSelecionado = status;
        if (this.statusSelecionado === '') {
          this.orcamentosFiltrados = this.orcamentos;
        } else {
          this.orcamentosFiltrados = this.orcamentos.filter(orcamento =>
            this.getStatus(orcamento.DocStatus, orcamento.CANCELED, orcamento.modificacoesSolicitadas) === this.statusSelecionado
          );
        }
      }

}
