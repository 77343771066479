
<div class="content pt-4">

 
  <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript">
    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
    <input matInput [autocomplete]="'off'" [placeholder]="'Pesquisar'" [formControl]="SearchQuestionControl" />
  </mat-form-field>

  <button mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary text-white bg-primary dark:text-white dark:bg-primary ng-star-inserted" ng-reflect-color="primary" (click)="newQuestion()">
    <span class="mat-button-wrapper">
      <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
        </svg>
      </mat-icon>
      <span class="ml-2 mr-1">Nova Questão</span>
    </span>
  </button>
  
</div>


<br>
<h3 class="info-subtitle">Mova para alterar a ordem:</h3>

<div class="question" *ngIf="questionsFiltradas.length > 0">
  
  <table cdkDropList (cdkDropListDropped)="drop($event)">
    <thead>
      <tr>
        <th></th>
        <th>Ordem</th>
        <th>Seção</th>
        <th>Questão</th>
        <th>Status</th>
        <th>Ações</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let question of questionsFiltradas" cdkDrag>
        <td>
          <mat-icon matTooltip="Arraste para alterar a ordem das perguntas" matTooltipPosition="above">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
              <path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z"/>
            </svg>
          </mat-icon>
          
        </td>
        <td>
          {{ question.order }}
        </td>
        <td>{{ getSectionName(question.section_id) }}</td>
        <td>{{ question.question }}</td>
        <td>
          <mat-icon *ngIf="question.active=== true"
            matTooltip="Requisição Aprovada"
            [style.color]="'green'"
            [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
          <mat-icon *ngIf="question.active === false" color="warn">cancel</mat-icon>
        </td>
        <td>
          <button (click)="editarQuestoes(question)">
            <mat-icon matTooltip="Editar Tipo de checklist" class="text-hint" [svgIcon]="'feather:edit'"></mat-icon>
          </button>
        </td>
        <td>
          <button mat-icon-button color="warn" (click)="excluirQuestion(question)">
            <mat-icon matTooltip="Deletar Tipo de checklist">delete</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

<div *ngIf="!loading && questionsFiltradas.length === 0">
  <p>Nenhum dado encontrado</p>
</div>
