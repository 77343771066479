import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OrdersOpenService } from './orders-open.service';
import { MatDialog} from '@angular/material/dialog';
import { OrderOpenDialogComponent } from './order-open-dialog/order-open-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { result } from 'lodash';
import { UserService,User } from 'app/core/services/auth/user.service';
import { Farm } from 'app/core/models/admin/farms.model';
import { FarmsService } from 'app/core/services/admin/farms.service';
import { Observable, concatMap, toArray, from, map, switchMap, of} from 'rxjs';
import { Subscription, forkJoin,take } from 'rxjs';
import { UsersService } from 'app/core/services/admin/users.service';
@Component({
  selector: 'app-orders-open',
  templateUrl: './orders-open.component.html',
  styleUrls: ['./orders-open.component.scss']
})
export class OrdersOpenComponent implements OnInit {
  filtroForm: FormGroup;
  loading: boolean = false;
  pedidos:any[]=[];
  scope:string;
  pedidosFiltrados:any[]=[];
  orcamentosFiltrados:any[]=[];
  orcamentos:any[]=[];
  linhasSelecionadas:  any;
  currentUser: User;
  numbersCard:any[]=[];
  numberCard:any[]=[];
  numberCardFiltrado:any[]=[];
  farms$: Observable<Farm[]>;
  farms: Farm[];
  erpIds: any[] = [];
  isClient = false;
  isComercial = false;
  isAdmin = false;
  users: User[];
  listaOrcamentos: any[] = []; 
  private farmsSubscription: Subscription | undefined;
  constructor(private ordersService:OrdersOpenService,private _usersService: UsersService,private userAuth: UserService,private _farmsService: FarmsService,
     public dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef,private fb: FormBuilder,) {   
   }

  ngOnInit(): void {
   
 
    this.getCurrentUser();

    this.filtroForm = this.fb.group({
      DocNum: [''],
      DocDate: [''],
      CardName: [''],
      CardCode: [''],
      DocStatus: [''],
      CANCELED: [''],
      DocEntry: ['']
    });
    this.changeDetectorRef
    
  }

 
  getCurrentUser(): void {
    this.loading = true;

    if (this.userAuth.userData) {
  
      this.processUserData(this.userAuth.userData);
    } else {
    
      this.userAuth.get().toPromise()
        .then(user => {
          this.userAuth.userData = user; 
          this.processUserData(user);
        })
        .catch(error => {
          console.error('Erro ao obter usuário:', error);
          this.loading = false; 
        });
    }
  }
  
   private processUserData(user: any): void {
 

   
    this.isClient = user.roles.some(r => r.scope === 'cliente');
    this.isComercial = user.roles.some(r => r.scope === 'comercial');
    this.isAdmin = user.roles.some(r => r.scope ==='admin');
    if (this.isClient) {
     
      this.farms$ = this._farmsService.searchFarmByUser(user.id);
      this.farms$.subscribe(farms => {
      
        this.farms = farms; 
        this.erpIds = farms.map(farm => farm.erp_id); 
  
        this.carregarPedidos();
      });
    } else if (this.isComercial) {

      this.carregarPedidosVendedor();
    } 
    else if (this.isAdmin) {
      this.carrregarOrcamentosAdmin();
    }else {


      this.loading = false; 
    }
  }
 
  verificarComentarios(comments: string): string {
    try {
      if (!comments) {
        return 'N';
      }
  
      if (comments.includes("Modificações solicitadas:") && comments.includes("Alterações Realizadas")) {
        return 'Y';
      } else if (comments.includes("Modificações solicitadas:")) {
        return 'M';
      } else {
        return 'N';
      }
    } catch (error) {
      console.error('Erro ao verificar comentários:', error);
      return 'N'; 
    }
  }
  
     
    carregarPedidos(): void {
      this.loading = true;
      const observables = this.farms.map(farm =>
        this.ordersService.filtrarPorCardCode(farm.erp_id)
      );
  
      this.loading = true;
  
      forkJoin(observables).subscribe(
        resultados => {
         
          this.orcamentos = resultados.flat();

          this.orcamentos = this.orcamentos.map(orcamento => ({
            ...orcamento,
            modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments),
           
          }));
          this.filtrarOrcamentos();
          
          this.loading = false;
        },
        error => {
          console.error('Erro ao carregar orçamentos por CardCode:', error);
          this.loading = false;
        }
      );
    }
  
    carregarPedidosVendedor(){
    
      if (this.currentUser && this.currentUser.email ) {
        const email = `${this.currentUser.email} `;
        this.ordersService.filtrarPorNomeUsuario(email).subscribe(
          orcamentos => {
            this.orcamentos = orcamentos.map(orcamento => ({
              ...orcamento,
              modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments)
            }));
            this.orcamentosFiltrados = [...this.orcamentos];
            this.filtrarOrcamentos(); 
            this.loading = false;
          },
          error => {
            console.error('Erro ao carregar orçamentos:', error);
            this.loading = false;
          }
        );

      
    }
  }
 
      carrregarOrcamentosAdmin(){
        this.ordersService.pedidosDetails().subscribe( orcamentos => {
          this.orcamentos = orcamentos.map(orcamento => ({
            ...orcamento,
            modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments)
          }));
          this.orcamentosFiltrados = [...this.orcamentos];
          this.filtrarOrcamentos(); 
          this.loading = false;
        },
        error => {
          console.error('Erro ao carregar orçamentos:', error);
          this.loading = false;
        }
      );
      
  
      }



  filtrarOrcamentos(): void {
    const { DocNum, DocDate, CardName, CardCode, DocStatus, CANCELED, DocEntry, NumAtCard,Comments } = this.filtroForm.value;

    this.orcamentosFiltrados = this.orcamentos.filter(orcamento => {
      return (DocNum ? orcamento.DocNum.toLowerCase().includes(DocNum.toLowerCase()) : true) &&
             (DocDate ? orcamento.DocDate.toLowerCase().includes(DocDate.toLowerCase()) : true) &&
             (CardName ? (orcamento.CardName || '').toLowerCase().includes(CardName.toLowerCase()) : true) &&
             (CardCode ? (orcamento.CardCode || '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
             (DocStatus ? (orcamento.DocStatus || '').toLowerCase().includes(DocStatus.toLowerCase()) : true) &&
             (CANCELED ? (orcamento.CANCELED || '').toLowerCase().includes(CANCELED.toLowerCase()) : true) &&
             (DocEntry ? (orcamento.DocEntry || '').toLowerCase().includes(DocEntry.toLowerCase()) : true)&&
             (NumAtCard ? (orcamento.NumAtCard || '').toLowerCase().includes(NumAtCard.toLowerCase()) : true)&&
             (Comments? (orcamento.Comments|| '').toLowerCase().includes(Comments.toLowerCase()):true);
    });
    this.loading=false;
    if (this.OrcamentosFiltradosVazio()) {

    }
  }
  recargarOrcamentos(): void {
    this.loading = true;
  
    if (this.isClient) {
    
      this.carregarPedidos();
    } else if (this.isComercial) {
     
      const email = `${this.currentUser.email} `;
      this.ordersService.filtrarPorNomeUsuario(email).toPromise()
        .then(resultados => {
          this.orcamentos = resultados.map(orcamento => ({
            ...orcamento,
            modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments)
          }));
          this.orcamentosFiltrados = [...this.orcamentos];
          this.filtrarOrcamentos();
          this.loading = false;
        })
        .catch(error => {
          console.error('Erro ao recarregar orçamentos por comercial:', error);
          this.loading = false;
        });
    } else if (this.isAdmin) {
    
      this.carregarOrcamentosAdmin();
    } else {

      this.loading = false;
    }
  }
  

  private carregarOrcamentosAdmin(): void {
    this.ordersService.pedidosDetails().toPromise()
      .then(orcamentos => {
        this.orcamentos = orcamentos.map(orcamento => ({
          ...orcamento,
          modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments)
        }));
        this.orcamentosFiltrados = [...this.orcamentos];
        this.filtrarOrcamentos();
        this.loading = false;
      })
      .catch(error => {
        console.error('Erro ao carregar orçamentos:', error);
        this.loading = false;
      });
  }
  
  selecionarLinha(linha: any) {
    this.linhasSelecionadas = linha;

    if (this.linhasSelecionadas.DocEntry) {

      this.loading=true;
        this.openDialog(this.linhasSelecionadas.DocEntry, this.linhasSelecionadas.NumAtCard);
        this.Atcard(this.linhasSelecionadas.NumAtCard)
    } else {
        console.error('DocEntry não encontrado na linha selecionada');
    }

    }
    openDialog(DocEntry: any, NumAtCard: string): void {
      this.loading = true; 
   
    
      this.ordersService.orcamentoDetails(DocEntry).subscribe(
        detalhes => {
        
         
          const dialogRef = this.dialog.open(OrderOpenDialogComponent, {
            data: detalhes
          });
    
          dialogRef.afterClosed().subscribe(result => {
           
            this.loading = false; 
          
            this.recargarOrcamentos();
            this.iniciarVerificacao(NumAtCard);
          });
        },
        error => {
          console.error('Erro ao obter os detalhes do orçamento', error);
          this.loading = false;
    
        }
      );
    }


      getStatus(status: string, CANCELED: string, modificacoesSolicitadas: string): string {
        if (status === 'C') {
          if (CANCELED === 'Y') {
            return 'Cancelado';
          } else if (CANCELED === 'N') {
            return 'Aprovado';
          } else {
            return 'Desconhecido';
          }
        } else if (status === 'O') {
          if (modificacoesSolicitadas === 'Y' || modificacoesSolicitadas === 'M') {
            return 'Modificações Solicitadas Pendentes';
          } else {
            return 'Pendente de Aprovação';
          }
        } else {
          return 'Desconhecido';
        }
      }
      
      getStatusColor(status: string, CANCELED: string, modificacoesSolicitadas: string): string {
        if (status === 'C') {
          if (CANCELED === 'Y') {
            return 'red';
          } else if (CANCELED === 'N') {
            return 'green';
          } else {
            return 'gray';
          }
        } else if (status === 'O') {
          if (modificacoesSolicitadas ===  'M') {
            return 'orange';
          } else {
            return 'blue';
          }
        } else {
          return 'gray';
        }
      }      



      OrcamentosFiltradosVazio(): boolean {
        return this.orcamentosFiltrados.filter(orcamento => orcamento.DocStatus === 'O').length === 0;
      }


      Atcard(NumAtCard:string){
        const valorAtCard = NumAtCard;
        const partes = valorAtCard.match(/\d+/g); 
        const codigoVenda = partes ? parseInt(partes.join('')) : 0;
  
        return codigoVenda + 1;
      }
      
      async iniciarVerificacao(NumAtCard: string): Promise<void> {
        const maxTentativas = 12;
        const delay = 5000; 
        let tentativas = 0;
    
        while (tentativas < maxTentativas) {
            try {
                const numbersCard = await this.ordersService.obterAtCard().toPromise();
    
           
                const numberCardFiltrado = numbersCard.map(card => card.DocEntry.toString());
    
              
                if (numberCardFiltrado.length > 0) {
                    const ultimoDocEntry = numberCardFiltrado[0]; 
             
                    this.loading = false;
                    return; 
                } else {
                    console.error('Nenhum DocEntry retornado:', numberCardFiltrado);
                }
            } catch (error) {
                console.error('Erro ao obter último DocEntry:', error);
                this.loading = false;
                return; 
            }
    
            await new Promise(resolve => setTimeout(resolve, delay));
            tentativas++;
        }
    
        console.error('Máximo de tentativas alcançado. Parando a verificação.');
        this.loading = false;
    }
    
     
      }
  
  
