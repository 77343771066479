import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-dialog-cancelar',
  templateUrl: './dialog-cancelar.component.html',
  styleUrls: ['./dialog-cancelar.component.scss']
})
export class DialogCancelarComponent implements OnInit {

  constructor(public dialog: MatDialog,public dialogRef: MatDialogRef<DialogCancelarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  close(){
    this.dialogRef.close();
  }
}
