import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { FuseConfigService } from '../../../../@fuse/services/config/config.service';
import { Regional, Territory } from './../../models/squads/territory.model';

@Injectable({
  providedIn: 'root',
})
export class TerritoryService {
  private host: string;
  private territories: Territory[] = [];
  private regionals: Regional[] = [];
  private _territory: BehaviorSubject<Territory | null> = new BehaviorSubject(
    null
  );
  private _territories: BehaviorSubject<Territory[] | null> =
    new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient,
    private _fuseConfigService: FuseConfigService
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.custHost;
    });
  }

  get territory$(): Observable<Territory> {
    return this._territory.asObservable();
  }

  get territories$(): Observable<Territory[]> {
    return this._territories.asObservable();
  }

  getRegionals(): Observable<Regional[]> {
    if (this.regionals.length > 0) {
      return of(this.regionals);
    }
    return this._httpClient
      .get<Regional[]>(this.host + '/api/v1/squads/regional')
      .pipe(
        take(1),
        tap(regionals => {
          this.regionals = regionals;
        })
      );
  }

  getTerritories(): Observable<Territory[]> {
    if (this.territories.length > 0) {
      return of(this.territories);
    }
    return this._httpClient
      .get<Territory[]>(this.host + '/api/v1/squads/territory')
      .pipe(
        take(1),
        tap(territories => {
          this.getRegionals()
            .pipe(take(1))
            .subscribe(regionals => {
              this.territories = territories;
              this.territories.map(
                t =>
                  (t.regional = regionals.find(
                    reg => reg.id == t.regional_id
                  )?.name)
              );
              this.sortTerritory({ active: '', direction: 'asc' });
            });
        })
      );
  }

  getTerritoriesByRegionalId(regional_id: number): Observable<Territory[]> {
    return this._httpClient
      .get<Territory[]>(
        this.host + `/api/v1/squads/territory?regional_id=${regional_id}`
      )
      .pipe(take(1));
  }

  downloadFile(data: any): void {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
}

download(onProgress: (progress: number) => void, onError: (error: any) => void): void {
    this._httpClient
        .get(this.host + '/api/v1/squads/export/territories', {
            observe: 'events',
            responseType: 'blob',
            reportProgress: true
        })
        .subscribe(event => {
            if (event.type === HttpEventType.DownloadProgress) {
                const progress = Math.round((100 * event.loaded) / (event.total || 1));
                console.log(`Progresso do download de Territórios: ${progress}%`);
                onProgress(progress);
            } else if (event.type === HttpEventType.Response) {
                this.downloadFile(event.body);
                onProgress(100);
            }
        }, error => {
            console.error('Erro no download de Territórios:', error);
            onError(error);
        });
}

  create(territory: Territory): Observable<Territory> {
    return this.territories$.pipe(
      take(1),
      switchMap(territories =>
        this._httpClient
          .post<Territory>(this.host + '/api/v1/squads/territory', territory)
          .pipe(
            map(newTerritory => {
              this.getRegionals()
                .pipe(take(1))
                .subscribe(regionals => {
                  newTerritory.regional = regionals.find(
                    reg => reg.id == newTerritory.regional_id
                  )?.name;
                  this._territories.next([...territories, newTerritory]);
                  this.territories = [];
                });
              return newTerritory;
            })
          )
      )
    );
  }

  delete(id: string): Observable<Territory> {
    return this.territories$.pipe(
      take(1),
      switchMap(territories =>
        this._httpClient
          .delete<Territory>(this.host + '/api/v1/squads/territory/' + id)
          .pipe(
            map(newTerritory => {

              territories = territories.filter(f => f.id.toString() != id);

              this._territories.next(territories);
              this.territories = [];

              return newTerritory;
            })
          )
      )
    );
  }

  update(id: string, data: Territory): Observable<Territory> {
    return this.territories$.pipe(
      take(1),
      switchMap(territory =>
        this._httpClient
          .put<Territory>(this.host + '/api/v1/squads/territory/' + id, data)
          .pipe(
            map(updatedTerritory => {

              this.getRegionals()
                .pipe(take(1))
                .subscribe(regionals => {
                  updatedTerritory.regional = regionals.find(
                    reg => reg.id == updatedTerritory.regional_id
                  )?.name;

                  const index = territory.findIndex(
                    item => item.id.toString() == id
                  );

                  territory[index] = updatedTerritory;

                  this._territories.next(territory);
                  this.territories = [];
                });

              return updatedTerritory;
            })
          )
      )
    );
  }

  searchTerritory(search: string): Observable<Territory[]> {
    search = search.toLowerCase();
    const filtered = this.territories.filter(
      f =>
        f.code.toLowerCase().includes(search) ||
        f.name?.toLowerCase().includes(search) ||
        f.regional?.toLowerCase().includes(search)
    );
    this._territories.next(filtered);
    return of(filtered);
  }

  sortTerritory(sort: Sort): void {
    this.territories = this.territories.sort((obj1, obj2) => {
      const name1 = obj1.name;
      const name2 = obj2.name;
      if (sort.direction == 'asc') {
        return name1.localeCompare(name2);
      } else {
        return name2.localeCompare(name1);
      }
    });
    this._territories.next(this.territories);
  }
}
