<div class="flex flex-col md:min-w-200 -m-6">
    <div class="flex items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
    <div class="text-lg font-medium">Questões</div>
   

    </div>
    <div *ngIf="editMode" class=" title-cabecalho ">
        Editar Questão

         
    </div>
    <div *ngIf="!editMode" class="title-cabecalho ">
        Nova Questão
    </div>
    <div class="h-full overflow-y-auto">
        <div class="container">
        <div class="id" *ngIf="editMode">
          <mat-form-field> 
            <mat-label class="campos_cod" appearance="outline">N° Id:</mat-label>
            <input type="text" matInput [formControl]="IdControl" [matAutocomplete]="autoId"  [readonly]="IdControl.value !==null">

            <mat-autocomplete #autoId="matAutocomplete">
            </mat-autocomplete>
          </mat-form-field>
          
        </div>
    <div class="content">
 

        
        <mat-form-field>
            <mat-label class="campos_cod" appearance="outline">Seção:</mat-label>
            <input type="text" matInput [formControl]="SectionControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySecao" (optionSelected)="selecionarSecao(secoes)" >
              <mat-option *ngFor="let secao of secoesFiltradas" [value]="secao">
                {{ secao?.id  + ' - ' + secao?.section }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          

        <mat-form-field>
            <mat-label class="campos_cod" appearance="outline"> Questão:</mat-label>
            <input type="text" matInput [formControl]="QuestionControl"  >
        </mat-form-field> 

        <mat-form-field>
          <mat-label class="campos_cod" appearance="outline">Status:</mat-label>
         
          <mat-select [formControl]="ActiveControl" >
              <mat-option value="true">Ativado</mat-option>
              <mat-option value="false">Desativado</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="campos_cod" appearance="outline">Tipo Equipamento:</mat-label>
          <input type="text" matInput [formControl]="EquipmentControl" [matAutocomplete]="autoEquipment" [value]="selectedEquipamento?.name">
          <mat-autocomplete #autoEquipment="matAutocomplete" [displayWith]="displayEquipamento">
            <mat-option (click)="selecionarEquipamentoPorId(null)">
              Nenhum
            </mat-option>
            <mat-option *ngFor="let equipamento of equipamentosFiltrados" [value]="equipamento" (click)="selecionarEquipamentoPorId(equipamento.id)">
              {{equipamento.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        
    </div>
</div>
    <div class="button">
        <button *ngIf="!editMode" mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" (click)="enviarDadosParaApi()" >
            <span class="mat-button-wrapper">
              <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">        
                <svg id="send" x="624"y="384" viewBox="0 0 24 24">
          <path
              fill="none"
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="2"
              stroke="currentColor"
              d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z"/>
      </svg>
                  </mat-icon>
                  <span class="ml-2 mr-1">Enviar</span>
              </span>
              <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
              </span>
              <span class="mat-button-focus-overlay">
              </span>
            </button>
            <button *ngIf="editMode" mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" (click)="editarQuestion(question)" >
                <span class="mat-button-wrapper">
                      <span class="ml-2 mr-1">Salvar</span>
                  </span>
                  <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
                  </span>
                  <span class="mat-button-focus-overlay">
                  </span>
                </button>
                <button   mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" style="background-color: #6c757d; color: white;"  (click)="cancel()"  [disabled]="loading">
                    <span class="mat-button-wrapper">
                        <span class="ml-2 mr-1">Cancelar </span>
                    </span>
                    <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
                    </span>
                    <span class="mat-button-focus-overlay">
                    </span>
                </button>
    </div>

</div>
</div>