import { Component, OnInit ,SimpleChanges,Input, Inject, Output, EventEmitter} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { ChangeDetectorRef } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup,FormControl } from '@angular/forms';
import { SettingsService } from 'app/core/services/admin/settings.service';
import { ChecklistService } from 'app/modules/settings/checklists/checklist.service';
import { UsersService } from 'app/core/services/admin/users.service';
import { UserService } from 'app/core/services/auth/user.service';
import { ActivatedRoute } from '@angular/router';
import { FarmsService } from 'app/core/services/admin/farms.service';
import { map, startWith, take } from 'rxjs/operators';
import {  Observable, of } from 'rxjs';
import { Farm } from 'app/core/models/admin/farms.model';
import { ErrordialogComponent } from '../../../errordialog/errordialog/errordialog.component';
import { EquipmentsService } from 'app/core/services/admin/equipments.service';
import { error } from 'console';
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  @Output() questionsClosed = new EventEmitter<void>();
  sectionId: number;
  nameSection:string;
  showUploadPhoto = true;
  showEquipamentoSelect: boolean = false;
  loading=false;
  questionsFiltradas: any[] = [];
  questions: any[] = [];
  photo: string | null = null;
  selectedFileName: string | null = null;
  secoesFiltradas:any[] = [];
  secoes:any[] = [];
  categorias:any[] = [];
  categoriasFiltradas:any[] = [];
  answers: { id: number, grade: string, photo: string | null, observations: string ,equipment_id?:any}[] = [];
  question: any = {}; 
  currentQuestionIndex = 0; 
  id_random = this.createNewId();
  filtroForm: FormGroup;
  showOptions = true;
  users: any[] = [];
  filteredFarms$: Observable<any[]> = of([]);
  farms: any[] = [];
  farmId:any;
  equipamentos:any[] = [];
  equipamentosFiltrados:any[] = [];
  selectedEquipamentoId: any | null = null;
  selectedFarm: any;
  farms$:Observable<Farm[]>;
  currentNameEquipmentId: string | null = null;
  EquipmentControl=new FormControl();
  EquipmentName:string | null = null;
  farmControl = new FormControl();
  userId: any | null = null; 
  constructor(  private checklistService: ChecklistService,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder, 
    private dialogRef: MatDialogRef<QuestionsComponent>,
    public dialog: MatDialog,
    private _farmsService: FarmsService,
    private _usersService: UsersService,
    private _userService: UserService,
    private equipmentService:EquipmentsService,
    private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: { sectionId: number },
  ) { this.sectionId = data.sectionId;}

  ngOnInit(): void {
   
    
    this.filtroForm = this.fb.group([{ id: [''], section_id: [''], question: [''], order: [''] }]);
      this.carregarQuestions();
      this.carregarSecoes();
      this.carregarCategoria();
    
      this._usersService.getUsersMemory().pipe(take(1)).subscribe(users => {
        this.users = users;
        this.userId = this._userService.userData.id; 

      });
    
      this._farmsService.getFarmsMemory().pipe(take(1)).subscribe(farms => {
      
        this.farms = farms;
      });
      

      this.filteredFarms$ = this.farmControl.valueChanges.pipe(
        startWith(''),
        map(value => {

          this.farmId=value.id;
          return typeof value === 'string' ? value : value?.name;
        }),
        map(name => this._filterFarms(name || ''))
      );
      
    }
    
    
  
  form = new FormGroup({
    selectedAnswer: new FormControl<string>(''),
    observacoes: new FormControl<string>('')
  });

  displayFarmName(farm: any): string {
    return farm ? farm.farm_name : ''; 
  }

  createNewId() {
    const id = uuidv4();
    this. id_random  = id;
    return id;
  }


  private _filterFarms(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.farms.filter(farm => farm.farm_name.toLowerCase().includes(filterValue));
  }
  

  onFarmSelected(event: any): void {
    this.selectedFarm = event.option.value;
  }

 
  
  onFileSelected(event: any): void {
    const file: File = event.target.files[0]; 
    
    if (file) {
      this.selectedFileName = file.name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        let base64String = e.target.result as string;
        const mimeType = base64String.substring(base64String.indexOf(':') + 1, base64String.indexOf(';'));

        base64String = base64String.replace(`data:${mimeType};base64,`, '');
        
        this.photo = base64String; 
        

        this.question.photo = this.photo;
      };
      
   
      reader.readAsDataURL(file); 
  }
}
  removePhoto(): void {
    this.question.photo = null;
    this.selectedFileName = '';  
  }


  toggleView() {
    this.showUploadPhoto = !this.showUploadPhoto;
  }

  onInputBlur(event: FocusEvent) {
    this.showOptions = true;
    setTimeout(() => {
      this.showOptions = false;
    }, 200);
  }
   
  carregarQuestions(): void {
    if (this.sectionId === null) {

      return;
    }

    this.loading = true;
    this.checklistService.questionSectionId(this.sectionId).subscribe(
      questions => {
        if (questions && questions.length > 0) {
          this.questions = questions;
          this.filtrarQuestions();
          this.currentQuestionIndex = 0; 
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        } else {
    
          this.loading = false;
        }
      },
      error => {

        this.loading = false;
      }
    );
  }
  filtrarQuestions(): void {
    const { id, section_id, question,name_equipment_id } = this.filtroForm.value || {};
    this.questionsFiltradas = this.questions.filter(questoes => {
      return (
        questoes.active === true && 
        (id ? (questoes.id || '').toString().toLowerCase().includes(id.toLowerCase()) : true) &&
        (section_id ? (questoes.section_id || '').toLowerCase().includes(section_id.toLowerCase()) : true) &&
        (name_equipment_id?(questoes.name_equipment_id || '').toLowerCase().includes(name_equipment_id.toLowerCase()) : true)&&
        (question ? (questoes.question || '').toString().toLowerCase().includes(question.toLowerCase()) : true)
      );
    });


  }


  async carregarCategoria(): Promise<void> {
    this.loading = true;
    try {
      const categorias = await this.equipmentService.getCategoria().toPromise();
      this.categorias = categorias;
      this.categoriasFiltradas = [...this.categorias];
  
    
    } catch (error) {
      console.error('Erro ao carregar categorias:', error);
      this.openErrorDialog('Erro ao carregar categorias. Por favor, tente novamente.');
    } finally {
      this.loading = false;
    }
  }

  filtrarCategorias():void{ 
    const {id,name}=this.filtroForm.value;  
      this.categoriasFiltradas=this.categorias.filter(categoria=>{
      return(id ? categoria.id.toString().toLowerCase().includes(id.toLowerCase()):true)&&
            (name ?(categoria.name || ''). toLowerCase().includes(name.toLowerCase()):true);
      })
  }

  selecionarCategoria(categoria: string): void {
    this.loading = true;
    const categoriaSelecionada = this.categorias.find(c => c.id === categoria);
    if (categoriaSelecionada) {
     this.EquipmentName=categoriaSelecionada.name;
     this.carregarEquipamento();
    } else {
      console.warn('Categoria não encontrada:', categoria);
      this.loading = false;
    }
  }


  get selectedAnswer() {
    return this.form.get('selectedAnswer')?.value as string;
  }
  get observations() {
    return this.form.get('observacoes').value ; 
  }
  selectAnswer(answer: string) {
    this.form.get('selectedAnswer')?.setValue(answer);
    const currentQuestion = this.getCurrentQuestion();
    if (currentQuestion) {
      currentQuestion.observations = this.observations; 
    }
    if (answer === '2') {
      
      this.showEquipamentoSelect = true;
      this.selecionarCategoria(this.currentNameEquipmentId)
    } else {
      this.showEquipamentoSelect = false; 
    }
  
  }
  
  saveAnswer(answer: string) {

  
    const currentQuestion = this.getCurrentQuestion();
    if (currentQuestion && currentQuestion.id !== undefined) {
      currentQuestion.equipment_id = this.selectedEquipamentoId;  

  
      const existingAnswer = this.answers.find(a => a.id === currentQuestion.id);
      if (existingAnswer) {
        existingAnswer.grade = answer;
        existingAnswer.photo = this.question.photo || null;
        existingAnswer.observations = this.form.get('observacoes')?.value?.toLowerCase();
        existingAnswer.equipment_id = this.selectedEquipamentoId; 
      } else {
        this.answers.push({
          id: currentQuestion.id,
          grade: answer,
          photo: this.question.photo || null,
          observations: this.form.get('observacoes')?.value?.toLowerCase(),
          equipment_id: this.selectedEquipamentoId 
        });
      }
    }
  }
  
  nextQuestion(): void {
 
    if ( (!this.selectedAnswer || this.selectedAnswer.trim() === '')) {
      this.openErrorDialog('Por favor, selecione uma resposta antes de continuar.');
      return; 
    }

    this.saveAnswer(this.selectedAnswer);

    if (this.currentQuestionIndex === this.questionsFiltradas.length - 1) {
      this.enviarChecklist();
    } else {
    
      this.currentQuestionIndex++;
      this.loadNextAnswer(); 
      this.EquipmentControl.reset();
    }
    
    if (this.showEquipamentoSelect) {
      this.EquipmentControl.reset(); 
      this.selectedEquipamentoId = null; 
      this.showEquipamentoSelect = false; 
    }

  }
  
  
  loadNextAnswer(): void {
    const currentQuestion = this.getCurrentQuestion();
    if (currentQuestion) {
      const existingAnswer = this.answers.find(a => a.id === currentQuestion.id);
      if (existingAnswer) {
        this.form.get('selectedAnswer')?.setValue(existingAnswer.grade);
        this.form.get('observacoes')?.setValue(existingAnswer.observations || '');
        this.question.photo = existingAnswer.photo || null;
  
        
        this.selectedEquipamentoId = existingAnswer.equipment_id ;
        this.showEquipamentoSelect = this.selectedEquipamentoId !== null;  
      } else {
        this.form.reset();
        this.removePhoto();
        this.selectedEquipamentoId = null;
        this.showEquipamentoSelect = false;
      }
    }
  }
  
  prevQuestion(): void {
    if (this.currentQuestionIndex > 0) {
      this.saveAnswer(this.selectedAnswer);
      this.currentQuestionIndex--;
      this.loadPreviousAnswer();

      const previousQuestion = this.getCurrentQuestion(); 
      if (previousQuestion) {
        
  

        const savedAnswer = this.answers.find(a => a.id === previousQuestion.id);
        if (savedAnswer) {
          this.form.get('selectedAnswer')?.setValue(savedAnswer.grade); 
          this.question.observations = savedAnswer.observations || '';
          this.question.photo = savedAnswer.photo || null;
          
          this.selectedEquipamentoId = savedAnswer.equipment_id ;
          this.showEquipamentoSelect = this.selectedEquipamentoId !== null;
  
          if (this.selectedEquipamentoId) {

            this.loadEquipamentoDetails(this.selectedEquipamentoId); 
          }
        }
      }
    }
  }
  
  loadPreviousAnswer(): void {
    const currentQuestion = this.getCurrentQuestion();
    
    if (currentQuestion) {
      const existingAnswer = this.answers.find(a => a.id === currentQuestion.id);
      
      if (existingAnswer) {
        this.form.get('selectedAnswer')?.setValue(existingAnswer.grade);
        this.form.get('observacoes')?.setValue(existingAnswer.observations || '');
        this.question.photo = existingAnswer.photo || null;
  
  
        this.selectedEquipamentoId = existingAnswer.equipment_id ;
        this.showEquipamentoSelect = this.selectedEquipamentoId !== null;
  
        if (this.selectedEquipamentoId) {
          this.loadEquipamentoDetails(this.selectedEquipamentoId); 
          this.EquipmentControl.setValue(this.equipamentosFiltrados.find(eq => eq.id === this.selectedEquipamentoId) || null);
        }
      } else {
        this.form.reset();
        this.removePhoto();
        this.selectedEquipamentoId = null;
        this.showEquipamentoSelect = false;
      }
    }
  }
  
  

  getCurrentQuestion(): any {
    const currentQuestion = this.questionsFiltradas[this.currentQuestionIndex];
    
    if (currentQuestion) {
      this.currentNameEquipmentId = currentQuestion.name_equipment_id;
    
      return currentQuestion;
    }
    this.currentNameEquipmentId = null;
    return null;
  }


  enviarChecklist() {
    this.saveAnswer(this.selectedAnswer);  
  
    const requestBody = {
      _id: this.id_random,
      farm_id: this.farmId,
      groupname: this.nameSection,
      executed_by: this.userId,
      created_at: new Date(),
      questions: this.answers.map(answer => {
        return {
          id: answer.id,
          grade: parseInt(answer.grade),
          
          answer: {
            grade: parseInt(answer.grade),
            description: answer.observations,
            picture: answer.photo,
            equipment_id: answer.equipment_id || null,  
          }
        };
      })
    };
  

    this.checklistService.criarCheckList(requestBody).subscribe(
      response => {
        this.closeQuestionsComponent();
        this.dialogRef.close();
      },
      error => {
        this.openErrorDialog('Erro ao enviar o checklist. Por favor, tente novamente.');
        console.error('Erro ao enviar o checklist:', error);
      }
    );
  }

  loadEquipamentoDetails(equipamentoId: number): void {
    this.equipmentService.equipamentoId(equipamentoId)
      .toPromise()
      .then(equipamento => {
        this.selectedEquipamentoId = equipamento; 
        this.changeDetectorRef.markForCheck(); 
      })
      .catch(error => {
        console.error('Erro ao carregar detalhes do equipamento:', error);
      });
  }
  
  carregarSecoes(): void {
    this.loading = true;
    this.checklistService.sectionId(this.sectionId).subscribe(
      (section: any) => {
        if (section) {
          if (section.id === this.sectionId) {
            this.nameSection = section.section; 

          } 
        } 
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      },
      error => {
  
        this.loading = false;
      }
    );
  }

  carregarEquipamento(): void {
    this.loading = true;
  
    this.equipmentService.getEquipamentosQuestions(this.farmId, this.EquipmentName)
      .toPromise()
      .then(equipamentos => {
        this.equipamentos = equipamentos;
        this.equipamentosFiltrados = [...this.equipamentos];
        this.filtrarEquipamentos();
        this.changeDetectorRef.markForCheck();
      })
      .catch(error => {
        console.error('Erro ao carregar equipamentos:', error);
        this.openErrorDialog('Erro ao carregar categorias. Por favor, tente novamente.');
      })
      .finally(() => {
        this.loading = false;
      });
  }
  

  filtrarEquipamentos():void{ 
    const {id,name,type,amount,inventory_number,description}=this.filtroForm.value;  
      this.equipamentosFiltrados=this.equipamentos.filter(equipamento=>{
      return(id ? equipamento.id.toString().toLowerCase().includes(id.toLowerCase()):true)&&
            (name ?(equipamento.name || ''). toLowerCase().includes(name.toLowerCase()):true)&&
            (type ? equipamento.type.toString().toLowerCase().includes(type.toLowerCase()):true)&&
            (amount ? equipamento.amount.toString().toLowerCase().includes(amount.toLowerCase()):true)&&
            (description ? equipamento.description.toString().toLowerCase().includes(description.toLowerCase()):true)&& 
            (inventory_number ? equipamento.inventory_number.toString().toLowerCase().includes(inventory_number.toLowerCase()):true)
            ;
      })
  }
  
  displayEquipamento(equipamento: any): string {
    return equipamento ?`${equipamento.inventory_number} - ${equipamento.name}- ${equipamento.type}` : '';
  }
  
 

selecionarEquipamentoPorId(id: any): void {
  this.selectedEquipamentoId = id;


  const selectedEquipamento = this.equipamentosFiltrados.find(e => e.id === id);
  if (selectedEquipamento) {
    this.EquipmentControl.setValue(selectedEquipamento);
  }
  this.saveAnswer(this.selectedEquipamentoId);
  
}

  


  openErrorDialog(message: string) {
    this.dialog.open(ErrordialogComponent, {
      data: { message: message }
    });
  }
  closeQuestionsComponent(): void {
    this.questionsClosed.emit();
  
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(QuestionsComponent, {
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
   
      this.questionsClosed.emit();
    });
  }
  sair(){
    this.questionsClosed.emit();
    this.dialogRef.close()
  }
}