import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  constructor(public dialogRef:MatDialogRef<AlertComponent>,public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any
) { }

ngOnInit(): void {
}
close(){
  
  this.dialogRef.close(true);
}

fechar(){
  this.dialogRef.close(false)
}
}
