

import { Component, OnInit, ChangeDetectorRef, Input, SimpleChanges } from '@angular/core';
import { ChecklistService } from '../../checklist.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { SettingsService } from 'app/core/services/admin/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PopupQuestionComponent } from '../popup-question/popup-question.component';
import { AlertComponent } from '../../alert/alert/alert.component';
import { ActivatedRoute } from '@angular/router';
import { startWith, map } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'settings-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() sectionId: number | null = null;

  loading = false;
  questionsFiltradas: any[] = [];
  questions: any[] = [];
  secoesFiltradas: any[] = [];
  secoes: any[] = [];

  filtroForm: FormGroup;
  SearchQuestionControl = new FormControl();

  constructor(
    private checklistService: ChecklistService,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.filtroForm = this.fb.group({
      id: [''],
      section_id: [''],
      question: [''],
      order: ['']
    });
  }

  ngOnInit(): void {
    this.checklistService.selectPanel$.subscribe(p => {
      if (p === 'settings-question') {
        this.changeDetectorRef.markForCheck();
      }
    });

    this.changeDetectorRef.detectChanges();
    this.carregarSecoes();
    this.carregarQuestions()
    this.initializeFiltering();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sectionId'] && this.sectionId !== null) {
      this.carregarQuestions();
    }
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.questionsFiltradas, event.previousIndex, event.currentIndex);
    this.updateOrder();
  }

  updateOrder(): void {
    this.questionsFiltradas.forEach((question, index) => {
      question.order = index + 1;
      this.atualizarOrdemNaAPI(question.id, question.order);
    });
  }

  atualizarOrdemNaAPI(id: number, newOrder: number): void {
    const requestBody = { order: newOrder };

    this.checklistService.editarQuestions(id, requestBody).subscribe(
      response => {
     
      },
      error => {
      
        this.dialog.open(AlertComponent, {
          width: '250px',
          data: { message: 'Erro ao atualizar a ordem da pergunta', showSuccessMessage: false }
        });
      }
    );
  }

  carregarQuestions(): void {
    if (this.sectionId === null) {

      return;
    }

    this.loading = true;
    this.checklistService.questionSectionId(this.sectionId).subscribe(
      questions => {
        if (questions && questions.length > 0) {
          this.questions = questions;
          this.filtrarQuestions(); 
          this.updateOrder();
          this.loading = false;

          this.changeDetectorRef.detectChanges();
        } else {
         
          this.loading = false;
        }
      },
      error => {
       
        this.loading = false;
      }
    );
  }

  filtrarQuestions(): void {
    const { id, section_id, question } = this.filtroForm.value || {};
    this.questionsFiltradas = this.questions.filter(questoes => {
      return (
        (id ? (questoes.id || '').toString().toLowerCase().includes(id.toLowerCase()) : true) &&
        (section_id ? (questoes.section_id || '').toLowerCase().includes(section_id.toLowerCase()) : true) &&
        (question ? (questoes.question || '').toString().toLowerCase().includes(question.toLowerCase()) : true)
      );
    });
  }

  carregarSecoes(): void {
    this.loading = true;
    this.checklistService.carregarSecao().subscribe(
      secoes => {
        if (secoes && secoes.length > 0) {
          this.secoes = secoes;
          this.secoesFiltradas = [...this.secoes];
          this.filtrarSecoes();
          this.loading = false;
           this.changeDetectorRef.detectChanges();
        } else {

          this.loading = false;
        }
      },
      error => {
       
        this.loading = false;
      }
    );
  }

  filtrarSecoes(): void {
    const { id, section, type } = this.filtroForm.value || {};
    this.secoesFiltradas = this.secoes.filter(secoes => {
      return (
        (id ? (secoes.id || '').toString().toLowerCase().includes(id.toLowerCase()) : true) &&
        (section ? (secoes.section || '').toLowerCase().includes(section.toLowerCase()) : true) &&
        (type ? (secoes.type || '').toString().toLowerCase().includes(type.toLowerCase()) : true)
      );
    });

   
  }

  private initializeFiltering(): void {
    this.SearchQuestionControl.valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value?.name)),
        map(name => name ? this._filterTipos(name) : this.questions.slice())
      )
      .subscribe(filteredTipos => {
        this.questionsFiltradas = filteredTipos;
        
      });
  }

  private _filterTipos(name: string): any[] {
    const filterValue = name?.toLowerCase();
    return this.questions.filter(questions => questions.question.toLowerCase().includes(filterValue));
  }

  displayTipo(questions: any): string {
    return questions ? questions.question : '';
  }

  getSectionName(section_id: number): string {
    const section = this.secoesFiltradas.find(s => s.id === section_id);
    return section ? section.section : 'Seção não encontrada';
  }

  editarQuestoes(question: any): void {
    this.loading = true;
    const id = question.id;
    this.checklistService.questionsId(id).subscribe(
      response => {
        const dialogRef = this.dialog.open(PopupQuestionComponent, {
          data: { response, editMode: true }
        });
        dialogRef.afterClosed().subscribe(() => {
          this.carregarQuestions();
          this.loading = false;
        });
      },
      error => {

        this.loading = false;
      }
    );
  }

  excluirQuestion(question: any): void {
    this.loading = true;
    const id = question.id;
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { message: 'Você tem certeza que deseja excluir a pergunta?', showSuccessMessage: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.checklistService.deleteQuestion(id).subscribe(
          () => {
            this.dialog.open(AlertComponent, {
              width: '250px',
              data: { message: 'Pergunta excluída com sucesso', showSuccessMessage: true }
            });
            this.carregarQuestions();
         
            this.loading = false;
          },
          error => {

            this.loading = false;
          }
        );
      } else {
        this.loading = false;
      }
    });
  }

  newQuestion(): void {
    const dialogRef = this.dialog.open(PopupQuestionComponent, {
      panelClass: 'custom-dialog-container',
      data: { editMode: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.carregarQuestions();

      }
    });
  }
}
