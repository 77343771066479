<div class="aprovar">

    <h1 mat-dialog-title>Confirmação</h1>
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions class="button-container">
        
      <button mat-button *ngIf="!data.showSuccessMessage"(click)="close()">Cancelar</button>
      <button mat-button *ngIf="data.showSuccessMessage" (click)="close()">Fechar</button>
      <button mat-button *ngIf="!data.showSuccessMessage"[mat-dialog-close]="true">Confirmar</button>
    </div>
    
</div>
