<!-- Navigation -->

<fuse-vertical-navigation
    class="dark bg-green-650 print:hidden overflow-y-auto"
    [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.compact"
    [opened]="!isScreenSmall ">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div
            class="flex items-center justify-center h-20 mt-3 mb-4"
            routerLinkActive="active">
            <a [routerLink]="['/dashboard']">
                <img
                    class="w-15"
                    src="assets/images/logo/logo1.png"
                    alt="Solubio" />
            </a>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 ">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden ">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <!-- Logo -->
        <!-- <mat-form-field class="fuse-mat-no-subscript flex-auto w-full max-w-24 sm:max-w-40 ml-2 sm:ml-4"> -->
        <div class="flex items-center ml-auto">
            <img
                src="assets/images/logo/solubiologo.png"
                class="w-50"
                alt="Solubio" />
        </div>

        <!-- Components -->
        <div class="flex  items-center pl-2 ml-auto space-x-1 sm:space-x-2 overflow-y-auto">
            <fuse-fullscreen class="hidden md:block overflow-y-auto"></fuse-fullscreen>

            <!-- Hide for a while -->
            <!-- <notifications></notifications> -->

            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto overflow-y-auto "  >
        <!-- <div
            class="absolute flex w-100 h-10 z-999"
            style="top: 70px; margin:auto;"> -->

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Solubio &copy; {{currentYear}}</span>
    </div> -->

    <!-- Alert message -->
    <div
        style="
            top: 70px;
            position: absolute;
            z-index: 999;
            width: 400px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        ">
        <fuse-alert *ngIf="alert" [type]="alert.type" [appearance]="'outline'">
            <span fuseAlertTitle>{{ alert.title }}</span>
            {{ alert.message }}
        </fuse-alert>
    </div>
</div>
