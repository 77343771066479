import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModificarService } from './modificar.service';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-modificar',
  templateUrl: './modificar.component.html',
  styleUrls: ['./modificar.component.scss']
})
export class ModificarComponent implements OnInit {
loading=false;

modificarControl: FormControl;

constructor(
  public dialogRef: MatDialogRef<ModificarComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any
) {
  this.modificarControl = new FormControl(data.observacao || '');
}

ngOnInit(): void {
}

cancel(): void {
  this.dialogRef.close();
}

sendObservacao(): void {
  const observacaoModificada = this.modificarControl.value as string;
  this.dialogRef.close(observacaoModificada);
}

}
