import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-cancelar',
  templateUrl: './dialog-cancelar.component.html',
  styleUrls: ['./dialog-cancelar.component.scss']
})
export class DialogCancelarComponent implements OnInit {

  constructor(public dialog: MatDialog,public dialogRef: MatDialogRef<DialogCancelarComponent>) { }

  ngOnInit(): void {
  
    
  }
  close(){
    this.dialogRef.close(true);
  }
}
