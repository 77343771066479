
<div class="flex flex-col max-w-240 md:min-w-160 -m-6 border_style">
    <!-- Header -->
    <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-green-350 text-on-primary">
      <div class="text-lg font-medium">Filtrar Orçamento de Vendas</div>
      <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
      </button>
    </div>
  
    <!-- Compose form-->
    <form [formGroup]="filtroForm" class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
      <!-- Filter by Order Number -->
      <mat-form-field class="flex-auto sm:flex">
        <mat-label>N° Orçamento</mat-label>
        <input matInput placeholder="Nº Orçamento" formControlName="DocEntry"/>
      </mat-form-field>
  
      <!-- Filter by Client -->
      <div class="flex flex-col sm:flex-row w-full gap-2" *ngIf="!isClient">
        <div class="flex-auto mb-2">
          <mat-form-field class="campos" appearance="outline">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <mat-label class="label">Cliente</mat-label>
            <input  type="text" matInput formControlName="CardName"  [readonly]="isClient" [matAutocomplete]="auto" (input)="filtrarClientesPorNome()">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selecionarCliente($event)">
              <mat-option *ngFor="let cliente of filteredClientes | async" [value]="cliente">
                {{ cliente.Nome_do_PN  }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
  
      <!-- Filter by Farm -->
      <mat-form-field appearance="fill">
        <mat-label>Fazenda</mat-label>
        <mat-select formControlName="CardCode">
          <mat-option *ngFor="let farm of farms" [value]="farm.id">
            {{ farm.identity_name ? farm.identity_name + ' - ' + farm.farm_name : farm.farm_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
       <!-- Filter by Date-->
       <div class="date flex ">
        <mat-form-field
        class="fuse-mat-no-subscript w-full sm:max-w-60 mb-4 sm:mb-0">
        <mat-label> Desde</mat-label>
        <input
            matInput
            [formControlName]="'DocDateStart'"
            [matDatepicker]="pickerStart" />
        <mat-datepicker-toggle
            matSuffix
            [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>
  
    <mat-form-field
        class="fuse-mat-no-subscript sm:ml-2 sm:ml-4 w-full sm:max-w-60">
        <mat-label>Até</mat-label>
        <input
            matInput
            [formControlName]="'DocDateEnd'"
            [matDatepicker]="pickerEnd" />
        <mat-datepicker-toggle
            matSuffix
            [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>
    </div>
      <!-- Filter by Status -->
      <div class="flex-auto sm:flex">
        <mat-form-field class="fuse-mat-no-subscript flex-auto w-full mb-4 sm:mb-0">
          <mat-label>Status</mat-label>
          <mat-select multiple appearance="fill" [panelClass]="'w-60 h-70 ml-6 -mt-2'" formControlName="status">
            <mat-option value='Aprovado'>Aprovado</mat-option>
            <mat-option value='Modificações Solicitadas Pendentes'>Modificações Solicitadas Pendentes</mat-option>
            <mat-option value='Pendente de Aprovação'>Pendente de Aprovação</mat-option>
            <mat-option value='Cancelado'>Cancelado</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
        <div class="-ml-2"></div>
        <div class="buttons flex items-center mt-4 sm:mt-0">
          <!-- Cancel button -->
          <button mat-flat-button class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" style="background-color: #6c757d; color: white;" (click)="cancel()">
            <span class="mat-button-wrapper">
              <span class="ml-2 mr-1">Cancelar</span>
            </span>
            <span matripple class="mat-ripple mat-button-ripple"></span>
            <span class="mat-button-focus-overlay"></span>
          </button>
          <!-- Apply Filters button -->
          <button class="order-first sm:order-last" mat-flat-button [ngClass]="'text-green-200 bg-green-350 dark:text-green-50 dark:bg-green-500'" (click)="filter()">
            <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
            <span class="ml-2 mr-1">Aplicar Filtros</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  