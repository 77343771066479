
import { MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';
import { DateTime } from 'aws-sdk/clients/devicefarm';
import { Cadastro, Item } from '../../Types';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { Dialog } from '@angular/cdk/dialog';
import { MatTable } from '@angular/material/table';
import { filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AfterViewInit, Component, OnInit, ViewChild,Input, Inject} from '@angular/core';
import { MatSidenav, MatSidenavContainer} from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { OpenSalesService } from '../open-sales.service';
import { CurrencyPipe } from '@angular/common';
import { DialogEditarComponent } from '../open-sales-dialog/dialog-editar/dialog-editar.component';



@Component({
  selector: 'app-open-sales-order',
  templateUrl: './open-sales-order.component.html',
  styleUrls: ['./open-sales-order.component.scss']
})
export class OpenSalesOrderComponent implements OnInit {

  

  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  @ViewChild('itensScroll') itensScroll: ElementRef;

  clientes:any[]=[];
  clientesFiltrados = new BehaviorSubject<any[]>([]);
  filtroForm: FormGroup;
  apiUrl: string = ''; 
  loading: boolean = false; 
  error: boolean = false; 
  regrasDeCalculo: any[] = [];

  
produto:any = null;
cliente:any = null;
vendedor:any=null;
cdEntrega:any = null;
methodopg:any=null;
codClienteControl=new FormControl();
cpfControl = new FormControl();
telefoneControl = new FormControl();
fazendaControl = new FormControl();
enderecoControl=new FormControl();
dataVencimentoControl=new FormControl();
codPedidoControl=new FormControl();
codControl=new FormControl();
clienteControl=new FormControl();
localEntregaControl=new FormControl();
freteControl=new FormControl();
observacoescadControl=new FormControl();
textoControl=new FormControl();
condicaoPagamentoControl=new FormControl();
inscricaoControl=new FormControl();
inscricaoMunicipalControl=new FormControl();
itemControl = new FormControl();
freteCodigo:any = null;
produtoControl=new FormControl();
vendedorControl=new FormControl();
fretes: any[] = [];
fretesFiltrados: any[] = [];
CdEntregaControl=new FormControl();
emailControl=new FormControl();
observacoesControl=new FormControl();
numeroOCControl=new FormControl();
quantidadeControl=new FormControl();
descontoControl=new FormControl();
aplicacaoControl = new FormControl();
nItemControl = new FormControl();
descricaoProdutoControl = new FormControl();
precoUnitarioControl = new FormControl();
dataEntregaControl=new FormControl();
culturaControl=new FormControl();
quantidadeAplicacoesControl = new FormControl();
doseBiologicaControl = new FormControl();
faixaCompraControl = new FormControl();
faixasDeCompra: any[] = [];
numeroHectaresControl = new FormControl('', [Validators.required]);
custoKgLControl = new FormControl('');
custoTotalHaBiologicoControl = new FormControl('');
nrKitControl = new FormControl({ value: '', disabled: true });
buscaClienteControl = new FormControl();
newValorTotalControl = new FormControl('');
qualidadeProdutoControl = new FormControl('');

items: any[] = []; 
itemsFiltrados: any[] = []; 

numbersCard:any[]=[];
numberCard:any[]=[];
numberCardFiltrado:any[]=[];

vendedores:any[]=[];
vendedoresFiltrados:any[]=[];
listaFormaPagamento:any[]=[];

formasPagamento: any[] = [];
formasPagamentoFiltradas: any[] = [];
formaPagamentoControl = new FormControl();
filteredFormaPagamento: any;
formaPagamentoSelecionada:any = null;
PayMethCodSelecionado: string;

cdsEntrega:any[]=[];
cdsEntregaFiltrados:any[]=[];

filteredItems: any;
filteredItemDescriptions:any;
filteredClientes: any;
filteredVendedores:any;
filteredCdEntrega:any;
filteredCulturas:any;
filteredCondicaoPagamento:any;
culturasFiltradas:any[]=[];
culturas:any[]=[];
linhasSelecionadas:  any;
dadosTabela:any[]=[];
novoItem: any = {};
cabecalho:any[]=[];
private indiceSequencial: number = 1;
contadorPedido: number = 0;
BPLIdSelecionado: number | null = null;
SlpCodeSelecionado: number | null = null;
editarAtivo: boolean = false;
ultimoNumAtCard: string;
GroupNumSelecionado: string;
condicoesPagamento: any[] = [];
condicoesPagamentoFiltradas: any[] = [];
condicao:any = null;
totalInvestimentoPorHa: number = 0;
investimentoTotalNoManejo: number = 0;
public mostrarDetalhesCliente: boolean = false;



  constructor(
    public matDialogRef: MatDialogRef<any>,
    public router: Router,
    private dialog:MatDialog,
    private openService:OpenSalesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectorRef: ChangeDetectorRef,
    private http:HttpClient,
    private currencyPipe: CurrencyPipe,

    )
    {
   
      this.filtroForm=new FormGroup({
      
      U_CADF_Fazenda: new FormControl(''),
      CardCode: new FormControl(''),
      Nome_do_PN: new FormControl('')
      });

      this.apiUrl = environment.apiSap ;

        let items: Item[] = [];
        
       let cabecalho:Cadastro[]=[];

    }
    async ngOnInit(): Promise<void> {

      await this.carregarClientes();
      await this.carregarItems();
      await this.carregarVendedores();
      await this.carregarCdsEntrega();
      await this.carregarMeioCultura();
      await this.carregarRegrasDeCalculo();
      await this.carregarCondicaoPg();
      await this.carregarOrcamentos();
      await this.carregarFormasPg();
      await this.carregarFretes();
      await this.carregarFretes();
      await this.carregarFaixasDeCompra();
      this.onChanges();
      this.changeDetectorRef.detectChanges();
      this.codControl = new FormControl({ value: '', disabled: this.selectUtilizado !== false }, Validators.required);
      this.quantidadeAplicacoesControl.valueChanges.subscribe(() => this.calcularCustoTotalHaBiologico());
      this.doseBiologicaControl.valueChanges.subscribe(() => this.calcularCustoTotalHaBiologico());
      this.custoKgLControl.valueChanges.subscribe(() => this.calcularCustoTotalHaBiologico());
      this.quantidadeControl.valueChanges.subscribe(() => this.validarERecalcularValorTotal());
      this.precoUnitarioControl.valueChanges.subscribe(() => this.validarERecalcularValorTotal());
      
      this.quantidadeAplicacoesControl.valueChanges.subscribe(() => this.atualizarNrKit());
      this.doseBiologicaControl.valueChanges.subscribe(() => this.atualizarNrKit());
      this.numeroHectaresControl.valueChanges.subscribe(() => this.atualizarNrKit());
    
      this.filteredCondicaoPagamento = this.condicaoPagamentoControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          if (typeof value === 'string') {
            return value;
          } else if (value && value.PymntGroup) {
            return value.PymntGroup;
          } else {
            return '';
          }
        }),
        map(nome => nome ? this._filterCondicoesPagamento(nome) : this.condicoesPagamento.slice())
      );
   
      
      this.filteredFormaPagamento = this.formaPagamentoControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.CPAGAMENTO || ''),
        map(nome => nome ? this._filterFormasPg(nome) : this.formasPagamento.slice())
      );

      this.filteredCulturas = this.culturaControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : (value ? value.Name : '')),
        map(name => name ? this._filterCulturas(name) : this.culturas.slice())
      );
      
      this.filteredItems = this.nItemControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : (value ? value.ItemCode : '')),
        map(name => name ? this._filterItems(name) : this.items.slice())
      );
      
      this.filteredItemDescriptions = this.descricaoProdutoControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : (value ? value.ItemName : '')),
        map(description => description ? this._filterItemDescriptions(description) : this.items.slice())
      );

      this.filteredClientes = this.clienteControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Nome_do_PN),
        map(nome => nome ? this._filterClientes(nome) : this.clientes.slice()) 
      );


      this.filteredVendedores=this.vendedorControl.valueChanges.pipe(
        startWith(''), 
        map(value => typeof value=== 'string'? value: value.SlpName),
        map(nome => nome ? this._filterVendedores(nome) :this.vendedores.slice())
      )
 
      this.CdEntregaControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.BPLName),
        map(nome => nome ? this._filterCdsEntrega(nome) : this.cdsEntrega.slice())
      ).subscribe((cdsEntregaFiltrados: any[]) => {
        this.filteredCdEntrega = cdsEntregaFiltrados;
      });
    
        this.vendedorControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.SlpName),
          map(nome => nome ? this._filterVendedores(nome) : this.vendedores.slice())
        ).subscribe((vendedoresFiltrados: any[]) => {
          this.filteredVendedores = vendedoresFiltrados;
        });

        this.culturaControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : (value ? value.Name : '')),
          map(name => name ? this._filterCulturas(name) : this.culturas.slice())
        ).subscribe((culturasFiltradas: any[]) => {
          this.filteredCulturas = culturasFiltradas;
        });

        this.buscaClienteControl.valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value?.Nome_do_PN || '')),
          map(searchTerm => this.filtrarClientes(searchTerm))
        ).subscribe(filteredClients => this.clientesFiltrados.next(filteredClients));

  
        
        combineLatest([
          this.nItemControl.valueChanges,
          this.quantidadeControl.valueChanges,
          this.codControl.valueChanges,
          this.aplicacaoControl.valueChanges,
          this.culturaControl.valueChanges,
          this.dataEntregaControl.valueChanges
        ])
          .pipe(
            filter(([nItem, quantidade, codVenda, aplicacao, cultura, dataEntrega]) =>
              !!nItem && !!quantidade && !!codVenda && !!aplicacao && !!cultura && !!dataEntrega
            )
          )
          .subscribe(([nItem, quantidade, codVenda, aplicacao, cultura, dataEntrega]) => {
            const itemCodePrincipal = nItem;
            const quantidadeItemPrincipal = parseFloat(quantidade) || 1;
        
            const itemPrincipal = {
              codVenda,
              aplicacao,
              cultura,
              dataEntrega
            };
        
            this.atualizarKitsComplementares(itemCodePrincipal, quantidadeItemPrincipal, itemPrincipal);
          });
        
       
      }
        
        private _filterItemDescriptions(name: string): any[] {
          const filterValue = name.toLowerCase();
          return this.items.filter(item => 
            (item.ItemCode && item.ItemCode.toLowerCase().includes(filterValue)) || 
            ((item.ItemName || '').toLowerCase().includes(filterValue))
          );
        }
        
        private _filterCulturas(name: string): any[] {
          const filterValue = name ? name.toLowerCase() : '';
          return this.culturas.filter(cultura => 
            cultura.Name && cultura.Name.toLowerCase().includes(filterValue)
          );
        }
    
      private _filterCondicoesPagamento(nome: string) {
        const filterValue = nome.toLowerCase();
        return this.condicoesPagamento.filter(condicao => condicao.PymntGroup.toLowerCase().includes(filterValue));
      }
    

      private _filterFormasPg(nome: string) {
        const filterValue = nome.toLowerCase();
        return this.formasPagamento.filter(forma => forma.CPAGAMENTO.toLowerCase().includes(filterValue));
      }


      private _filterClientes(value: string): any[] {
        const filterValue = value.toLowerCase();

        // Filtra o array de clientes com base nos atributos especificados
        return this.clientes.filter(cliente => 
          cliente.CardCode.toLowerCase().includes(filterValue) ||
          cliente.Nome_do_PN.toLowerCase().includes(filterValue) ||
          cliente.CNPJ_CPF.toLowerCase().includes(filterValue) ||
          cliente.U_CADF_Fazenda.toLowerCase().includes(filterValue) ||
          cliente.Inscricao_Estadual.toLowerCase().includes(filterValue)
        );
      }
    private _filterVendedores(nome: string): any[] {
      const filterValue = nome.toLowerCase();
      return this.vendedores.filter(vendedor => vendedor.SlpName.toLowerCase().includes(filterValue));
    }

    private _filterCdsEntrega(nome: string): any[] {
      const filterValue = nome.toLowerCase();
      return this.cdsEntrega.filter(cdEntrega => cdEntrega.BPLName.toLowerCase().includes(filterValue));
        
      }

      async carregarClientes(): Promise<void> {
        try {
          const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaClientes').toPromise();
          this.clientes = response.listaClientes;
          this.clientesFiltrados.next(this.clientes); // Inicia com todos os clientes
        } catch (error) {
          console.error('Erro ao carregar clientes:', error);
        }
      }
    
    
    onChanges(): void {
      this.filtroForm.valueChanges.subscribe(val => {
        this.filtrarClientes(''); 
      });
 
    
      this.filtroForm.valueChanges.subscribe(() => {
      });
      this.changeDetectorRef.detectChanges(); 
 
    }

    private filtrarClientes(searchTerm: string = ''): any[] {
      const filterValue = searchTerm.toLowerCase();
      return this.clientes.filter(cliente => 
        (cliente.CardCode && cliente.CardCode.toLowerCase().includes(filterValue)) ||
        (cliente.Nome_do_PN && cliente.Nome_do_PN.toLowerCase().includes(filterValue)) ||
        (cliente.CNPJ_CPF && cliente.CNPJ_CPF.toLowerCase().includes(filterValue)) ||
        (cliente.U_CADF_Fazenda && cliente.U_CADF_Fazenda.toLowerCase().includes(filterValue)) ||
        (cliente.Inscricao_Estadual && cliente.Inscricao_Estadual.toLowerCase().includes(filterValue))
      );
    }
    
    selecionarCliente(event: any) {
      const clienteSelecionado = event.option.value;
      this.codClienteControl.setValue(clienteSelecionado.CardCode);
      this.clienteControl.setValue(clienteSelecionado.Nome_do_PN);
      this.cpfControl.setValue(clienteSelecionado.CNPJ_CPF);
      this.telefoneControl.setValue(clienteSelecionado.Telefone);
      this.fazendaControl.setValue(clienteSelecionado.U_CADF_Fazenda);
      this.inscricaoControl.setValue(clienteSelecionado.Inscricao_Estadual);
      this.enderecoControl.setValue(clienteSelecionado.Address2);
      this.localEntregaControl.setValue(clienteSelecionado.U_CADF_Fazenda);
    }
    
    displayCliente(cliente: any): string {
      return cliente ? `${cliente.Nome_do_PN}` : '';
   }
   

  cancel(): void {
    this.matDialogRef.close(false);
    this.changeDetectorRef.detectChanges();
  }
    
  carregarOrcamentos(): void {
    this.loading = true;
    this.openService.obterAtCard().subscribe(
      numbersCard => {
        this.numberCard = numbersCard.map(card => card.DocEntry.toString()); 
        this.numberCardFiltrado = [...this.numberCard]; 
        this.ultimoNumAtCard = this.numberCard[this.numberCard.length - 1];
      
        this.contadorPedido = 0;
        this.loading = false;
  

        this.separarStringDoNumero(this.ultimoNumAtCard);
   
        
        
        this.contadorPedido = 0;
        this.loading = false;
      },
      error => {
        console.error('Erro ao carregar orçamentos:', error);
        this.loading = false;
      }
    );
  }
  separarStringDoNumero(numAtCard: string): void {

    const numeros = numAtCard.replace(/\D/g, ''); 
  
  }

  formatarPreco(): void {
    let valor = this.precoUnitarioControl.value;
    if (valor) {
      const valorNumerico = parseFloat(valor.replace(/[^\d.-]/g, ''));
      const precoUnitarioFormatado = this.currencyPipe.transform(valorNumerico, 'BRL', 'symbol', '1.2-2');
      this.precoUnitarioControl.setValue(precoUnitarioFormatado, { emitEvent: false });
    }
  }

  private _filter(value: string): any[] {
    const filterValue = this._formatPreco(value);
    return this.items.filter(option => this.currencyPipe.transform(option.Price, 'BRL', 'symbol', '1.2-2').includes(filterValue));
  }

  private _formatPreco(valor: string): string {
    const valorNumerico = parseFloat(valor.replace(/[^\d.-]/g, ''));
    return this.currencyPipe.transform(valorNumerico, 'BRL', 'symbol', '1.2-2') ?? '';
  }

  async carregarItems(): Promise<void> {
    try {
      this.loading = true;
      const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaItems').toPromise();
      this.items = response.listaItems;
      this.itemsFiltrados = [...this.items];
      this.loading = false;
    } catch (error) {
      console.error('Erro ao carregar itens:', error);
      this.loading = false;
    }
  }

  onNewItemInput() {
    if (this.novoItem.nItem || this.novoItem.descricaoProduto) {
      this.dadosTabela.push(this.novoItem);
      this.novoItem = {};
    }
  }

  private _filterItems(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.items.filter(item => 
      (item.ItemCode && item.ItemCode.toLowerCase().includes(filterValue)) || 
      ((item.ItemName || '').toLowerCase().includes(filterValue))
    );
  }
  
displayItem(item: any): string {
  return item ? (item.ItemCode && item.ItemName ? `${item.ItemCode} - ${item.ItemName}` : item.ItemCode || item.ItemName || '') : '';
}

async preencherPrecoUnitario(itemCode: string, item: any): Promise<void> {
  try {
    const faixaSelecionada = this.faixaCompraControl.value;
    if (!faixaSelecionada || !faixaSelecionada.description) {
      console.warn('Nenhuma faixa de compra válida selecionada.');
      item.precoUnitario = '0';
      return;
    }

    const faixaSelecionadaValor = faixaSelecionada.description.match(/\d+k/)?.[0];
    const inoculumsResponse = await this.openService.obterPrecosInoculums().toPromise();
    const cultureMediaResponse = await this.openService.obterPrecosCultureMedia().toPromise();
    const combinedPrices = [...inoculumsResponse, ...cultureMediaResponse];

    const matchingItem = combinedPrices.find(p => 
      p.itemcode === itemCode && 
      p.price_ranges.some(range =>
        range.consumption_range.includes(faixaSelecionadaValor) && 
        range.price_type === "Preço/Unidade"
      )
    );

    if (matchingItem) {
      const matchingRange = matchingItem.price_ranges.find(range => 
        range.consumption_range.includes(faixaSelecionadaValor) && 
        range.price_type === "Preço/Unidade"
      );
      item.precoUnitario = matchingRange?.value?.toFixed(2) || '0.00';
    } else {
      item.precoUnitario = '0';
    }

    console.log(`Preço unitário definido: ${item.precoUnitario}`);
  } catch (error) {
    console.error('Erro ao preencher Preço Unitário:', error);
    item.precoUnitario = '0';
  }
}

async preencherCustoKgL(itemCode: string, item: any) {
  try {
    const faixaSelecionada = this.faixaCompraControl.value;
    console.log('Faixa de compra selecionada:', faixaSelecionada);

    if (!faixaSelecionada || !faixaSelecionada.description) {
      console.warn('Nenhuma faixa de compra válida selecionada.');
      item.custoKgL = ''; // Atualiza diretamente no item
      return;
    }

    const faixaSelecionadaValor = faixaSelecionada.description.match(/\d+k/)?.[0];
    const inoculumsResponse = await this.openService.obterPrecosInoculums().toPromise();
    const cultureMediaResponse = await this.openService.obterPrecosCultureMedia().toPromise();
    
    const combinedPrices = [...inoculumsResponse, ...cultureMediaResponse];
    const matchingItem = combinedPrices.find(p => 
      p.itemcode === itemCode && 
      p.price_ranges.some(range =>
        range.consumption_range.includes(faixaSelecionadaValor) && 
        range.price_type === "Preço/L Kit"
      )
    );

    if (matchingItem) {
      const matchingRange = matchingItem.price_ranges.find(range => 
        range.consumption_range.includes(faixaSelecionadaValor) && 
        range.price_type === "Preço/L Kit"
      );

      item.custoKgL = matchingRange ? matchingRange.value : ''; // Atualiza no item
      console.log('Valor do Custo Kg/L definido como:', matchingRange ? matchingRange.value : 'N/A');
    } else {
      console.warn('Nenhum item correspondente encontrado para ItemCode:', itemCode);
      item.custoKgL = ''; // Limpa o campo se não houver correspondência
    }
  } catch (error) {
    console.error('Erro ao preencher o Custo Kg/L:', error);
    item.custoKgL = '';
  }
}

async selecionarProduto(event: any, item: any) {
  const itemSelecionado = this.itemsFiltrados.find(
    (product) => product.ItemCode === event.option.value || product.ItemName === event.option.value
  );

  if (itemSelecionado) {
    console.log('Produto selecionado:', itemSelecionado);

    // Atualiza propriedades do item principal
    item.nItem = itemSelecionado.ItemCode;
    item.descricaoProduto = itemSelecionado.ItemName;
    item.precoUnitario = this.currencyPipe.transform(itemSelecionado.Price, 'BRL', 'symbol', '1.2-2');

    await this.preencherCustoKgL(itemSelecionado.ItemCode, item);
    await this.preencherPrecoUnitario(itemSelecionado.ItemCode, item);
    this.calcularCustoTotalHaBiologicoParaItem(item);

    // Gerenciar Kits Complementares

    this.changeDetectorRef.detectChanges();
  } else {
    console.warn('Nenhum item encontrado com o valor selecionado:', event.option.value);
  }
}


recalcularValorTotal(item: any): void {
  const quantidade = parseFloat(item.quantidade) || 0;
  
  // Extrair e converter corretamente o valor do preço unitário
  const precoUnitario = parseFloat(
    (item.precoUnitario || '0')
      .toString()
      .replace(/[^\d,-]/g, '') // Remove caracteres não numéricos (exceto separadores de milhar e decimal)
      .replace(',', '.')       // Substitui vírgula por ponto para formato decimal
  ) || 0;

  // Calcular o valor total
  const valorTotal = quantidade * precoUnitario;

  // Armazenar o valor total no item, formatado para duas casas decimais
  item.valorTotal = valorTotal.toFixed(2); 

  console.log(`Valor Total recalculado para o item ${item.nItem}:`, item.valorTotal);
}

validarERecalcularValorTotal(): void {
  const quantidade = parseFloat(this.quantidadeControl.value) || 0;
  const precoUnitarioStr = this.precoUnitarioControl.value ? this.precoUnitarioControl.value.toString() : '0';
  const precoUnitario = parseFloat(precoUnitarioStr.replace(/[^0-9,-]+/g, '').replace(',', '.')) || 0;

  if (quantidade > 0 && precoUnitario > 0) {
    const valorTotal = (quantidade * precoUnitario).toFixed(2);
    this.newValorTotalControl.setValue(valorTotal);
    console.log(`Novo Valor Total: ${valorTotal}`);
  } else {
    this.newValorTotalControl.setValue('0.00');
  }
}

async selecionarProdutoNovoItem(event: any) {
  const itemSelecionado = this.itemsFiltrados.find(
    (product) => product.ItemCode === event.option.value || product.ItemName === event.option.value
  );

  if (itemSelecionado) {
    console.log('Produto selecionado:', itemSelecionado);

    // Atualiza os controles de formulário diretamente para um novo item
    this.nItemControl.setValue(itemSelecionado.ItemCode, { emitEvent: false });
    this.descricaoProdutoControl.setValue(itemSelecionado.ItemName, { emitEvent: false });

    const novoItem = {
      nItem: itemSelecionado.ItemCode,
      descricaoProduto: itemSelecionado.ItemName,
      codVenda: this.codControl.value,
      aplicacao: this.aplicacaoControl.value,
      cultura: this.culturaControl.value,
      dataEntrega: this.dataEntregaControl.value,
      precoUnitario: '',
      custoKgL: '',
    };

    try {
      await this.preencherCustoKgL(itemSelecionado.ItemCode, novoItem);
      this.custoKgLControl.setValue(novoItem.custoKgL, { emitEvent: false });

      await this.preencherPrecoUnitario(itemSelecionado.ItemCode, novoItem);
      this.precoUnitarioControl.setValue(
        this.currencyPipe.transform(novoItem.precoUnitario, 'BRL', 'symbol', '1.2-2'),
        { emitEvent: false }
      );


    } catch (error) {
      console.error('Erro ao preencher os valores ou buscar Inoculum:', error);
    }

  } else {
    console.warn('Nenhum item encontrado com o valor selecionado:', event.option.value);
  }

  this.changeDetectorRef.detectChanges();
}


isItemPrincipalCompleto(itemPrincipal: any): boolean {
  return !!(
    itemPrincipal.codVenda &&
    itemPrincipal.aplicacao &&
    itemPrincipal.cultura &&
    itemPrincipal.dataEntrega &&
    itemPrincipal.numeroOC
  );
}

async atualizarKitsComplementares(itemCodePrincipal: string, quantidadeItemPrincipal: number, itemPrincipal: any) {
  try {
    console.log('Atualizando Kits Complementares para ItemCode:', itemCodePrincipal);

    // Carregar as informações de inoculums e meios de cultura
    const inoculums = await this.openService.obterListaInoculums().toPromise();
    const meiosDeCultura = await this.openService.obterMeiosDeCultura().toPromise();

    const inoculumRelacionado = inoculums.find((inoculum) => inoculum.itemcode === itemCodePrincipal);

    if (inoculumRelacionado && inoculumRelacionado.cultureMedia) {
      const itemCultureMedia = this.itemsFiltrados.find(
        (product) => product.ItemCode === inoculumRelacionado.cultureMedia.itemcode
      );

      if (itemCultureMedia) {
        const meioDeCultura = meiosDeCultura.find((meio) => meio.itemcode === itemCultureMedia.ItemCode);

        if (meioDeCultura) {
          // Calcula a nova quantidade para os kits complementares
          const quantidadeKitsComplementares = quantidadeItemPrincipal * (meioDeCultura.rule_qtd_mult || 1);

          // Buscar preço unitário do kit complementar
          const faixaSelecionada = this.faixaCompraControl.value?.description || '';
          const faixaSelecionadaValor = faixaSelecionada.match(/\d+k/)?.[0];
          const combinedPrices = [
            ...(await this.openService.obterPrecosInoculums().toPromise()),
            ...(await this.openService.obterPrecosCultureMedia().toPromise())
          ];

          const matchingPrice = combinedPrices.find(p =>
            p.itemcode === itemCultureMedia.ItemCode &&
            p.price_ranges.some(range =>
              range.consumption_range.includes(faixaSelecionadaValor) &&
              range.price_type === "Preço/Unidade Meio de Cultura"
            )
          );

          const precoUnitario = matchingPrice?.price_ranges.find(range =>
            range.consumption_range.includes(faixaSelecionadaValor) &&
            range.price_type === "Preço/Unidade Meio de Cultura"
          )?.value || 0;

          // Atualiza ou adiciona o kit complementar na tabela de dados
          const kitExistente = this.dadosTabela.find(
            (item) => item.nItem === itemCultureMedia.ItemCode && item.itemPrincipal === itemCodePrincipal
          );

          if (kitExistente) {
            kitExistente.quantidade = quantidadeKitsComplementares;
            kitExistente.precoUnitario = precoUnitario;

            // Propagar valores do item principal
            kitExistente.codVenda = itemPrincipal.codVenda;
            kitExistente.aplicacao = itemPrincipal.aplicacao;
            kitExistente.cultura = itemPrincipal.cultura;
            kitExistente.dataEntrega = itemPrincipal.dataEntrega;

            this.recalcularValorTotal(kitExistente); // Recalcula o valor total do kit existente
          } else {
            const novoKit = {
              nItem: itemCultureMedia.ItemCode,
              descricaoProduto: itemCultureMedia.ItemName,
              quantidade: quantidadeKitsComplementares,
              precoUnitario: precoUnitario,
              valorTotal: 0, // Será recalculado abaixo
              tipoGrupo: 'kitComplementar',
              itemPrincipal: itemCodePrincipal,
              // Propagar valores do item principal
              codVenda: itemPrincipal.codVenda,
              aplicacao: itemPrincipal.aplicacao,
              cultura: itemPrincipal.cultura,
              dataEntrega: itemPrincipal.dataEntrega,
            };

            this.recalcularValorTotal(novoKit); // Recalcula o valor total do novo kit
            this.dadosTabela.push(novoKit);
          }

          console.log(`Kit Complementar Atualizado para ${itemCultureMedia.ItemCode}:`, quantidadeKitsComplementares);
        } else {
          console.warn(`Meio de Cultura não encontrado para ItemCode: ${itemCultureMedia.ItemCode}`);
        }
      } else {
        console.warn('Nenhum CultureMedia associado encontrado para o ItemCode:', inoculumRelacionado.cultureMedia.itemcode);
      }
    }
  } catch (error) {
    console.error('Erro ao atualizar kits complementares:', error);
  }
}


async carregarVendedores():Promise<void>{
  try {
    this.loading = true;
    const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaVendedores').toPromise();
    this.vendedores = response.listaVendedores;
    this.vendedoresFiltrados = [...this.vendedores];
    this.loading = false;
  
  } catch (error) {
    console.error('Erro ao carregar clientes:', error);
    this.loading = false;
  }
}

  carregarFaixasDeCompra(): void {
    this.openService.obterFaixasDeCompra().subscribe(
      (faixas) => {
        this.faixasDeCompra = faixas;
      },
      (error) => {
        console.error('Erro ao carregar faixas de compra mínima:', error);
      }
    );
  }

 selecionarVendedor(event: any) {
  this.vendedor = event.option.value;
  this.vendedorControl.setValue(this.vendedor.SlpName);
  this.SlpCodeSelecionado = this.vendedor.SlpCode;

}

carregarFretes(): void {
  this.loading = true;
  this.openService.carregarFrete().subscribe(
    fretes => {
      this.fretes = fretes;
      this.fretesFiltrados = [...this.fretes];
      this.loading = false;
    },
    error => {
      console.error('Erro ao carregar lista de fretes: ', error);
      this.loading = false;
    }
  );
}

private async carregarRegrasDeCalculo(): Promise<void> {
  try {
    this.regrasDeCalculo = await this.openService.obterRegrasDeCalculo().toPromise();
    console.log('Regras de cálculo carregadas:', this.regrasDeCalculo); // Verifique se as regras foram carregadas corretamente
  } catch (error) {
    console.error('Erro ao carregar regras de cálculo:', error);
  }
}
calcularQuantidadeKits(itemCode: string, quantidadeAplicacoes: number, doseBiologica: number, numeroHectares: number): number {
  // Verificar se os valores são válidos
  quantidadeAplicacoes = Number(quantidadeAplicacoes) || 0;
  doseBiologica = Number(doseBiologica) || 0;
  numeroHectares = Number(numeroHectares) || 0;

  console.log('Item Code:', itemCode, 'Quantidade Aplicações:', quantidadeAplicacoes, 'Dose Biológica:', doseBiologica, 'Hectares:', numeroHectares);

  // Buscar a regra específica do item
  const regra = this.regrasDeCalculo.find(r => r.item_code === itemCode);

  if (!regra) {
    console.warn(`Regra de cálculo não encontrada para o item ${itemCode}. Usando divisor padrão de 500.`);
  }

  const divisor = regra ? parseFloat(regra.divisor) : 500;
  console.log('Divisor encontrado para cálculo:', divisor);

  // Realizar o cálculo
  const quantidadeKits = (quantidadeAplicacoes * doseBiologica * numeroHectares) / divisor;
  console.log('Quantidade de Kits calculada:', quantidadeKits);

  return Math.ceil(quantidadeKits); // Arredondamento para cima
}

calcularCustoTotalHaBiologicoParaItem(item: any): void {
  const quantidadeAplicacoes = Number(this.quantidadeAplicacoesControl.value) || 0;
  const doseBiologica = Number(this.doseBiologicaControl.value) || 0;
  const custoKgL = Number(item.custoKgL) || 0;

  if (quantidadeAplicacoes && doseBiologica && custoKgL) {
    item.custoTotalHaBiologico = (quantidadeAplicacoes * doseBiologica * custoKgL).toFixed(2);
  } else {
    item.custoTotalHaBiologico = '';
  }
}

calcularCustoTotalHaBiologico(): void {
  const quantidadeAplicacoes = Number(this.quantidadeAplicacoesControl.value) || 0;
  const doseBiologica = Number(this.doseBiologicaControl.value) || 0;
  const custoKgL = Number(this.custoKgLControl.value) || 0;

  if (quantidadeAplicacoes && doseBiologica && custoKgL) {
    const custoTotalHaBiologico = quantidadeAplicacoes * doseBiologica * custoKgL;
    this.custoTotalHaBiologicoControl.setValue(custoTotalHaBiologico.toFixed(2));
  } else {
    this.custoTotalHaBiologicoControl.setValue('');
  }
}

selecionarFrete(event: any): void {
  const descricaoFreteSelecionado = this.freteControl.value;
  const freteSelecionado = this.fretes.find(frete => frete.DESCRICAO === descricaoFreteSelecionado);

  if (freteSelecionado) {
    this.freteControl.setValue(freteSelecionado.DESCRICAO); 
    this.freteCodigo=freteSelecionado.CODIGO;
  }
}

        async carregarCdsEntrega(): Promise<void> {
          try {
            this.loading = true;
            const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaFiliais').toPromise();
            this.cdsEntrega = response.listaFiliais;
            this.cdsEntregaFiltrados = [...this.cdsEntrega];
            this.loading = false;
          } catch (error) {
            console.error('Erro ao carregar clientes:', error);
            this.loading = false;
          }
        }

          selecionarCdEntrega(event: any) {
            this.cdEntrega = event.option.value;
            this.CdEntregaControl.setValue(this.cdEntrega.BPLName);
            this.BPLIdSelecionado = this.cdEntrega.BPLId;
        
        }

        async carregarMeioCultura(): Promise<void> {
          try {
            this.loading = true;
            const response = await this.openService.carregarCultura().toPromise();
            console.log('Meios de Cultura carregados:', response); // Verifique o retorno
            this.culturas = response || []; // Use a resposta diretamente, já que é um array
            this.culturasFiltradas = [...this.culturas];
          } catch (error) {
            console.error('Erro ao carregar meios de cultura:', error);
          } finally {
            this.loading = false;
          }
        }
        

        carregarCondicaoPg(): void {
          this.loading = true;
          this.openService.condicaoPagamento().subscribe(
            condicoespg => {
              this.condicoesPagamento = condicoespg;
             
              this.condicoesPagamentoFiltradas = [...this.condicoesPagamento];
              this.loading = false;
            },
            error => {

              this.loading = false;
            }
          );
        }
      
        selecionarCondicaoPagamento(event: any): void {
          const condicaoSelecionada = event.option.value;
          const condicaoEncontrada = this.condicoesPagamentoFiltradas.find(
            condicao => condicao.PymntGroup === condicaoSelecionada
          );
        
          if (condicaoEncontrada) {
            this.condicaoPagamentoControl.setValue(condicaoEncontrada.PymntGroup);
            this.GroupNumSelecionado = condicaoEncontrada.GroupNum;

        }
      } 
        
        carregarFormasPg(): void {
          this.loading = true;
          this.openService.pagamentoForma().subscribe(
            formaspg => {
              this.formasPagamento = formaspg; 
              this.formasPagamentoFiltradas = [...this.formasPagamento];
              this.loading = false;
            },
            error => {
   
              this.loading = false;
            }
          );
        }

        filtrarFormasPagamento(): void {
          const { FPAGAMENTO } = this.filtroForm.value;
          this.formasPagamentoFiltradas = this.formasPagamento.filter(forma => {
            return FPAGAMENTO ? forma.FPAGAMENTO.toLowerCase().includes(FPAGAMENTO.toLowerCase()) : true;
          });
       
        }
        selecionarFormaPagamento(event: any) {
          this.formaPagamentoSelecionada = event.option.value;
          this.PayMethCodSelecionado = this.formaPagamentoSelecionada.FPAGAMENTO;
          this.formaPagamentoControl.setValue(this.PayMethCodSelecionado);
              
       }

        displayFormaPg(forma?: any): string {
          return forma ? forma.FPAGAMENTO : '';
        }

        get selectUtilizado() {

          return false; 
        }

gerarCodPedido(event: any): void {
  const opcaoSelecionada = event.value;
  
  const partes = opcaoSelecionada.split(' '); 
  const codigoVenda = partes[1]; 
  const numCard = this.numberCardFiltrado.shift();
  let numCardValido = numCard ? numCard : ''; 
  if (numCardValido.length > 0) {
    const numCardArray = numCardValido.split(''); 
    const indexParaModificar = numCardArray.length - 1; 

    if (indexParaModificar >= 0) {
      const novoDigito = (parseInt(numCardArray[indexParaModificar]) + this.contadorPedido+2) % 10; 
      numCardArray[indexParaModificar] = novoDigito.toString();
      numCardValido = numCardArray.join('');

    }
  }

  const codigoPedido = `${codigoVenda}${numCardValido}`;
  this.codPedidoControl.setValue(codigoPedido); 

}

adicionarCadastro() {

const codPedido=this.codPedidoControl.value;
const codCliente=this.codClienteControl.value;
const cliente= this.clienteControl.value;
const cpf_cnpj = this.cpfControl.value;
const telefone = this.telefoneControl.value;
const fazenda = this.fazendaControl.value;
const inscricaoEstadual=this.inscricaoControl.value;
const endereco=this.enderecoControl.value;
const cod = this.codControl.value;
const vendedor = this.vendedorControl.value;
const formaPg=this.formaPagamentoControl.value;
const condicaoPg=this.GroupNumSelecionado;
const localEntrega=this.fazendaControl.value;
const frete=this.freteCodigo;
const cdEntrega=this.CdEntregaControl.value;
const observacoes=this.observacoescadControl.value;

const cadastro: Cadastro = {
codPedido:codPedido,
codCliente:codCliente,
cliente:cliente,
cpf_cnpj:cpf_cnpj,
telefone:telefone,
fazenda:fazenda,
inscricaoEstadual:inscricaoEstadual,
endereco:endereco,
cod: cod,
vendedor:vendedor,
formaPg:formaPg,
condicaoPg:condicaoPg,
localEntrega:localEntrega,
frete:frete,
cdEntrega:cdEntrega,
observacoes:observacoes,

};

this.cabecalho.push(cadastro);
this.changeDetectorRef.detectChanges();

}
async enviarDadosParaAPI() {
  // Verificar se há dados no novo item para adicionar
  if (this.nItemControl.value || this.descricaoProdutoControl.value || this.quantidadeControl.value) {
      const novoItem = {
          nItem: this.nItemControl.value,
          descricaoProduto: this.descricaoProdutoControl.value,
          custoKgL: this.custoKgLControl.value,
          custoTotalHaBiologico: this.custoTotalHaBiologicoControl.value,
          precoUnitario: this.precoUnitarioControl.value,
          quantidade: this.quantidadeControl.value,
          desconto: this.descontoControl.value,
          codVenda: this.codControl.value,
          aplicacao: this.aplicacaoControl.value,
          cultura: this.culturaControl.value,
          dataEntrega: this.dataEntregaControl.value,
          numeroOC: this.numeroOCControl.value,
      };

      // Adicionar o novo item à lista de dadosTabela
      this.dadosTabela.push(novoItem);

      // Limpar os controles do novo item
      this.nItemControl.reset();
      this.descricaoProdutoControl.reset();
      this.custoKgLControl.reset();
      this.custoTotalHaBiologicoControl.reset();
      this.precoUnitarioControl.reset();
      this.quantidadeControl.reset();
      this.descontoControl.reset();
      this.codControl.reset();
      this.aplicacaoControl.reset();
      this.culturaControl.reset();
      this.dataEntregaControl.reset();
      this.numeroOCControl.reset();
  }

  console.log('Itens antes de enviar para API:', this.dadosTabela);

  // Continuar com o restante da lógica para preparar o requestBody e enviar para a API
  const codPedido = this.codPedidoControl.value;
  const codCliente = this.codClienteControl.value;
  const dataVenc = this.dataVencimentoControl.value;
  const CdEntrega = this.BPLIdSelecionado;
  const vendedor = this.SlpCodeSelecionado;
  const observacao = this.observacoescadControl.value;
  const metodopg = this.formaPagamentoControl.value;
  const condicaoPg = this.GroupNumSelecionado;
  const frete = this.freteCodigo;
  const endereco = this.enderecoControl.value;

  const documentLines = this.dadosTabela.map(item => {
      const precoUnitarioNumerico = parseFloat(
          (item.precoUnitario || '0')
              .toString() // Garante que o valor seja uma string
              .replace(/[^\d,.-]/g, '') // Remove caracteres não numéricos
              .replace(',', '.') // Substitui vírgula por ponto
      ) || 0;

      return {
          ItemCode: item.nItem,
          Quantity: item.quantidade,
          UnitPrice: precoUnitarioNumerico,
          Usage: item.codVenda ? item.codVenda.substring(0, 3) : '',
          U_SOLU_Cultura: item.cultura,
          U_SOLU_TP_Aplicacao: item.aplicacao,
          U_OM_NumberOC: item.numeroOC,
          U_OM_NumberOCLine: null,
          ShipDate: item.dataEntrega,
          DiscountPercent: item.desconto
      };
  });

  const requestBody = {
      CardCode: codCliente,
      DocDueDate: dataVenc,
      DocObjectCode: 23,
      BPL_IDAssignedToInvoice: CdEntrega,
      SalesPersonCode: vendedor,
      NumAtCard: codPedido,
      Comments: observacao,
      U_SOLU_MEIO_PAG: metodopg,
      PaymentGroupCode: condicaoPg,
      DiscountPercent: 0.0,
      Address: "Entrega",
      Address2: endereco,
      DocumentLines: documentLines,
      TaxExtension: {
          Incoterms: frete
      }
  };

  this.loading = true;

  (await this.openService.enviarPedido(requestBody)).subscribe(
      (response) => {
          const successDialogRef = this.dialog.open(DialogEditarComponent, {
              width: '350px',
              data: { message: 'Orçamento enviado com Sucesso!', showSuccessMessage: true }
          });
          this.loading = false;
          successDialogRef.afterClosed().subscribe(() => {
              this.matDialogRef.close();
              this.enviarEmail(response.DocEntry);
          });
          this.matDialogRef.close(true);
      },
      (error) => {
          this.loading = false;
          console.error('Erro ao enviar dados para a API:', error);
          let errorMessage = 'Ocorreu um erro ao enviar os dados para a API.';
          if (error.error && error.error.message) {
              errorMessage = error.error.message;
          }
          this.dialog.open(DialogEditarComponent, {
              width: '350px',
              data: { message: errorMessage, showSuccessMessage: true }
          });
      }
  );
}

    enviarEmail(docentry:number){
        this.openService.enviarPDf(docentry).toPromise();
    }


  verificarEModificarCodVenda(): void {
    const novoCodVenda = this.codControl.value; 
    this.dadosTabela.forEach(item => {
      if (item.cod_venda !== novoCodVenda) {
        item.cod_venda = novoCodVenda; 
      }
    });


    this.changeDetectorRef.detectChanges();
  }

// Função que retorna os dados da linha de inserção
getDadosLinhaInsercao() {
  return {
    numeroHectares: parseFloat(this.numeroHectaresControl.value) || 0,
    custoTotalHaBiologico: parseFloat(this.custoTotalHaBiologicoControl.value) || 0
  };
}

// Função para calcular Total (Investimento por Ha)
calcularInvestimentoPorHa(): void {
  const dadosInsercao = this.getDadosLinhaInsercao();
  this.totalInvestimentoPorHa = this.dadosTabela.reduce((total, item) => {
    const custoTotalHaBiologico = parseFloat(item.custoTotalHaBiologico) || 0;
    return total + custoTotalHaBiologico;
  }, 0) + dadosInsercao.custoTotalHaBiologico;
}

// Função para calcular Investimento Total no Manejo
calcularInvestimentoTotalNoManejo(): void {
  const dadosInsercao = this.getDadosLinhaInsercao();
  this.investimentoTotalNoManejo = this.dadosTabela.reduce((total, item) => {
    const numeroHectares = parseFloat(item.numeroHectares) || 0;
    const custoTotalHaBiologico = parseFloat(item.custoTotalHaBiologico) || 0;
    return total + (numeroHectares * custoTotalHaBiologico);
  }, 0) + (dadosInsercao.numeroHectares * dadosInsercao.custoTotalHaBiologico);
}

atualizarCalculos(): void {
  this.calcularInvestimentoPorHa();
  this.calcularInvestimentoTotalNoManejo();
  
  console.log('Total Investimento Por Ha:', this.totalInvestimentoPorHa); // Log do total por Ha
  console.log('Investimento Total no Manejo:', this.investimentoTotalNoManejo); // Log do total no manejo
}


atualizarNrKit(): void {
  const itemCode = this.nItemControl.value;
  const quantidadeAplicacoes = Number(this.quantidadeAplicacoesControl.value) || 0;
  const doseBiologica = Number(this.doseBiologicaControl.value) || 0;
  const numeroHectares = Number(this.numeroHectaresControl.value) || 0;

  if (itemCode) {
    const quantidadeKits = this.calcularQuantidadeKits(itemCode, quantidadeAplicacoes, doseBiologica, numeroHectares);
    this.nrKitControl.setValue(quantidadeKits.toString());
    this.quantidadeControl.setValue(Math.ceil(quantidadeKits)); // Atualiza quantidade com valor arredondado
  } else {
    this.nrKitControl.setValue('');
    this.quantidadeControl.setValue(''); // Limpa o campo se o código do item não estiver definido
  }
}


recalcularNrKit(item: any): void {
  if (item.nItem && item.quantidadeAplicacoes && item.doseBiologica && item.numeroHectares) {
    const quantidadeKits = this.calcularQuantidadeKits(
      item.nItem,
      item.quantidadeAplicacoes,
      item.doseBiologica,
      item.numeroHectares
    );
    
    // Arredonda o valor de Nr Kit para cima e atualiza a quantidade com o mesmo valor
    item.nrKit = quantidadeKits.toString();
    item.quantidade = Math.ceil(quantidadeKits);
  } else {
    item.nrKit = '';
    item.quantidade = ''; // Limpa a quantidade se os valores forem inválidos
  }
}

recalcularNovoValorTotal(): void {
  const quantidade = parseFloat(this.quantidadeControl.value) || 0;
  const precoUnitario = parseFloat(
    (this.precoUnitarioControl.value || '0').replace(/\./g, '').replace(',', '.')
  ) || 0;

  const valorTotal = quantidade * precoUnitario;
  console.log(`Recalculando Valor Total: Quantidade (${quantidade}) * Preço Unitário (${precoUnitario}) = ${valorTotal}`);
  
  this.newValorTotalControl.setValue(valorTotal.toFixed(2));
}

adicionarNovoItem(): void {
  const novoItem = {
    nItem: this.nItemControl.value,
    descricaoProduto: this.descricaoProdutoControl.value,
    quantidadeAplicacoes: this.quantidadeAplicacoesControl.value,
    doseBiologica: this.doseBiologicaControl.value,
    numeroHectares: this.numeroHectaresControl.value,
    custoKgL: this.custoKgLControl.value,
    custoTotalHaBiologico: this.custoTotalHaBiologicoControl.value,
    precoUnitario: this.precoUnitarioControl.value,
    quantidade: this.quantidadeControl.value,
    desconto: this.descontoControl.value,
    codVenda: this.codControl.value,
    aplicacao: this.aplicacaoControl.value,
    cultura: this.culturaControl.value,
    dataEntrega: this.dataEntregaControl.value,
    numeroOC: this.numeroOCControl.value,
    nrKit: this.nrKitControl.value,
    valorTotal: this.newValorTotalControl.value,
  };

  this.recalcularValorTotal(novoItem);   this.dadosTabela.push(novoItem);

  this.atualizarCalculos();
  this.recalcularNrKit(novoItem);
  this.limparCamposNovoItem();
}

limparCamposNovoItem(): void {
  this.nItemControl.reset();
  this.descricaoProdutoControl.reset();
  this.quantidadeAplicacoesControl.reset();
  this.doseBiologicaControl.reset();
  this.numeroHectaresControl.reset();
  this.nrKitControl.reset();
  this.custoKgLControl.reset();
  this.custoTotalHaBiologicoControl.reset();
  this.precoUnitarioControl.reset();
  this.quantidadeControl.reset();
  this.descontoControl.reset();
  this.codControl.reset();
  this.aplicacaoControl.reset();
  this.culturaControl.reset();
  this.dataEntregaControl.reset();
  this.numeroOCControl.reset();
  this.newValorTotalControl.reset();
}

  scrollToLeft() {
    this.itensScroll.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
  }
  
  
  removerItem(item: any): void {
    // Filtrar o item a ser removido
    this.dadosTabela = this.dadosTabela.filter(i => i !== item);
  
    // Remover kits complementares associados
    if (item.nItem) {
      this.removerKitsComplementares(item.nItem);
    }
  
    console.log('Itens após remover item:', this.dadosTabela);
    this.atualizarCalculos();
    this.changeDetectorRef.detectChanges();
  }

  removerKitsComplementares(itemPrincipalCode: string): void {
  this.dadosTabela = this.dadosTabela.filter(i => i.itemPrincipal !== itemPrincipalCode);
  console.log(`Kits complementares associados ao item ${itemPrincipalCode} foram removidos.`);
}

  onBlur(item: any, campo: string): void {
    console.log(`Campo "${campo}" alterado para:`, item[campo]); // Log do valor alterado
    
    this.dadosTabela = this.dadosTabela.map(i => {
      if (i === item) {
        return { ...i, [campo]: item[campo] };
      }
      return i;
    });
  
    console.log(`Dados Atualizados no onBlur para o item:`, this.dadosTabela); // Log após atualização
  }

}
