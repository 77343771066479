import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _selectPanel: Subject<any> = new Subject();

  constructor() {}

  get selectPanel$(): Observable<any> {
    return this._selectPanel.asObservable();
  }

  doSelectPanel(panel: string): any {
    this._selectPanel.next(panel);
  }
}
