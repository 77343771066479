import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-dialog-error-editar',
  templateUrl: './dialog-error-editar.component.html',
  styleUrls: ['./dialog-error-editar.component.scss']
})
export class DialogErrorEditarComponent implements OnInit {

  constructor(public dialogRef:MatDialogRef<DialogErrorEditarComponent>,public dialog:MatDialog) { }

  ngOnInit(): void {
  }
  close(){
    this.dialogRef.close(true);
  }

}
