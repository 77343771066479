import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SalesService {

  apiUrl: string = ''; 

  constructor(private http: HttpClient) { 
    this.apiUrl = environment.apiSap;
  }

  carregarVendas(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaPedidos').pipe(
      map(response => {
        return response.listaPedidos || [];
      }),
      catchError(error => {
        console.error('Erro ao carregar pedidos:', error);
        return throwError(error);
      })
    );
  }



  obterClientes():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaClientes').pipe(
      map(response=>{
      return response.listaClientes || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar lista de vendedores :', error);
      return throwError(error);
    })
  );
  }


  vendaDetails(DocEntry: number): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/pedido/${DocEntry}`; 
    return this.http.get<any>(url);
  }

  filtrarPedidoPorCardCode(CardCode: any): Observable<any[]> {
    if (!CardCode) {
      return of([]);
    }
  
    const url = `${this.apiUrl}/api/consulta/listaPedidos?CardCode='${CardCode}'`;
    return this.http.get<any>(url).pipe(
      map(response => {
        return response.listaPedidos || [];
      }),
      catchError(error => {
        console.error('Erro ao filtrar pedidos por CardCode:', error);
        return throwError(error);
      })
    );
  }

  buscarDados(parameters: string):Observable<any[]> {
    const url=`${this.apiUrl}${parameters}`
    return this.http.get<{ listaPedidos: any[] }>(url).pipe(
      map(response => {
        return response.listaPedidos || [];
      })
      
    );
  }

  obterVendedores():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaVendedores').pipe(
      map(response=>{
      return response.listaVendedores || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar lista de vendedores :', error);
      return throwError(error);
    })
  );
  }

  filtrarPorNomeUsuario(emailVendedor: string): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/listaOrcamentos?EmailVendedor='${emailVendedor}'`;
    return this.http.get<any>(url).pipe(
      map(response => {
        return response.listaOrcamentos || [];
      })
      
    );
  }
  carregarFrete(){
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaFrete').pipe(
      map(response=>{
      return response.listaFrete || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar lista de vendedores :', error);
      return throwError(error);
    })
  );
  }

  obterMetodoPagamento():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaFormaPagamento').pipe(
      map(response=>{
      return response.listaFormaPagamento || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar lista de vendedores :', error);
      return throwError(error);
    })
  );
  }

  obterCondicaoPagamento():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaCondicaoPagamento').pipe(
      map(response=>{
      return response.listaCondicaoPagamento || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar lista de vendedores :', error);
      return throwError(error);
    })
  );
  }


  editarOrcamento(DocEntry: number){

    const url = `${this.apiUrl}/api/orcamentos/editar/${DocEntry}`;
    const requestBody = { DocEntry };
    return this.http.post<any>(url, requestBody);
}



filtrarPedidoPorVendedor(emailVendedor: any): Observable<any[]> {
  const url = `${this.apiUrl}/api/consulta/listaPedidos?emailVendedor='${emailVendedor}'`;
  return this.http.get<any>(url).pipe(
    map(response => {
      return response.listaPedidos || [];
    })
    
  );
}

}