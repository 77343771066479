import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ModificarService {
  private observacaoSource = new BehaviorSubject<string>('');
  currentObservacao = this.observacaoSource.asObservable();

  constructor() {}

  changeObservacao(observacao: string) {
    this.observacaoSource.next(observacao);
  }
}
