import { User } from 'app/core/models/auth/user.model';
import { JobTitle } from './../../models/squads/jobtitle.model';
import { JobTitleService } from './../squads/jobtitle.service';
import { UsersService } from 'app/core/services/admin/users.service';
import { TerritoryService } from './../squads/territory.service';
import { Regional, Territory } from 'app/core/models/squads/territory.model';
import { FilterModel } from './../../models/admin/filter.models';
import { FuseConfigService } from '@fuse/services/config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap, catchError } from 'rxjs/operators';
import { SquadPagination, Squad } from 'app/core/models/admin/squads.model';
import { environment } from 'environments/environment';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root',
})
export class SquadsService {
  public _filter: BehaviorSubject<FilterModel[] | null> = new BehaviorSubject(
    null
  );

  private _pagination: BehaviorSubject<SquadPagination | null> =
    new BehaviorSubject(null);
  private _squad: BehaviorSubject<Squad | null> = new BehaviorSubject(null);
  private _squads: BehaviorSubject<Squad[] | null> = new BehaviorSubject(null);
  private host: string;

  private squads: Squad[];
  private territories: Territory[];
  private titles: JobTitle[];
  private users: User[];

  constructor(
    private _httpClient: HttpClient,
    private _fuseConfigService: FuseConfigService,
    private _territoryService: TerritoryService,
    private _usersService: UsersService,
    private _jobtitleService: JobTitleService
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.custHost;
    });
  }

  get pagination$(): Observable<SquadPagination> {
    return this._pagination.asObservable();
  }

  get squad$(): Observable<Squad> {
    return this._squad.asObservable();
  }

  get squads$(): Observable<Squad[]> {
    return this._squads.asObservable();
  }

  getSquads(
    page: number = 0,
    size: number = 10,
    sort: string = 'created_at',
    order: 'asc' | 'desc' | '' = 'asc',
    filters: FilterModel[] = undefined
  ): Observable<Squad[]> {
    let param = {
      limit: size,
      offset: page * size,
      orderby: sort,
      order,
    };
    if (filters) {
      filters.map(f => {
        const fil = {};
        fil[f.field] = f.filter;
        param = { ...param, ...fil };
      });
    }

    return this._httpClient
      .get<any>(this.host + '/api/v1/squads', {
        params: param,
      })
      .pipe(
        map(resp => resp),
        tap(response => {
          this._pagination.next({
            length: response.count,
            page: page,
            size: size,
            lastPage: 0,
            startIndex: 0,
            endIndex: 0,
          });

          this._territoryService
            .getTerritories()
            .pipe(take(1))
            .subscribe(f => {
              this.territories = f;

              this._jobtitleService
                .getTitles()
                .pipe(take(1))
                .subscribe(f => {
                  this.titles = f;

                  this._usersService
                    .getUsersMemory()
                    .pipe(take(1))
                    .subscribe(f => {
                      this.users = f;

                      response.map(s => {
                        s.territory = this.getTerritoryName(s.territory_id);
                      });

                      this.squads = response;
                      this.sortSquad({ active: '', direction: 'asc' });
                    });
                });
            });
        })
      );
  }

  getSquadById(id: string): Observable<Squad> {
    return this._httpClient
      .get<Squad>(this.host + '/api/v1/product_squad/' + id)
      .pipe(take(1));
  }

  createSquad(squad: Squad): Observable<Squad> {
    return this.squads$.pipe(
      take(1),
      switchMap(squads =>
        this._httpClient.post<Squad>(this.host + '/api/v1/squads', squad).pipe(
          map(newSquad => {

            this._squads.next([newSquad, ...squads]);
            this.squads = [newSquad, ...squads];
            return newSquad;
          })
        )
      )
    );
  }

  deleteSquad(id: string): Observable<Squad> {
    return this.squads$.pipe(
      take(1),
      switchMap(squads =>
        this._httpClient.delete<Squad>(this.host + '/api/v1/squads/' + id).pipe(
          map(newSquad => {

            squads = squads.filter(f => f.id.toString() != id);

            this._squads.next(squads);
            this.squads = squads;
            return newSquad;
          })
        )
      )
    );
  }

  updateSquad(id: string, data: Squad): Observable<Squad> {
    return this.squads$.pipe(
      take(1),
      switchMap(squad =>
        this._httpClient
          .put<Squad>(this.host + '/api/v1/squads/' + id, data)
          .pipe(
            map(updatedSquad => {

              const index = squad.findIndex(item => item.id.toString() == id);


              squad[index] = updatedSquad;


              this._squads.next(squad);
              this.squads = squad;
              return updatedSquad;
            })
          )
      )
    );
  }

  sendFileSquad(id: string, file: any): Observable<Squad> {
    const formData = new FormData();
    formData.append('file', file.files[0]);
    return this._httpClient.put<Squad>(
      this.host + '/api/v1/product_squad/' + id,
      formData
    );
  }

  get filter$(): Observable<FilterModel[]> {
    return this._filter.asObservable();
  }

  downloadFile(data: any): void {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
}

download(onProgress: (progress: number) => void, onError: (error: any) => void): void {
    this._httpClient
        .get(this.host + '/api/v1/squads/export/customers', {
            observe: 'events',
            responseType: 'blob',
            reportProgress: true
        })
        .subscribe(event => {
            if (event.type === HttpEventType.DownloadProgress) {
                const progress = Math.round((100 * event.loaded) / (event.total || 1));
                console.log(`Progresso do download de Squads: ${progress}%`);
                onProgress(progress);
            } else if (event.type === HttpEventType.Response) {
                this.downloadFile(event.body);
                onProgress(100);
            }
        }, error => {
            console.error('Erro no download de Squads:', error);
            onError(error);
        });
}

  searchSquad(search: string): Observable<Squad[]> {
    search = search.toLowerCase();

    const filteredUsers = this.users
      .filter(user =>
        (
          user.firstname.toLowerCase() +
          ' ' +
          (user.lastname.toLowerCase() || '')
        ).includes(search)
      )
      .map(u => u.id);
    const filtered = this.squads.filter(
      f =>
        f.squad.map(sq => sq.user_id).some(e => filteredUsers.includes(e)) ||
        f.code?.toLowerCase().includes(search)
    );

    this._squads.next(filtered);
    return of(filtered);
  }

  getTerritoryName(id: string): string {
    if (!this.territories) {
      return '';
    }
    return this.territories
      .filter(f => {
        return f.id.toString() == id;
      })
      .map(fa => {
        return fa.name;
      })[0];
  }

  getTerritoryRegionalName(id: string): string {
    if (!this.territories) {
      return '';
    }
    return this.territories
      .filter(f => {
        return f.id.toString() == id;
      })
      .map(fa => {
        return 'Regional ' + fa.regional_id;
      })[0];
  }

  sortSquad(sort: Sort): void {
    this.squads = this.squads.sort((obj1, obj2) => {
      const name1 = obj1.code;
      const name2 = obj2.code;
      if (sort.direction == 'asc') {
        return name1.localeCompare(name2);
      } else {
        return name2.localeCompare(name1);
      }
    });
    this._squads.next(this.squads);
  }
}
