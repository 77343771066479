<div class="pedido sidebar relative overflow-y-scroll w-full h-full meu-dialog">
 
   
    <div class=" flex items-center justify-between pt-0 h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">Pedido de Vendas</div>
        <button mat-icon-button="" class="mat-focus-indicator mat-icon-button mat-button-base" tabindex="-1" (click)="cancel()">
            <span class="mat-button-wrapper">
                <mat-icon role="img" class="mat-icon notranslate text-current mat-icon-no-color" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </mat-icon>
            </span>
            <span class="mat-button-focus-overlay"></span>
        </button>
    </div>

    <div class="header">
        <div class="title-cabecalho">Cabeçalho
        <mat-form-field class="campos_cod" appearance="outline">
            <mat-label class="label">Cod Pedido</mat-label>
            <input type="text" matInput [formControl]="codPedidoControl" [readonly]="codPedidoControl.value!==null ">
        </mat-form-field>
    </div>
</div>
    <div class="cadastro">
        <div class="contents1">
            <mat-form-field class="campos_cod" appearance="outline">
                <mat-label class="label">Cod Cliente</mat-label>
                <input type="text" matInput [formControl]="codClienteControl" [readonly]="codClienteControl.value!==null ">
            </mat-form-field>
            <mat-form-field class="campos" appearance="outline">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <mat-label class="label">Cliente</mat-label>
                <input type="text" matInput [formControl]="clienteControl" [readonly]="clienteControl.value!==null ">
            </mat-form-field>
            <mat-form-field class="fuse-mat-no-subscript w-full sm:flex-auto sm:max-w-40 mr-2" appearance="outline">
                <mat-label>Data de Entrega</mat-label>
                <input
                  matInput 
                  [formControl]="dataVencimentoControl"
                  [matDatepicker]="pickerScheduledE"
                  [readonly]="dataVencimentoControl.value!=null"/>
                <mat-datepicker-toggle matSuffix [for]="pickerScheduledE" disabled="dataVencimentoControl.value!=null"></mat-datepicker-toggle>
                <mat-datepicker #pickerScheduledE></mat-datepicker>
              </mat-form-field>

        </div>
        <div class="contents2">
            <mat-form-field class="campos_cod" appearance="outline">
                <mat-label class="label">CPF/CNPJ</mat-label>
                <input type="text" matInput [formControl]="cpfControl" [readonly]="cpfControl.value!==null ">
            </mat-form-field>
            <mat-form-field class="campos_tel" appearance="outline">
                <mat-label class="label">Telefone</mat-label>
                <input type="text" matInput [formControl]="telefoneControl"  [readonly]="telefoneControl.value!==null ">
            </mat-form-field>
            <mat-form-field class="campos" appearance="outline">
                <mat-label class="label">Fazenda</mat-label>
                <input type="text" matInput [formControl]="fazendaControl" [readonly]="fazendaControl.value!==null ">
            </mat-form-field>
            <mat-form-field class="campos" appearance="outline">
                <mat-label class="label">Inscrição Estadual</mat-label>
                <input type="text" matInput [formControl]="inscricaoControl" [readonly]="inscricaoControl.value!==null ">
            </mat-form-field>        
        </div>
        <div class="contents3">
        <mat-form-field class="example-container accent" appearance="outline">
            <mat-label>Frete</mat-label>
            <input type="text" matInput [formControl]="freteControl" [readonly]="freteControl.value!==null ">
        </mat-form-field>
        <mat-form-field class="campos" appearance="outline">
            <mat-label class="label">Endereço</mat-label>
            <input type="text" matInput [formControl]="enderecoControl"   [readonly]="enderecoControl.value!==null ">
        </mat-form-field>

            <mat-form-field class="campos-local" appearance="outline">
                <mat-label class="label">Local de Entrega</mat-label>
                <input type="text" matInput [formControl]="localEntregaControl"   [readonly]="localEntregaControl.value!==null ">
            </mat-form-field>

        </div>
        <div class="contents34">
            <mat-form-field class="example-container cdEntrega accent" appearance="outline">
                <mat-label>Cd.Entrega</mat-label>
                <input type="text" matInput [formControl]="CdEntregaControl"   [readonly]="CdEntregaControl.value!==null ">
            </mat-form-field>
            <mat-form-field class="campo-vendedor" appearance="outline">
                <mat-label class="label">Vendedor</mat-label>
                <input type="text" matInput [formControl]="vendedorControl"   [readonly]="vendedorControl.value!==null ">
            </mat-form-field>
            <mat-form-field class="campo-fin" appearance="outline">
                <mat-label class="label">Condições de Pagamento</mat-label>
                <input type="text" matInput [formControl]="condPagamentoControl"[readonly]="condPagamentoControl.value!==null ">
            </mat-form-field>
            <mat-form-field class="campo-fin" appearance="outline">
                <mat-label class="label">Meio de Pagamento</mat-label>
                <input type="text" matInput [formControl]="meioPagamentoControl" [matAutocomplete]="automeioPagamento" >
                <mat-autocomplete #automeioPagamento="matAutocomplete"  >

            </mat-autocomplete>
              </mat-form-field>

        </div>
        <div class="contents4">
            <mat-form-field class="camposObs" appearance="outline">
                <mat-label class="label">Observações</mat-label>
                <input type="text" matInput [formControl]="observacoescadControl"  [readonly]="observacoescadControl.value!==null">
            </mat-form-field>
           
        </div>
    </div>  
        <div class="title1">Itens do Pedido</div>

    <div class="itens">
       
    <div class="table-vendas" appearance="">
        <table>
            <thead>
              <tr>
           
                <th>Cod Item</th>
                <th>Descricao Do Produto</th>
                <th>Quantidade</th>
                <th>Aplicação</th>
                <th>Cultura</th>
                <th>Preço Unit.</th>
                <th>Desconto</th>
                <th>Preço c/Desconto</th>
                <th>Total</th>
                <th>Cod Venda</th>
                <th>Data de Entrega</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dadosTabela; let i = index" (click)="selecionarLinha(item)"  [class.selected]="linhasSelecionadas === item" >
                <td>{{ item.nItem }}</td>
                <td>{{ item.descricaoProduto }}</td>
                <td>{{ item.quantidade  }}</td>
              
                <td>{{ item.aplicacao }}</td>
                <td>{{item.cultura}}</td>
                <td>{{ item.precoUnitario }}</td>
                <td>{{ item.desconto| number: '1.0-0' }}%</td>
                <td>{{ item.precoDesconto }}</td>
                <td>{{ item.total }}</td>
                <td>{{ item.cod_venda}}</td>
                <td>{{item.data | date:'dd/MM/yyyy'  }}</td>
                <td>
                </td>
              </tr>

                <tr *ngIf="dadosTabela.length === 0">
                  <td colspan="2">Nenhum dado disponível</td>
            </tbody>
      
          </table>
      </div>
     
      
    </div>
</div>