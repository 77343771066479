<div class="pedido relative overflow-y-scroll  h-ful meu-dialog pt-0">
  <div *ngIf="loading" class="loading-overlay">
      <mat-spinner diameter="50" color="accent"></mat-spinner>
    </div>
  <div class="sidebar flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
      <div class="text-lg font-medium">Orçamento de Venda</div>
      <button mat-icon-button="" class="mat-focus-indicator mat-icon-button mat-button-base" tabindex="-1" (click)="cancel()">
          <span class="mat-button-wrapper">
              <mat-icon role="img" class="mat-icon notranslate text-current mat-icon-no-color" aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
              </mat-icon>
          </span>
          <span class="mat-button-focus-overlay"></span>
      </button>
  </div>
  <div class="header">
      <div  class="title-cabecalho">
          Cabeçalho
        
    
    
          <mat-form-field class="campos_cod" appearance="outline">
            <mat-label class="label">Cod Orçamento</mat-label>
            <input type="text" matInput [formControl]="codPedidoControl" [matAutocomplete]="autocodPedido"  [readonly]="codPedidoControl.value!==null "  >
            <mat-autocomplete #autocodPedido="matAutocomplete" >
            
            </mat-autocomplete>
          </mat-form-field>
      
      </div>

      </div>
  <div class="cadastro">
      <div class="contents1">
          <mat-form-field class="campos_cod" appearance="outline">
              <mat-label class="label">Cod Cliente</mat-label>
              <input type="text" matInput [formControl]="codClienteControl" [readonly]="codClienteControl.value!==null "   >
          </mat-form-field>
          <mat-form-field class="campos" appearance="outline">
              <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
              <mat-label class="label">Cliente</mat-label>
              <input type="text" matInput [formControl]="clienteControl" [readonly]="isClient">
          </mat-form-field>
          <mat-form-field class="campos_cod" appearance="outline">
              <mat-label class="label">CPF/CNPJ</mat-label>
              <input type="text" matInput [formControl]="cpfControl" [readonly]="isClient">
          </mat-form-field>
          <mat-form-field class="campos_tel" appearance="outline">
              <mat-label class="label">Telefone</mat-label>
              <input type="text" matInput [formControl]="telefoneControl" [readonly]="isClient">
          </mat-form-field>
      </div>
      <div class="contents2">
          <mat-form-field class="campos" appearance="outline">
              <mat-label class="label">Fazenda</mat-label>
              <input type="text" matInput [formControl]="fazendaControl" [readonly]="isClient">
          </mat-form-field>
          <mat-form-field class="campos" appearance="outline">
              <mat-label class="label">Inscrição Estadual</mat-label>
              <input type="text" matInput [formControl]="inscricaoControl" [readonly]="isClient">
          </mat-form-field>


      </div>
      <div class="contents3">

          <mat-form-field class="campos-local" appearance="outline">
              <mat-label class="label">Local de Entrega</mat-label>
              <input type="text" matInput [formControl]="localEntregaControl" [readonly]="isClient">
          </mat-form-field>
          <mat-form-field class="example-container accent" appearance="outline">
              <mat-label>Frete</mat-label>
              <input type="text" matInput [formControl]="freteControl" [readonly]="freteControl.value!==null ">
          </mat-form-field>
          <mat-form-field class="campos" appearance="outline">
              <mat-label class="label">Endereço</mat-label>
              <input type="text" matInput [formControl]="enderecoControl" [readonly]="isClient">
          </mat-form-field>
          <mat-form-field class="fuse-mat-no-subscript w-full sm:flex-auto sm:max-w-40 mr-2" appearance="outline">
            <mat-label>Data de Entrega</mat-label>
            <input
              matInput 
              [formControl]="dataVencimentoControl"
              [matDatepicker]="pickerScheduledE"
              [readonly]="isClient"/>
            <mat-datepicker-toggle matSuffix [for]="pickerScheduledE" disabled="isClient"></mat-datepicker-toggle>
            <mat-datepicker #pickerScheduledE></mat-datepicker>
          </mat-form-field>
          

      </div>
      <div class="contents34">
          <mat-form-field class="example-container cdEntrega accent" appearance="outline">
              <mat-label>Cd.Entrega</mat-label>
              <input type="text" matInput [formControl]="CdEntregaControl" [readonly]="isClient">
          </mat-form-field>
          <mat-form-field class="campo-vendedor" appearance="outline">
              <mat-label class="label">Vendedor</mat-label>
              <input type="text" matInput [formControl]="vendedorControl" [readonly]="isClient">
          </mat-form-field>
          <mat-form-field class="campo-fin" appearance="outline">
            <mat-label class="label"> Meio de Pagamento</mat-label>
            <input type="text" matInput [formControl]="pagamentoControl" [readonly]="pagamentoControl.value!==null ">
        </mat-form-field>
          <mat-form-field class="campo-fin" appearance="outline">
              <mat-label class="label">Condições de Pagamento</mat-label>
              <input type="text" matInput [formControl]="condPagamentoControl" [readonly]="isClient">
          </mat-form-field>

      </div>
      <div class="contents4">
          <mat-form-field class="camposObs" appearance="outline">
              <mat-label class="label">Observações</mat-label>
              <input type="text" matInput [formControl]="observacoescadControl" [readonly]="isClient">
          </mat-form-field>
      </div>
  </div>
  <div class="header-itens">
      <div class="title1">Itens</div>
  </div>
  <div class="Adicionar_itens" *ngIf="!isClient">
     
      
      <div class="contents1" >
        <mat-form-field class="campos" appearance="outline">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <mat-label class="label">NItem</mat-label>

          <input type="text" id="nItem" matInput [formControl]="nItemControl" [matAutocomplete]="autoNItem">
          
 
          <mat-autocomplete #autoNItem="matAutocomplete" (optionSelected)="selecionarProduto($event)">
            <mat-option *ngFor="let item of filteredItems | async" [value]="item.ItemCode">
              {{ item.ItemCode + ' - ' + item.ItemName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="campos" appearance="outline" >
          <mat-label class="label">Numero OC</mat-label>
          <input type="number" id="descricaoProduto" matInput [formControl]="numeroOCControl"  >
        
        </mat-form-field>
        <mat-form-field class="campos" appearance="outline">
          <mat-label class="label">Descrição do Produto</mat-label>
          <input type="text" id="descricaoProduto" matInput [formControl]="descricaoProdutoControl" [matAutocomplete]="autoDescricaoProduto">
          <mat-autocomplete #autoDescricaoProduto="matAutocomplete" (optionSelected)="selecionarProduto($event)">
            <mat-option *ngFor="let item of filteredItemDescriptions | async" [value]="item.ItemName">
              {{ item.ItemCode + ' - ' + item.ItemName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
              
              </div>  
              
          <div class="contents2">
               <mat-form-field class="campos" appearance="outline">
             <mat-label class="label">Desconto</mat-label>

             <input type="number" id="desconto" matInput placeholder="%"  [formControl]="descontoControl">
                </mat-form-field>

                <mat-form-field  class="campos" appearance="outline">
                  <mat-label class="label">Quantidade</mat-label>
                  <input type="number" id="quantidade" matInput placeholder="Qtd."[formControl]="quantidadeControl">
                </mat-form-field>
  
                <mat-form-field class="campos" appearance="outline">
                  <mat-label class="label">Preço Unitário</mat-label>
                  <input type="text" id="precoUnitario" matInput [formControl]="precoUnitarioControl"   (blur)="formatarPreco()" [matAutocomplete]="autoPrecoUnitario" [value]="  precoUnitarioControl.value ">
                  <mat-autocomplete #autoPrecoUnitario="matAutocomplete" >
                    <mat-option *ngFor="let produto of itemsFiltrados" [value]="produto.UnitPrice  ">
                    {{produto.UnitPrice | currency:'BRL' }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                      </div>
                      <div class="contents3">
                          <mat-form-field class="example-container accent" appearance="outline">
                              <mat-label>Aplicação</mat-label>
                              
                              <mat-select [formControl]="aplicacaoControl" >
                                <mat-option value="OnFarm">On Farm</mat-option>
                                <mat-option value="OnDemand">On Demand</mat-option>
                              </mat-select>
                             </mat-form-field>

                             <mat-form-field class="campos" appearance="outline">
                            <mat-label class="label">Cultura</mat-label>
                            <input type="text" id="cultura" matInput [formControl]="culturaControl" [matAutocomplete]="autoCultura">
                            <mat-autocomplete #autoCultura="matAutocomplete">
                              <mat-option *ngFor="let cultura of filteredCulturas" [value]="cultura.Name">
                                {{ cultura.Name }}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>


                  <mat-form-field class="cod example-container accent" appearance="outline">
                      <mat-label>Cód.Venda</mat-label>
                      <input type="text" matInput [formControl]="codControl" [matAutocomplete]="autoCodvenda">
                      
                  </mat-form-field>

                  <mat-form-field
                class="fuse-mat-no-subscript w-full sm:flex-auto sm:max-w-40 mr-2"  appearance="outline">
                <mat-label>Data de Entrega</mat-label>
                <input
                    matInput 
                    [formControl]="dataEntregaControl"
                    [matDatepicker]="pickerScheduled"/>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerScheduled"></mat-datepicker-toggle>
                <mat-datepicker #pickerScheduled></mat-datepicker>
            </mat-form-field>
          </div>
  
           <div class="contents4">
                  
                 
                  <div class="button">
                <button  type="submit" mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" (click)="salvarAlteracoes()">
                  <span class="mat-button-wrapper">
                    
                        <span class="ml-2 mr-1">Adicionar Item </span>
                    </span>
                    <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
                    </span>
                    <span class="mat-button-focus-overlay">
                    </span>
                  </button>
              </div>
      </div>
  </div>
  <div class="itens">
      <div class="button" *ngIf="!isClient">
        <button  mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" (click)="editarItem()"   >
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">        
              <svg
                id="edit-2"
                x="912"
                y="144"
                viewBox="0 0 24 24">
                <path
                    fill="none"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke-width="2"
                    stroke="currentColor"
                    d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>
            </svg>
                </mat-icon>
                <span class="ml-2 mr-1">Editar</span>
            </span>
            <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
            </span>
            <span class="mat-button-focus-overlay">
            </span>
          </button>
      </div>
      
  <div class="table-vendas" appearance="">
    <table>
      <thead>
        <tr>
          <th>N Item</th>
          <th>Cod Item</th>
          <th>Descricao Do Produto</th>
          <th>Quantidade</th>
  
          <th>Aplicação</th>
          <th>Cultura</th>
          <th>Preço Unit.</th>
          <th>Desconto</th>
          <th>Preço c/Desconto</th>
          <th>Total</th>
          <th>Cod Venda</th>
          <th>Data de Entrega</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dadosTabela; let i = index" (click)="selecionarLinha(item)"  [class.selected]="linhasSelecionadas === item" >
          <td>{{ item.nindice }}</td>
          <td>{{ item.nItem }}</td>
          <td>{{ item.descricaoProduto }}</td>
          <td>{{ item.quantidade  }}</td>
        
          <td>{{ item.aplicacao }}</td>
          <td>{{item.cultura}}</td>
          <td>{{ item.precoUnitario }}</td>
          <td>{{ item.desconto| number: '1.0-0' }}%</td>
          <td>{{ item.precoDesconto }}</td>
          <td>{{ item.total }}</td>
          <td>{{ item.cod_venda}}</td>
          <td>{{item.data | date:'dd/MM/yyyy'  }}</td>
          <td>
            <button mat-icon-button color="warn" (click)="removerItem(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>

          <tr *ngIf="dadosTabela.length === 0">
            <td colspan="2">Nenhum dado disponível</td>
      </tbody>

    </table>
  
    <div class="button">
      <button *ngIf="isClient" mat-flat-button="" 
      class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-'text-green-200 bg-green-350 dark:text-green-50 dark:bg-green-500' ng-star-inserted" 
      ng-reflect-color="primary"  (click)="enviarPedido()">
        <span class="mat-button-wrapper">
      
            
            <span class="ml-2 mr-1 text-white">
               Aprovar</span>
        </span>
        <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
              
        </span>
        <span class="mat-button-focus-overlay">
        </span>
      </button>

      <button  *ngIf="isClient" mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" (click)="modificarOrcamento(selecionado)"   >
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">        
            <svg
              id="edit-2"
              x="912"
              y="144"
              viewBox="0 0 24 24">
              <path
                  fill="none"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  stroke-width="2"
                  stroke="currentColor"
                  d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>
          </svg>
              </mat-icon>
              <span class="ml-2 mr-1">Modificar</span>
          </span>
          <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
          </span>
          <span class="mat-button-focus-overlay">
          </span>
        </button>
      <button mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" style="background-color: #6c757d; color: white;" (click)="cancelarOrcamento(codPedidoControl.value)"   [class.selected]="orcamentoSelecionado === codPedidoControl.value"  [disabled]="loading">
          <span class="mat-button-wrapper">
              <span class="ml-2 mr-1">Cancelar Orçamento</span>
          </span>
          <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
          </span>
          <span class="mat-button-focus-overlay">
          </span>
      </button>
      <button *ngIf="!isClient" mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" (click)="editarOrcamento(codPedidoControl.value)" [class.selected]="orcamentoSelecionado === codPedidoControl.value"  [disabled]="loading" >
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">        
            <svg
      id="send"
      x="624"
      y="384"
      viewBox="0 0 24 24">
      <path
          fill="none"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="2"
          stroke="currentColor"
          d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z"/>
  </svg>
              </mat-icon>
              <span class="ml-2 mr-1">Salvar Alterações</span>
          </span>
          <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
          </span>
          <span class="mat-button-focus-overlay">
          </span>
        </button>

    </div>
    
  </div>