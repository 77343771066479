import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Shortcut } from 'app/layout/common/shortcuts/shortcuts.types';

@Injectable({
  providedIn: 'root',
})
export class ShortcutsService {
  private _shortcuts: ReplaySubject<Shortcut[]> = new ReplaySubject<Shortcut[]>(
    1
  );

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

 
  get shortcuts$(): Observable<Shortcut[]> {
    return this._shortcuts.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getAll(): Observable<Shortcut[]> {
    return this._httpClient.get<Shortcut[]>('api/common/shortcuts').pipe(
      tap(shortcuts => {
        this._shortcuts.next(shortcuts);
      })
    );
  }

  /**
   * Create a shortcut
   *
   * @param shortcut
   */
  create(shortcut: Shortcut): Observable<Shortcut> {
    return this.shortcuts$.pipe(
      take(1),
      switchMap(shortcuts =>
        this._httpClient
          .post<Shortcut>('api/common/shortcuts', { shortcut })
          .pipe(
            map(newShortcut => {

              this._shortcuts.next([...shortcuts, newShortcut]);

              return newShortcut;
            })
          )
      )
    );
  }

  /**
   * Update the shortcut
   *
   * @param id
   * @param shortcut
   */
  update(id: string, shortcut: Shortcut): Observable<Shortcut> {
    return this.shortcuts$.pipe(
      take(1),
      switchMap(shortcuts =>
        this._httpClient
          .patch<Shortcut>('api/common/shortcuts', {
            id,
            shortcut,
          })
          .pipe(
            map((updatedShortcut: Shortcut) => {
             
              const index = shortcuts.findIndex(item => item.id === id);

              
              shortcuts[index] = updatedShortcut;

            
              this._shortcuts.next(shortcuts);

              return updatedShortcut;
            })
          )
      )
    );
  }

  /**
   * Delete the shortcut
   *
   * @param id
   */
  delete(id: string): Observable<boolean> {
    return this.shortcuts$.pipe(
      take(1),
      switchMap(shortcuts =>
        this._httpClient
          .delete<boolean>('api/common/shortcuts', { params: { id } })
          .pipe(
            map((isDeleted: boolean) => {
          
              const index = shortcuts.findIndex(item => item.id === id);

           
              shortcuts.splice(index, 1);

              this._shortcuts.next(shortcuts);

    
              return isDeleted;
            })
          )
      )
    );
  }
}
