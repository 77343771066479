import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, take, tap } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/services/auth/user.service';
import { FuseConfigService } from '@fuse/services/config';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private host: string;
  private remember: boolean;

  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _fuseConfigService: FuseConfigService
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.authHost;
    });
  }

  set accessToken(token: string) {
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accessToken');
    if (this.remember) {
      localStorage.setItem('accessToken', token);
    } else {
      sessionStorage.setItem('accessToken', token);
    }
  }

  get accessToken(): string {
    if (sessionStorage.getItem('accessToken')) {
      return sessionStorage.getItem('accessToken');
    } else {
      return localStorage.getItem('accessToken');
    }
  }

  set refreshToken(token: string) {
    localStorage.removeItem('refreshToken');
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  set userId(id: string) {
    localStorage.removeItem('userId');
    sessionStorage.removeItem('userId');
    if (this.remember) {
      localStorage.setItem('userId', id);
    } else {
      sessionStorage.setItem('userId', id);
    }
  }

  get userId(): string {
    if (sessionStorage.getItem('userId')) {
      return sessionStorage.getItem('userId');
    } else {
      return localStorage.getItem('userId');
    }
  }

  forgotPassword(uid: string): Observable<any> {
    return this._httpClient.post(this.host + '/auth/password', { uid: uid });
  }

  resetPassword(password: string, token: string): Observable<any> {
    return this._httpClient.post(this.host + '/auth/password/' + token, {
      password: password,
    });
  }

  signIn(
    credentials: { uid: string; password: string },
    remember: boolean
  ): Observable<any> {

    if (this._authenticated) {
      return throwError('logged');
    }
    this.remember = remember;

    return this._httpClient.post(this.host + '/auth/login', credentials).pipe(
      switchMap((response: any) => {

        this.accessToken = response.token;
        this.userId = response.user.id;
        this.refreshToken = response.refreshtoken;

 
        this._authenticated = true;

     
        this._userService.user = response.user;
        this._userService.userData = response.user;
     
        return of(response);
      })
    );
  }

  signInUsingToken(): Observable<any> {
   
    return this._httpClient
      .post(this.host + '/auth/refreshtoken', {
        refreshtoken: this.refreshToken,
      })
      .pipe(
        catchError(() =>
         
          of(false)
        ),
        switchMap((response: any) => {
       
          this.accessToken = response.token;

        
          this.refreshToken = response.refreshtoken;

         
          this._authenticated = true;

          this._userService.user = response.user;

      
          return of(true);
        })
      );
  }

  signOut(): Observable<any> {
  
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userId');


    this._authenticated = false;

    return of(true);
  }

  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  check(): Observable<boolean> {
    
    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    return this.signInUsingToken();
  }

  verifyTokenAuthenticity(token) {
    return this._httpClient
      .get<any>(this.host + '/auth/password/' + token)
      .pipe(tap(result => result));
  }
}
