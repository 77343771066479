
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-fazenda-list',
  templateUrl: './fazenda-list.component.html',
  styleUrls: ['./fazenda-list.component.scss']
})
export class FazendaListComponent implements OnInit {
  fazendas: any[] = [];
  fazendasFiltradas: any[] = [];
  filtroForm: FormGroup;
  loading: boolean = false; 
  error: boolean = false; 
  apiUrl: string = ''; 


  constructor(private http: HttpClient) {
    this.filtroForm = new FormGroup({
      code: new FormControl(''),
      u_regional: new FormControl(''),
      u_grandesContas: new FormControl(''),
      u_colaborador: new FormControl('')
    });
    this.apiUrl = environment.apiSap + '/api/fazendas'; 
  }

  ngOnInit(): void {
    this.carregarFazendas();
    this.onChanges();
  }

  carregarFazendas(): void {
    this.loading = true;
    this.error = false;
    this.http.get<any>(this.apiUrl).subscribe(
      response => {
        this.fazendas = response.fazendas;
        this.fazendasFiltradas = [...this.fazendas];
        this.loading = false;
      },
      error => {
        console.error('Erro ao obter dados das fazendas:', error);
        this.error = true;
        this.loading = false;
      }
    );
  }


  onChanges(): void {
    this.filtroForm.valueChanges.subscribe(val => {
      this.filtrarFazendas();
    });
  }
  filtrarFazendas(): void {
    const { code, u_regional, u_grandesContas, u_colaborador } = this.filtroForm.value;
    this.fazendasFiltradas = this.fazendas.filter(fazenda => {
      return (code ? fazenda.Code.toLowerCase().includes(code.toLowerCase()) : true) &&
             (u_regional ? fazenda.U_REGIONAL.toLowerCase().includes(u_regional.toLowerCase()) : true) &&
             (u_grandesContas ? fazenda.U_GrandesContas === u_grandesContas : true) &&
             (u_colaborador ? (fazenda.U_Colaborador || '').toLowerCase().includes(u_colaborador.toLowerCase()) : true);
    });
  }
  
  limparFiltros(): void {
    this.filtroForm.reset();
    this.fazendasFiltradas = [...this.fazendas];
  }
}