import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl,FormGroup } from '@angular/forms';
import { Type } from '@angular/core';
import { Item } from '../../Types';
import { BehaviorSubject, Observable, catchError, finalize, map, startWith } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { OpenSalesService } from '../open-sales.service';
import { UserService,User } from 'app/core/services/auth/user.service';
import { Console } from 'console';
import { ClientRequest } from 'http';
import { ModificarService } from 'app/modules/admin/dashboard/orders-open/modificar/modificar.service';
import { DialogPedidoComponent } from 'app/modules/admin/dashboard/orders-open/dialog-pedido/dialog-pedido.component';
import { ModificarComponent } from 'app/modules/admin/dashboard/orders-open/modificar/modificar.component';
import { DialogCancelarComponent } from './dialog-cancelar/dialog-cancelar.component';
import { DialogEditarComponent } from './dialog-editar/dialog-editar.component';
import { DialogErrorCancelarComponent } from 'app/modules/admin/dashboard/orders-open/dialog-error-cancelar/dialog-error-cancelar.component';
import { DialogErrorEditarComponent } from 'app/modules/admin/dashboard/orders-open/dialog-error-editar/dialog-error-editar.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-open-sales-dialog',
  templateUrl: './open-sales-dialog.component.html',
  styleUrls: ['./open-sales-dialog.component.scss']
})
export class OpenSalesDialogComponent implements OnInit {

  CodVenda = [
    { id: 212, Tipo: 'VFU'},
    { id: 222, Tipo: 'VDI'},
    { id: 210, Tipo: 'BNF'},

  ];
 
  tiposFiltrados: Observable<{ id: number, Tipo: string }[]>;
  apiUrl: string = '';

  codClienteControl=new FormControl();
  cpfControl = new FormControl();
  telefoneControl = new FormControl();
  fazendaControl = new FormControl();
  enderecoControl=new FormControl();
  BPLIdSelecionado:string;
  codPedidoControl=new FormControl();
  codControl=new FormControl();
  clienteControl=new FormControl();
  localEntregaControl=new FormControl();
  freteControl=new FormControl();
  observacoescadControl=new FormControl();
  textoControl=new FormControl();
  pagamentoControl=new FormControl();
  inscricaoControl=new FormControl();
  fretes: any[] = [];
  fretesFiltrados: any[] = [];
  itemControl = new FormControl();
  
  produtoControl=new FormControl();
  
  vendedorControl=new FormControl();
  
  freteCodigo:any = null;
  CdEntregaControl=new FormControl();
  cliente:any = null;
  items: any[] = []; 
  clientes:any[]=[];
  clientesFiltrados:any[]=[];
  observacoesControl=new FormControl();
  numeroOCControl=new FormControl();
  quantidadeControl=new FormControl();
  descontoControl=new FormControl();
  aplicacaoControl = new FormControl();
  nItemControl = new FormControl();
  descricaoProdutoControl = new FormControl();
  precoUnitarioControl = new FormControl();
  dataEntregaControl=new FormControl();
  dataVencimentoControl=new FormControl();
  culturaControl=new FormControl();
  inscricaoMunicipalControl=new FormControl();
  condPagamentoControl=new FormControl();
  loading: boolean = false;
  filtroForm: FormGroup;
  precoForm:FormGroup;
  vendedoresFiltrados:any[]=[];
  vendedores:any[]=[];
  linhasSelecionadas:  any;
 dadosTabela:any[]=[];
 cardcodeSelecionado: number | null = null;
 SlpCodeSelecionado: number | null = null;
 vendedor:any=null;
 filteredVendedores:any;
 filteredCulturas:any;
 filteredItemDescriptions:any;
 filteredClientes: any;
 private indiceSequencial: number=0 ;
 formapagamento:any=null;
 AtCard=new FormControl();
PayMethCodSelecionado:number | null = null;
filteredformaspagamento:any;
formaspagamentoFiltradas:any[]=[];
formaspagamento:any[]=[];
editarAtivo: boolean = false;
orcamentoSelecionado:  any;
filteredItems: any;
itemsFiltrados: any[] = []; 
orcamentosFiltrados:any[]=[];
orcamentos:any[]=[];
cdsEntrega:any[]=[];
cdsEntregaFiltrados:any[]=[];
cdEntrega:any = null;
GroupNumSelecionado: string;
condicoesPagamento: any[] = [];
condicoesPagamentoFiltradas: any[] = [];
filteredCondicaoPagamento:any;
condicao:any = null;
culturasFiltradas:any[]=[];
culturas:any[]=[];
currentUser: User;
 isClient = false;
 isComercial = false;
 isAdmin = false;
 private ultimoAtCard: string = '';

  constructor(   private currencyPipe: CurrencyPipe, public dialogRef: MatDialogRef<OpenSalesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private openService:OpenSalesService,
     private userAuth: UserService,
     private modificarService:ModificarService,
     private changeDetectorRef: ChangeDetectorRef, 
     private http:HttpClient,
     private fb: FormBuilder,public dialog: MatDialog) {
      let items: Item[] = [];
      this.apiUrl = environment.apiSap ;
     }

  ngOnInit(): void {
      this.carregarCondicaoPagamento();
      this.carregarVendedores();
      this.carregarClientes();
      this.carregarMetodoPagamento();
      this.carregarCdsEntrega();
      this.carregarOrcamentos();
      this.carregarItems();
      this.carregarUser();
      this.carregarMeioCultura();
      this.carregarFretes();
      this.clienteControl = new FormControl();
      this.fazendaControl = new FormControl();
      this.telefoneControl = new FormControl();
      this.localEntregaControl = new FormControl();

      this.precoForm = this.fb.group({
        precoUnitario: ['']
      });

    this.filtroForm = new FormGroup({
      CardCode: new FormControl(''),
      Nome_do_PN: new FormControl(''),
      CNPJ_CPF: new FormControl(''),
      Telefone: new FormControl(''),
      U_CADF_Fazenda: new FormControl(''),
    });
    this.tiposFiltrados = this.codControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTipos(value))
    );

    
    
      this.filteredClientes = this.clienteControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Nome_do_PN),
        map(nome => {
          const cardCode = this.data.orcamento.CardCode || ''; 
          return this._filterClientes(nome, cardCode);
        })
      );
      this.precoUnitarioControl.valueChanges.subscribe(value => {
        if (value !== null && value !== undefined) {
          const formattedValue = this.currencyPipe.transform(value, 'BRL', 'symbol', '1.0-2');
          this.precoUnitarioControl.setValue(formattedValue, { emitEvent: false });
        }
      });

  
      this.clienteControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Nome_do_PN),
        map(nome => {
          const cardCode = this.data.orcamento.CardCode || ''; 
          return this._filterClientes(nome, cardCode);
        })
      ).subscribe((clientesFiltrados: any[]) => {
        this.clientesFiltrados = clientesFiltrados;
      });

      this.filteredformaspagamento = this.pagamentoControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Descript),
        map(nome => {
          const PayMethodCode = this.data.orcamento.PaymentMethod || '';
          return this._filterPagamentos(nome, PayMethodCode);
        })
      );
  
   
    this.filteredVendedores = this.vendedorControl.valueChanges.pipe(
      startWith(''), 
      map(value => typeof value === 'string' ? value : value.SlpName),
      map(nome => {
        const salesPersonCode = this.data.orcamento.SalesPersonCode || ''; 
        return this._filterVendedores(nome, salesPersonCode);
      })
    );
    this.filteredItems = this.nItemControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : (value ? value.ItemCode : '')),
      map(name => name ? this._filterItems(name) : this.items.slice())
    );
    
    this.filteredItemDescriptions = this.descricaoProdutoControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : (value ? value.ItemName : '')),
      map(description => description ? this._filterItemDescriptions(description) : this.items.slice())
    );

    this.vendedorControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.SlpName),
      map(nome => {

  
        const salesPersonCode = this.data.orcamento.SalesPersonCode || ''; 
        return this._filterVendedores(nome, salesPersonCode);
      })
    ).subscribe((vendedoresFiltrados: any[]) => {
      this.filteredVendedores = vendedoresFiltrados;
    });


    if (this.data) {

      this.codClienteControl.setValue(this.data.orcamento.CardCode);
      this.clienteControl.setValue(this.data.orcamento.CardName);
      this.cpfControl.setValue(this.data.orcamento.TaxExtension.CNPJ);
      this.observacoescadControl.setValue(this.data.orcamento.Comments)
      this.dataVencimentoControl.setValue(this.data.orcamento.DocDueDate);
     this.condPagamentoControl.setValue(this.data.orcamento.PaymentGroupCode);
      this.pagamentoControl.setValue(this.data.orcamento.U_SOLU_MEIO_PAG);
      this.AtCard.setValue(this.data.orcamento.NumAtCard);
      this.codPedidoControl.setValue(this.data.orcamento.DocNum);
      this.codControl.setValue(this.data.orcamento.DocumentLines.Usage);
      this.CdEntregaControl.setValue(this.data.orcamento.BPLName);
      this.enderecoControl.setValue(this.data.orcamento.Address);
      this.inscricaoControl.setValue(this.data.orcamento.TaxExtension.inscricao_estadual);
      this.freteControl.setValue(this.data.orcamento.TaxExtension.Incoterms);

      if (this.data.orcamento.DocumentLines && Array.isArray(this.data.orcamento.DocumentLines)) {
        this.data.orcamento.DocumentLines.forEach((line: any) => {
          this.adicionarItemFromAPI(line);
        });
      } else {
  
      }
      const salesPersonCode = this.data.orcamento.SalesPersonCode;
    const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);
    if (vendedor) {
      this.vendedorControl.setValue(vendedor ? vendedor.SlpName : '');
      
    } 
    const cardCode = this.data.orcamento.CardCode;
    const cliente = this.clientes.find(c=>c.CardCode === cardCode);
    if (cliente) {
      this.clienteControl.setValue(cliente ? cliente.Nome_do_PN: '');
      this.telefoneControl.setValue(cliente? cliente.Telefone: '');
      this.fazendaControl.setValue(cliente? cliente.U_CADF_Fazenda: '');
      this.localEntregaControl.setValue(cliente? cliente.U_CADF_Fazenda: '');

        }

        const cdsEntregaName = this.data.orcamento.BPLName;
        const cdEntrega = this.cdsEntrega.find(c => c.BPLName === cdsEntregaName);
        if (cdEntrega) {
          this.BPLIdSelecionado = cdEntrega.BPLId;
          
        } 

  
   
        const codigoVenda=this.data.orcamento.DocumentLines.Usage;
        const tipoCodVenda = this.getTipoByCodVenda(codigoVenda);
        if(tipoCodVenda){
            this.codControl.setValue(tipoCodVenda);

        }
        
        const codigoFrete = this.data.orcamento.TaxExtension.Incoterms;
        const freteSelecionado = this.fretes.find(frete => frete.CODIGO === codigoFrete);
        
        if (freteSelecionado) {
    
          this.freteControl.setValue(freteSelecionado ? freteSelecionado.DESCRICAO : '');
        }
        
      }
      this.filteredCulturas = this.culturaControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : (value ? value.Name : '')),
        map(name => name ? this._filterCulturas(name) : this.culturas.slice())
      );
      
      this.culturaControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : (value ? value.Name : '')),
        map(name => name ? this._filterCulturas(name) : this.culturas.slice())
      ).subscribe((culturasFiltradas: any[]) => {
        this.filteredCulturas = culturasFiltradas;
      });

    }

  formatarPreco(): void {
    let valor = this.precoUnitarioControl.value;
    if (valor) {
      const valorNumerico = parseFloat(valor.replace(/[^\d,.-]/g, '').replace(',', '.'));
      const precoUnitarioFormatado = this.currencyPipe.transform(valorNumerico, 'BRL', 'symbol', '1.2-2');
      this.precoUnitarioControl.setValue(precoUnitarioFormatado, { emitEvent: false });
    }
    }

    private _filterTipos(value: string): { id: number, Tipo: string }[] {
      const filterValue = value.toLowerCase();
      return this.CodVenda.filter(option => option.Tipo.toLowerCase().includes(filterValue));
    }
    private _filter(value: string): any[] {
      const filterValue = this._formatPreco(value);
      return this.items.filter(option => this.currencyPipe.transform(option.Price, 'BRL', 'symbol', '1.2-2').includes(filterValue));
    }
  
    private _formatPreco(valor: string): string {
      const valorNumerico = parseFloat(valor.replace(/[^\d,.-]/g, '').replace(',', '.'));
      return this.currencyPipe.transform(valorNumerico, 'BRL', 'symbol', '1.2-2') ?? '';
    }
  
 async carregarUser(): Promise<void> {
      const user = await this.userAuth.get().toPromise();
        this.currentUser = user;
        this.isClient = user.roles.some(r => r.scope === 'cliente');
        this.isComercial = user.roles.some(r => r.scope === 'comercial');
        this.isAdmin = user.roles.some(r => r.scope ==='admin');
        if(this.isClient){
          this.isClient = true;
        }
    }

    private _filterClientes(nome: string, cardCode: string): any[] {
      const filterValue = nome.toLowerCase();
      return this.clientes.filter(cliente =>
        (cardCode ? cliente.CardCode.toLowerCase().includes(cardCode.toLowerCase()) : true) &&
        cliente.Nome_do_PN.toLowerCase().includes(filterValue)
      );
    }
  private _filterVendedores(nome: string, salesPersonCode: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.vendedores.filter(vendedor => 
      (salesPersonCode ? vendedor.SlpCode.toString().toLowerCase().includes(salesPersonCode.toString().toLowerCase()) : true) &&
      vendedor.SlpName.toLowerCase().includes(filterValue)
    );
  }

  carregarVendedores(): void {
    this.loading = true;
    this.openService.obterVendedores().subscribe(
      vendedores => {
        this.vendedores = vendedores;
        this.vendedoresFiltrados = [...this.vendedores];
        this.loading = false;
   
        const salesPersonCode = this.data.orcamento.SalesPersonCode;
        this.selecionarVendedorPorCodigo(salesPersonCode);
      },
      error => {

        this.loading = false;
      }
    );
  }
  filtrarVendedores():void{ 
    const {SlpCode,SlpName}=this.filtroForm.value;  
      this.vendedoresFiltrados=this.vendedores.filter(vendedor=>{
      return(SlpCode ? vendedor.SlpCode.toString().toLowerCase().includes(SlpCode.toLowerCase()):true)&&
            (SlpName ?(vendedor.SlpName || ''). toLowerCase().includes(SlpName.toLowerCase()):true);
      })
  }
  
  selecionarVendedor(event: any) {
    const selectedSlpCode = event.option.value;
    this.data.orcamento.SalesPersonCode = selectedSlpCode;  

    const vendedor = this.vendedores.find(v => v.SlpCode.toString() === selectedSlpCode);
    if (vendedor) {
      this.vendedorControl.setValue(vendedor.SlpName);
      this.SlpCodeSelecionado = vendedor.SlpCode;
    } 
  }



  selecionarVendedorPorCodigo(salesPersonCode: string) {
    const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);  
    if (vendedor) {
      this.vendedorControl.setValue(vendedor.SlpName);  
      this.SlpCodeSelecionado = vendedor.SlpCode;       
      
    } 
  }

private _filterItemDescriptions(name: string): any[] {
  const filterValue = name.toLowerCase();
  return this.items.filter(item => 
    (item.ItemCode && item.ItemCode.toLowerCase().includes(filterValue)) || 
    ((item.ItemName || '').toLowerCase().includes(filterValue))
  );
}

async carregarItems(): Promise<void> {
  try {
    this.loading = true;
    const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaItems').toPromise();
    this.items = response.listaItems;
    this.itemsFiltrados = [...this.items];
    this.loading = false;

  } catch (error) {
  
    this.loading = false;
  }
}

private _filterItems(name: string): any[] {
  const filterValue = name.toLowerCase();
  return this.items.filter(item => 
    (item.ItemCode && item.ItemCode.toLowerCase().includes(filterValue)) || 
    ((item.ItemName || '').toLowerCase().includes(filterValue))
  );
}

filtrarItens(): void {
  const { ItemCode, ItemName } = this.filtroForm.value;
  this.itemsFiltrados = this.items.filter(item => {
    return (ItemCode ? item.ItemCode.toLowerCase().includes(ItemCode.toLowerCase()) : true) &&
      (ItemName ? (item.ItemName || '').toLowerCase().includes(ItemName.toLowerCase()) : true);
  });
}

displayItem(item: any): string {
  return item ? (item.ItemCode && item.ItemName ? `${item.ItemCode} - ${item.ItemName}` : item.ItemCode || item.ItemName || '') : '';
}


carregarFretes(): void {
  this.loading = true;
  this.openService.carregarFrete().subscribe(
    fretes => {
      this.fretes = fretes;
      this.fretesFiltrados = [...this.fretes];
      this.loading = false;
      const freteCod = this.data.orcamento.TaxExtension.Incoterms;
      this.selecionarFrete(freteCod);
    },
    error => {
      console.error('Erro ao carregar lista de fretes: ', error);
      this.loading = false;
    }
  );
}


filtrarFretes(): void {
  const {CODIGO, DESCRICAO } = this.filtroForm.value;

  this.fretesFiltrados = this.fretes.filter(frete => {
    return (
      (CODIGO ? frete.CODIGO.toString().toLowerCase().includes(CODIGO.toLowerCase()) : true) &&
      (DESCRICAO ? (frete.DESCRICAO || '').toLowerCase().includes(DESCRICAO.toLowerCase()) : true)
    );
  });
}
selecionarFrete(frete:any): void {
  const codigoFreteSelecionado = this.data.orcamento.TaxExtension.Incoterms; 
  const freteSelecionado = this.fretes.find(frete => frete.CODIGO === codigoFreteSelecionado); 

  if (freteSelecionado) {
    this.freteControl.setValue(freteSelecionado.DESCRICAO); 
    this.freteCodigo = freteSelecionado.CODIGO; 

  }
}

selecionarProduto(event: any) {
  const itemSelecionado = this.itemsFiltrados.find(item => 
    item.ItemCode === event.option.value || item.ItemName === event.option.value
  );
  if (itemSelecionado) {
    this.nItemControl.setValue(itemSelecionado.ItemCode, { emitEvent: false });
    this.descricaoProdutoControl.setValue(itemSelecionado.ItemName, { emitEvent: false });
    this.precoUnitarioControl.setValue(this.currencyPipe.transform(itemSelecionado.Price, 'BRL', 'symbol', '1.2-2'), { emitEvent: false });
  }
}


  async carregarClientes():Promise<void> {
    this.loading = true;
    try {
      const clientes = await this.openService.obterClientes().toPromise();
      this.clientes = clientes;
      this.clientesFiltrados = [...this.clientes];
      this.loading = false;
     
      const cardCode = this.data.orcamento.CardCode;
      
  
      if (cardCode) {
        this.selecionarClientePorCardCode(cardCode);
      } 
    } catch (error) {
     
      this.loading = false;
    }
  }
  
    filtrarClientes(): void {
      const { U_CADF_Fazenda, Nome_do_PN, CNPJ_CPF, Telefone, CardCode} = this.filtroForm.value;
      this.clientesFiltrados = this.clientes.filter(cliente => {
        return (U_CADF_Fazenda ? cliente.U_CADF_Fazenda.toLowerCase().includes(U_CADF_Fazenda.toLowerCase()) : true) &&
          (Nome_do_PN ? (cliente.Nome_do_PN || '').toLowerCase().includes(Nome_do_PN.toLowerCase()) : true) &&
          (CardCode ? (cliente.CardCode|| '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
          (CNPJ_CPF ? (cliente.CNPJ_CPF || '').toLowerCase().includes(CNPJ_CPF.toLowerCase()) : true) &&
          (Telefone ? (cliente.Telefone || '').toLowerCase().includes(Telefone.toLowerCase()) : true);
      });
     
    }
  
  
    selecionarCliente(event: any) {
      const cardcodeSelecionado = event.option.value;
      this.data.orcamento.CardCode = cardcodeSelecionado;

      const cliente = this.clientes.find(c => c.CardCode === cardcodeSelecionado);
   
      if (cliente) {
     
        this.clienteControl.setValue(cliente.Nome_do_PN);
        this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
        this.telefoneControl.setValue(cliente.Telefone);
    
        this.cardcodeSelecionado = cliente.CardCode;
    
      } 
    }
  
  
    selecionarClientePorCardCode(cardCode: string) {
    
    
      const cleanedCardCode = cardCode.trim().toLowerCase();
   
      const cliente = this.clientes.find(c => {
        const clienteCardCode = c.CardCode.trim().toLowerCase();
    
        return clienteCardCode === cleanedCardCode;
      });
    
      if (cliente) {
    
        this.filtroForm.patchValue({
          CardCode: cliente.CardCode,
          Nome_do_PN: cliente.Nome_do_PN,
          CNPJ_CPF: cliente.CNPJ_CPF,
          Telefone: cliente.Telefone,
          U_CADF_Fazenda: cliente.U_CADF_Fazenda,
        });
        this.clienteControl.setValue(cliente.Nome_do_PN)
        this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
        this.localEntregaControl.setValue(cliente.U_CADF_Fazenda);
        this.telefoneControl.setValue(cliente.Telefone);
        this.cpfControl.setValue(cliente.CNPJ_CPF)
        this.cardcodeSelecionado = cliente.CardCode;
  
      } 
    }

   
    
    
    carregarCondicaoPagamento(): void {
      this.loading = true;
      this.openService.condicaoPagamento().subscribe(
        condicoespagamento => {
          this.condicoesPagamento = condicoespagamento;
          this.condicoesPagamentoFiltradas = [...this.condicoesPagamento];
          this.loading = false;
          const PayCondCode=this.data.orcamento.PaymentGroupCode;
            this.selecionarCondicaoPagamentoPorCodigo(PayCondCode);
     
        
        },
        error => {
          this.loading = false;
        }
      );
    }
    filtrarCondicoesPagamento(): void {
      const {GroupNum, PymntGroup } = this.filtroForm.value;
      this.condicoesPagamentoFiltradas = this.condicoesPagamento.filter(condicaopagamento => {
        return (GroupNum ? condicaopagamento.PayMethCod.toString().toLowerCase().includes(GroupNum.toLowerCase()) : true) &&
               (PymntGroup ? (condicaopagamento.PymntGroup || '').toLowerCase().includes(PymntGroup.toLowerCase()) : true);
      });
    }
    
    selecionarCondicaoPagamento(event: any) {
      const PayMethodCodeSelecionado = event.option.value;
    
      const condicaopagamento = this.condicoesPagamento.find(c => c.GroupNum === PayMethodCodeSelecionado);
    
      if (condicaopagamento) {
     
        this.condPagamentoControl.setValue(condicaopagamento.PymntGroup);

        this.PayMethCodSelecionado = condicaopagamento.GroupNum;
      }
    }
    selecionarCondicaoPagamentoPorCodigo(PayMethCod: any) {

      const condicaopagamento = this.condicoesPagamento.find(c => {
 
        return c.GroupNum === PayMethCod;
      });
    
      if (condicaopagamento) {
     
        this.condPagamentoControl.setValue(condicaopagamento.PymntGroup);
        this.PayMethCodSelecionado = condicaopagamento.GroupNum;
        
      } 
    }
    

    carregarMeioCultura(): void {
      this.loading = true;
      this.openService.carregarCultura().subscribe({
        next: (culturas: any[]) => {
          this.culturas = culturas;
          this.culturasFiltradas = [...this.culturas];
          this.loading = false;
         
        },
        error: (error) => {
       
          this.loading = false;
        }
      });
    }
    
    filtrarCultura(): void {
      const { Name, Code } = this.filtroForm.value;
      this.culturasFiltradas = this.culturas.filter(cultura => {
        return (Code ? cultura.Code.toLowerCase().includes(Code.toLowerCase()) : true) &&
               (Name ? (cultura.Name || '').toLowerCase().includes(Name.toLowerCase()) : true);
      });
    
    }
    
    private _filterCulturas(name: string): any[] {
      const filterValue = name ? name.toLowerCase() : '';
      return this.culturas.filter(cultura => 
        cultura.Name && cultura.Name.toLowerCase().includes(filterValue)
      );
    }
    removerItem(index: number): void {
      this.dadosTabela.splice(index, 1);
      this.limparCampos()
    }
    
    adicionarItemFromAPI(data: any): void {
    
      const nindice=this.indiceSequencial++;
      const nItem = data.ItemCode;
      const descricaoProduto = data.ItemDescription;
      const quantidade = data.Quantity ;
      const desconto = data.DiscountPercent;
      const aplicacao = data.U_SOLU_TP_Aplicacao;
      const cod_venda=data.Usage;
      const cultura = data.U_SOLU_Cultura;
      const precoUnitario =`${data.Currency} ${data.UnitPrice}` ;
      const precoDesconto=`${data.Currency} ${data.Price}`;
      const total=`${data.Currency} ${data.LineTotal}`
      const dataEntrega = data.ShipDate;
      const observacoes = data.observacoes;
      const totalvalue= parseFloat(total.replace(/[^\d.-]/g, ''));
      const totalformat=this.currencyPipe.transform(totalvalue, 'BRL', 'symbol', '1.2-2') ;
      const valorNumerico = parseFloat(precoUnitario.replace(/[^\d.-]/g, ''));
      const value=parseFloat(precoDesconto.replace(/[^\d.-]/g, ''));
      const valueFormat= this.currencyPipe.transform(value, 'BRL', 'symbol', '1.2-2') ;
      const precoUnitarioFormatado = this.currencyPipe.transform(valorNumerico, 'BRL', 'symbol', '1.2-2') ;
      
      const tipoCodVenda = this.getTipoByCodVenda(cod_venda);
    
      this.codControl.setValue(tipoCodVenda)
      this.codControl.disable()
  
      const item: Item = {
        nindice:nindice,
        nItem: nItem,
        descricaoProduto: descricaoProduto,
        quantidade: quantidade,
        desconto: desconto,
        aplicacao: aplicacao,
        cultura: cultura,
        precoUnitario:precoUnitarioFormatado,
        precoDesconto:valueFormat,
        total:totalformat,
        data: dataEntrega,
        observacoes: observacoes,
        cod_venda:cod_venda,
        numeroOC: 0
      };
      this.dadosTabela.push(item);
 
    } 

  getTipoByCodVenda(cod_venda: number): string | undefined {
    const result = this.CodVenda.find(item => item.id === cod_venda);
    return result ? result.Tipo : undefined;
  }
  
  private _filterPagamentos(nome: string, PayMethodCode: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.formaspagamento.filter(option =>
      option.Descript.toLowerCase().includes(filterValue) ||
      option.PayMethCod.toString().includes(filterValue) ||
      option.PayMethCod.toString() === PayMethodCode
    );
  }
 
carregarMetodoPagamento(): void {
  this.loading = true;
  this.openService.obterMetodoPagamento().subscribe(
    formaspagamento => {
      this.formaspagamento = formaspagamento;
      this.formaspagamentoFiltradas = [...this.formaspagamento];
      this.loading = false;
   
      
    },
    error => {
    
      this.loading = false;
    }
  );
}


filtrarMetodoPagamento(): void {
  const { FPAGAMENTO } = this.filtroForm.value;
  this.formaspagamentoFiltradas = this.formaspagamento.filter(formapagamento => {
    return (FPAGAMENTO ? formapagamento.FPAGAMENTO.toString().toLowerCase().includes(FPAGAMENTO.toLowerCase()) : true) 
          
  });
}

  cancel(): void {
    this.dialogRef.close(true);
  }

  salvarAlteracoesOcamento(): void {
    
    this.dialogRef.close(true);
  }


  carregarOrcamentos(): void {
    this.loading = true;
    this.openService.carregarOrcamentos().subscribe(
      orcamentos => {
        this.orcamentos = orcamentos;
        this.orcamentosFiltrados = [...this.orcamentos];
        this.loading = false;
      },
      error => {
      
        this.loading = false;
      }
    );
  }
  filtrarOrcamentos(): void {
    const { DocNum, DocDate, CardName, CardCode, DocStatus, CANCELED ,DocEntry} = this.filtroForm.value;
    this.orcamentosFiltrados = this.orcamentos.filter(orcamento => {
      return (DocNum ? orcamento.DocNum.toLowerCase().includes(DocNum.toLowerCase()) : true) &&
             (DocDate ? orcamento.DocDate.toLowerCase().includes(DocDate.toLowerCase()) : true) &&
             (CardName ? (orcamento.CardName || '').toLowerCase().includes(CardName.toLowerCase()) : true) &&
             (CardCode ? (orcamento.CardCode || '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
             (DocStatus ? (orcamento.DocStatus || '').toLowerCase().includes(DocStatus.toLowerCase()) : true) &&
             (CANCELED ? (orcamento.CANCELED || '').toLowerCase().includes(CANCELED.toLowerCase()) : true)&&
             (DocEntry ? (orcamento.DocEntry || '').toLowerCase().includes(DocEntry.toLowerCase()) : true) ;
    });
  }



  adicionarItem() {
  
const nidice=this.indiceSequencial++;
const nItem = this.nItemControl.value;
const descricaoProduto = this.descricaoProdutoControl.value;
const quantidade = this.quantidadeControl.value;
const numeroOC=this.numeroOCControl.value;
const desconto = this.descontoControl.value;
const cod_venda=this.codControl.value;
const aplicacao = this.aplicacaoControl.value;
const cultura=this.culturaControl.value;
const precoUnitario = this.precoUnitarioControl.value;
const data = this.dataEntregaControl.value;
const observacoes = this.observacoesControl.value;
  const valorNumerico = parseFloat(precoUnitario.replace(/[^\d.-]/g, ''));
    const precoUnitarioFormatado = this.currencyPipe.transform(valorNumerico / 100, 'BRL', 'symbol', '1.2-2') ;
    
    const idCodVenda = this.getIdByTipo(cod_venda);
    const item: Item = {
      nindice:nidice,
      nItem: nItem,
      cod_venda:idCodVenda,
      descricaoProduto: descricaoProduto,
      numeroOC:numeroOC,
      quantidade: quantidade,
      desconto: desconto,
      aplicacao: aplicacao,
      cultura:cultura,
      precoUnitario: precoUnitario,
      data:data,
      observacoes: observacoes
    };

    this.items.push(item);
    this.dadosTabela.push(item);
    this.changeDetectorRef.detectChanges();
    this.limparCampos();

  }

  limparCampos() {
    this.nItemControl.reset();
    this.descricaoProdutoControl.reset();
    this.quantidadeControl.reset();
    this.descontoControl.reset();
    this.aplicacaoControl.reset();
    this.culturaControl.reset();
    this.precoUnitarioControl.reset();
    this.dataEntregaControl.reset();
    this.observacoesControl.reset();
  }

  selecionarLinha(linha: any) {
    this.linhasSelecionadas = linha;
 
  }
  editarItem() {
    this.editarAtivo = true;

    if (this.linhasSelecionadas) {
    
        this.nItemControl.setValue(this.linhasSelecionadas.nItem);
        this.descricaoProdutoControl.setValue(this.linhasSelecionadas.descricaoProduto);
        this.quantidadeControl.setValue(this.linhasSelecionadas.quantidade);
        this.descontoControl.setValue(this.linhasSelecionadas.desconto);
        this.aplicacaoControl.setValue(this.linhasSelecionadas.aplicacao);
        this.culturaControl.setValue(this.linhasSelecionadas.cultura);
        this.precoUnitarioControl.setValue(this.linhasSelecionadas.precoUnitario);
        this.dataEntregaControl.setValue(this.linhasSelecionadas.data);
        this.observacoesControl.setValue(this.linhasSelecionadas.observacoes);
    }
}

salvarAlteracoes() {

  if (this.editarAtivo) {
    if (this.linhasSelecionadas) {
         this.linhasSelecionadas.nItem = this.nItemControl.value;
        this.linhasSelecionadas.descricaoProduto = this.descricaoProdutoControl.value;
        this.linhasSelecionadas.quantidade = this.quantidadeControl.value;
        this.linhasSelecionadas.desconto = this.descontoControl.value;
        this.linhasSelecionadas.aplicacao = this.aplicacaoControl.value;
        this.linhasSelecionadas.cultura=this.culturaControl.value;
        this.linhasSelecionadas.precoUnitario = this.precoUnitarioControl.value;
      
        this.linhasSelecionadas.data=this.dataEntregaControl.value;
        this.linhasSelecionadas.observacoes = this.observacoesControl.value;
        const idVenda = this.getIdByTipo(this.codControl.value);

        this.linhasSelecionadas.cod_venda = idVenda;
        if (this.todosItensComCodVendaDefinido()) {
          this.codControl.disable(); 
          const tipoVenda = this.getTipoByCodVenda(this.linhasSelecionadas.cod_venda);
          this.codControl.setValue(tipoVenda);
          
        }
    }
   

      this.limparCampos();
      this.editarAtivo=false;
  } else{
    this.adicionarItem();
  }

}

verificarCodVendaIgual(): boolean {
  const novoCodVenda =  this.data.orcamento.DocEntry;

 
  for (const item of this.dadosTabela) {
   
    const idVendaItem = this.getIdByTipo(item.cod_venda);

    
    if (idVendaItem !== novoCodVenda) {
      return false; 
    }
  }

  return true; 
}
todosItensComCodVendaDefinido(): boolean {

  return this.dadosTabela.every(item => item.cod_venda !== undefined && item.cod_venda !== null);
}

getIdByTipo(tipo: string): number | undefined {
  const result = this.CodVenda.find(item => item.Tipo === tipo);
  
  return result ? result.id : undefined;
}

cancelarOrcamento(selecionado: any): void {
  this.loading=true;
  const DocEntry =  this.data.orcamento.DocEntry;

  if (!DocEntry) {
   
    this.loading = false;
    return;
  }

  this.enviarCancelamento(DocEntry.toString());
 

  
}

enviarCancelamento(DocEntry: number): void {
  const dialogRef = this.dialog.open(DialogEditarComponent, {
    width: '250px',
    data: { message: 'Você tem certeza que deseja cancelar o orçamento?', showSuccessMessage: false }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result === true) {
      this.loading=true;
      this.openService.orcamentoCancelar(DocEntry).subscribe({
        next: () => {
          this.cancel();
          
          dialogRef.close();
        },
        error: (err) => {
          this.loading = false;
          const dialogRef = this.dialog.open(DialogErrorCancelarComponent, {
            width: '250px'
          });
        }
      });
    } else {
      this.loading=false
      
    }
  });
}




editarOrcamento(selecionado:any):void {
  this.loading = true;

    if (typeof selecionado === 'number') {
      selecionado = { DocNum: selecionado.toString() };
    }

    if (!selecionado || !selecionado.DocNum) {

      return;
    }

    this.orcamentoSelecionado = selecionado;

    if (!this.orcamentosFiltrados || this.orcamentosFiltrados.length === 0) {
  
      return;
    }

    const orcamentoEditar = this.orcamentosFiltrados.find(orcamento => {
   
    });

 
    const docentry =  this.data.orcamento.DocEntry;
 
  const codCliente = this.codClienteControl.value;
  const dataVenc =this.dataVencimentoControl.value;
  const CdEntrega = this.CdEntregaControl.value;
  const vendedor = this.SlpCodeSelecionado;
  const observacao = this.observacoescadControl.value;
  const metodopg=this.pagamentoControl.value;
  const condicaoPg=this.PayMethCodSelecionado;
  const endereco = this.enderecoControl.value;
  const entrega=this.fazendaControl.value;
  const NumAtCard=this.AtCard.value;
  const documentLines = this.dadosTabela.map(item => {
    const precoUnitarioNumerico = parseFloat(item.precoUnitario.replace(/[,.R$]/g, ''));
    return {
      ItemCode: item.nItem,
      Quantity: item.quantidade,
      UnitPrice: precoUnitarioNumerico,
      Usage: item.cod_venda,
      U_SOLU_Cultura: item.cultura,
      U_SOLU_TP_Aplicacao: item.aplicacao,
      U_OM_NumberOC: item.numeroOC,
      U_OM_NumberOCLine: null,
      ShipDate: item.data,
      DiscountPercent: parseFloat(item.desconto)
    };
  });

  const cdEntregaObj = this.cdsEntrega.find(c => c.BPLName.toString() === CdEntrega);
  const cdEntregaId = cdEntregaObj ? cdEntregaObj.BPLId : null;

  const requestBody = {
    CardCode: codCliente,
    DocDueDate: '2024-04-19',
    DocObjectCode: 23,
    BPL_IDAssignedToInvoice: cdEntregaId,
    SalesPersonCode: vendedor,
    NumAtCard: NumAtCard,
    Comments: `${observacao} \n             Alterações Realizadas`,
    U_SOLU_MEIO_PAG: metodopg,
    PaymentGroupCode: condicaoPg,
    DiscountPercent: 0.0,
    Address:"Entrega",
    Address2: endereco,
    DocumentLines: documentLines,
    TaxExtension: {
      Incoterms: 1
    }
  };


  this.loading = true;
  const dialogRef = this.dialog.open(DialogEditarComponent, {
    width: '250px',
    data: { message: 'Você tem certeza que deseja editar o orçamento?' , showSuccessMessage: false}
  });
  this.openService.editarOrcamento(requestBody, docentry).subscribe(
    (response) => {
      this.dialogRef.close(true);
      dialogRef.afterClosed().subscribe(() => {
        this.dialogRef.close(true);
      });
   

    },
    (error) => {
      const dialogRef = this.dialog.open(DialogEditarComponent, {
        width: '250px',
        data: { message: 'Erro ao enviar Orçamento' , showSuccessMessage: true}
      });
     
     
  
      this.loading=false;
    }
  );
}

modificarOrcamento(selecionado: any): void {
  this.loading = true;

  const docentry =  this.data.orcamento.DocEntry;

  if (!docentry) {
  
    this.loading = false;
    return;
  }

  this.orcamentoSelecionado = selecionado;

  if (!this.orcamentosFiltrados || this.orcamentosFiltrados.length === 0) {
   
    this.loading = false;
    return;
  }

  this.orcamentoSelecionado = this.orcamentosFiltrados.find(orcamento => {
    return orcamento.DocEntry === docentry;
  });

  if (!this.orcamentoSelecionado) {
    this.dialog.open(DialogErrorEditarComponent, {
      width: '250px',
    });
  
    this.loading = false;
    return;
  }

  const dialogRef = this.dialog.open(ModificarComponent, {
    data: { observacao: this.orcamentoSelecionado.observacao || '' }
  });

  dialogRef.afterClosed().subscribe(result => {
    

    this.loading = false;
    if (result !== undefined) {
      
      this.orcamentoSelecionado.observacao = result;
      this.continuarAtualizacaoOrcamento(docentry);
    }
  });
}

continuarAtualizacaoOrcamento(docEntry: any): void {
  const codCliente = this.codClienteControl.value;
  const CdEntrega = this.CdEntregaControl.value;
  const vendedor = this.SlpCodeSelecionado;
  const observables=this.observacoescadControl.value;
  const observacao = this.orcamentoSelecionado.observacao || '';
  const metodopg=this.pagamentoControl.value;
  const condicaoPg=this.PayMethCodSelecionado;
  const endereco = this.enderecoControl.value;
  const NumAtCard=this.AtCard.value;
  const documentLines = this.dadosTabela.map(item => {
    const precoUnitarioNumerico = parseFloat(item.precoUnitario.replace(/[,.R$]/g, ''));
    return {
      ItemCode: item.nItem,
      Quantity: item.quantidade,
      UnitPrice: precoUnitarioNumerico,
      Usage: item.cod_venda,
      U_SOLU_Cultura: item.cultura,
      U_SOLU_TP_Aplicacao: item.aplicacao,
      U_OM_NumberOC: item.numeroOC,
      U_OM_NumberOCLine: null,
      ShipDate: item.data,
      DiscountPercent: parseFloat(item.desconto)
    };
  });

  const cdEntregaObj = this.cdsEntrega.find(c => c.BPLName.toString() === CdEntrega);
  const cdEntregaId = cdEntregaObj ? cdEntregaObj.BPLId : null;
  
  const requestBody = {
    CardCode: codCliente,
    DocDueDate: '2024-04-19',
    DocObjectCode: 23,
    BPL_IDAssignedToInvoice: cdEntregaId,
    SalesPersonCode: vendedor,
    NumAtCard: NumAtCard,
    Comments: `${observacao} \n                                             Alterações Realizadas`,
    U_SOLU_MEIO_PAG: metodopg,
    PaymentGroupCode: condicaoPg,
    DiscountPercent: 0.0,
    Address:"Entrega",
    Address2: endereco,
    DocumentLines: documentLines,
    TaxExtension: {
      Incoterms: 1
    }
  };
 
  this.loading = true;

  this.openService.editarOrcamento(requestBody, docEntry).subscribe(
    (response) => {
      const dialogRef = this.dialog.open(DialogPedidoComponent, {
        width: '250px',
        data: { message: 'Modificações enviadas com Sucesso!', showSuccessMessage: true }
      });

      dialogRef.afterClosed().subscribe(() => {
        this.loading = false;
        
        this.dialogRef.close(true); 
      });
    },
    (error) => {

      this.loading = false;
    }
  );
}

async carregarCdsEntrega(): Promise<void> {
  try {
    this.loading = true;
    this.openService.carregarCdsEntrega().subscribe(
      cdsEntrega=>{
        this.cdsEntrega=cdsEntrega;
        this.cdsEntregaFiltrados = [...this.cdsEntrega];
        this.loading = false;
        const BPLName = this.data.orcamento.BPLName;
        this.selecionarCdEntregaPorNome(BPLName);
      }
    );
    
  
  } catch (error) {
  
    this.loading = false;
  }
}

filtrarCdEntrega():void{
  const {BPLName,BPLId}=this.filtroForm.value;
    this.cdsEntregaFiltrados=this.cdsEntrega.filter(cdEntrega=>{
    return(BPLId ? cdEntrega.BPLId.toLowerCase().includes(BPLId.toLowerCase()):true)&&
          (BPLName ?(cdEntrega.BPLName || '').toLowerCase().includes(BPLName.toLowerCase()):true);
    })
   
}
selecionarCdEntrega(event: any) {
  const selectedBplName = event.option.value;
  this.data.orcamento.BPLName = selectedBplName; 
  const cdEntrega = this.cdsEntrega.find(cd => cd.BPLName.toLowerCase() === selectedBplName.toLowerCase());
  if (cdEntrega) {
    this.CdEntregaControl.setValue(cdEntrega.BPLName);
    this.BPLIdSelecionado = cdEntrega.BPLId;
   
  } 
}

selecionarCdEntregaPorNome(BPLName: string) {
  const cdEntrega = this.cdsEntrega.find(cd => cd.BPLName.toLowerCase() === BPLName.toLowerCase()); 
  if (cdEntrega) {
    this.CdEntregaControl.setValue(cdEntrega.BPLName); 
    this.BPLIdSelecionado = cdEntrega.BPLId; 
  }
}
  

enviarPedido(): void {
  this.loading = true;
  const dataVenc = this.dataVencimentoControl.value;
  const CdEntregaId = this.BPLIdSelecionado;
  const documentLines = this.dadosTabela.map(item => ({
      BaseEntry: this.data.orcamento.DocEntry,
      BaseType: '23',
      BaseLine: item.nindice,
  }));

  const requestBody = {
      DocDueDate: dataVenc,
      BPL_IDAssignedToInvoice: CdEntregaId,
      DocumentLines: documentLines,
  };

  console.log('Enviando pedido com AtCard inicial:', this.AtCard.value);

  this.iniciarVerificacao(this.AtCard.value).then(atCardEncontrado => {
      if (atCardEncontrado) {
          console.log('AtCard válido encontrado:', this.AtCard.value);
          this.tentarEnvioPedido(requestBody);
      } else {
          console.log('AtCard não encontrado, gerando um novo AtCard...');
          const novoAtCard = this.gerarNovoAtCard(this.AtCard.value);
          console.log('Novo AtCard gerado:', novoAtCard);


          this.tentarEnvioPedido(requestBody); 
      }
  }).catch(() => {
      
      this.loading = false;
  });
}

private tentarEnvioPedido(requestBody: any, tentativas: number = 0): void {
  console.log(`Tentativa de envio do pedido (${tentativas + 1}):`, requestBody);
  this.openService.envioPedido(requestBody).pipe(
      catchError(error => {
          console.error('Erro ao enviar pedido:', error);
          return throwError(error);
      }),
      finalize(() => {
          this.loading = false;
      })
  ).subscribe(
      response => {
       
          const successDialogRef = this.dialog.open(DialogPedidoComponent, {
              width: '350px',
              data: { message: 'Pedido Aprovado com Sucesso!', showSuccessMessage: true }
          });
          successDialogRef.afterClosed().subscribe(() => {
              this.cancel();
          });
      },
      error => {
 
          if (error.code === -1116 && tentativas < 3) {
              console.log('Erro de duplicidade no AtCard, gerando um novo AtCard e tentando novamente...');
              const novoAtCard = this.gerarNovoAtCard(this.AtCard.value);
              requestBody.NumAtCard = novoAtCard;
              console.log('Novo AtCard gerado para reenvio:', novoAtCard);
              this.tentarEnvioPedido(requestBody, tentativas + 1); 
          } else if (tentativas >= 2) {
   
              console.error('Erro ao aprovar o pedido:', error);
              const errorDialogRef = this.dialog.open(DialogPedidoComponent, {
                  width: '350px',
                  data: { message: 'Erro ao Aprovar pedido!', showSuccessMessage: true }
              });
              errorDialogRef.afterClosed().subscribe(() => {
                  
              });
          }
      }
  );
}






modificarAtCardEEnviarOrcamento(requestBody: any): void {
  const novoAtCard = this.gerarNovoAtCard(this.AtCard.value); 
  requestBody.NumAtCard = novoAtCard;

  const docentry = this.data.orcamento.DocEntry;
  const codCliente = this.codClienteControl.value;
  const dataVenc = this.dataVencimentoControl.value;
  const CdEntrega = this.CdEntregaControl.value;
  const vendedor = this.SlpCodeSelecionado;
  const observacao = this.observacoescadControl.value;
  const metodoPg = this.pagamentoControl.value;
  const condicaoPg = this.PayMethCodSelecionado;
  const endereco = this.enderecoControl.value;
  const entrega = this.fazendaControl.value;

  const documentLines = this.dadosTabela.map(item => {
      const precoUnitarioNumerico = parseFloat(item.precoUnitario.replace(/[,.R$]/g, ''));
      return {
          ItemCode: item.nItem,
          Quantity: item.quantidade,
          UnitPrice: precoUnitarioNumerico,
          Usage: item.cod_venda,
          U_SOLU_Cultura: item.cultura,
          U_SOLU_TP_Aplicacao: item.aplicacao,
          U_OM_NumberOC: item.numeroOC,
          U_OM_NumberOCLine: null,
          ShipDate: item.data,
          DiscountPercent: parseFloat(item.desconto)
      };
  });

  const cdEntregaObj = this.cdsEntrega.find(c => c.BPLName.toString() === CdEntrega);
  const cdEntregaId = cdEntregaObj ? cdEntregaObj.BPLId : null;

  const requestBodyOrcamento = {
      CardCode: codCliente,
      DocDueDate: dataVenc,
      DocObjectCode: 23,
      BPL_IDAssignedToInvoice: cdEntregaId,
      SalesPersonCode: vendedor,
      NumAtCard: novoAtCard,
      Comments: observacao,
      U_SOLU_MEIO_PAG: metodoPg,
      PaymentGroupCode: condicaoPg,
      DiscountPercent: 0.0,
      Address: "Entrega",
      Address2: endereco,
      DocumentLines: documentLines,
      TaxExtension: {
          Incoterms: 1
      }
  };

  console.log('Request body do orçamento:', requestBodyOrcamento); 
  this.openService.editarOrcamento(requestBodyOrcamento, docentry).subscribe(
      (response) => {
          console.log('Orçamento editado com sucesso:', response); 
          this.enviarPedido()
      },
      error => {
          console.error('Erro ao editar orçamento:', error); 
      }
  );
}

gerarNovoAtCard(atCardExistente: string): string {

  const letras = atCardExistente.substring(0, 3);
  
 
  const numeros = atCardExistente.substring(3);
  

  const novaParteNumerica = this.incrementarUltimoNumero(numeros);

 
  const novoAtCard = letras + novaParteNumerica;

  console.log("Novo AtCard gerado:", novoAtCard);
  return novoAtCard;
}

incrementarUltimoNumero(numeros: string): string {

  const numeroAtual = parseInt(numeros, 10); 
  const novoNumero = numeroAtual + 1;
  return novoNumero.toString(); 
}



private async iniciarVerificacao(numAtCard: any): Promise<boolean> {
  const maxTentativas = 2; 
  const delay = 5000; 
  let tentativas = 0;
  let atCardAtual = numAtCard;

  while (tentativas < maxTentativas) {
      console.log(`Tentativa ${tentativas + 1}: Verificando se o AtCard ${atCardAtual} já existe...`);
      try {
          const numbersCard = await this.openService.obterAtCard().toPromise();
          const numberCardFiltrado = numbersCard.map(card => card.NumAtCard.toString());

          if (numberCardFiltrado.includes(atCardAtual) || !atCardAtual) {
              console.log('AtCard já existe ou está vazio. Gerando um novo AtCard...');
              
        
              atCardAtual = this.gerarNovoAtCard(atCardAtual); 

            
              if (!atCardAtual) {
                  console.log('AtCard gerado ainda está vazio. Tentando novamente...');
                  tentativas++;
                  continue;
              }


              console.log('Chamando modificarAtCardEEnviarOrcamento com o novo AtCard:', atCardAtual);
              this.modificarAtCardEEnviarOrcamento({ NumAtCard: atCardAtual });
              return true; 
          } else {
              console.log('AtCard é válido:', atCardAtual);
              this.AtCard.setValue(atCardAtual);
              return true;
          }
      } catch (error) {
          console.error('Erro ao obter AtCard:', error);
          return false;
      }

      
  }

  console.error('Não foi possível gerar um AtCard válido após todas as tentativas');
  return false;
}






envioEmail(NumAtCard: any): void {
    const valorAtCard = NumAtCard;
    const partes = valorAtCard.match(/\d+/g);
    const codigoVenda = partes ? partes.join('') : '';

    const docEntry = codigoVenda;

    this.openService.envioEmail(docEntry).subscribe(
        emailResponse => {
       
        },
       
    );
}

}
