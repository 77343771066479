<div class="flex flex-col max-w-200 md:min-w-160 -m-6">
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">Modificações</div>
        <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div class="header">
        Descreva as alterações desejadas:
        <div  class="title-cabecalho">
    
      
      </div>
          
    <div class="content">
        <mat-form-field class="campos_cod" appearance="outline">
            
            <mat-label class="label">Adicone as Modificações desejadas: </mat-label>
            <input type="textearea" matInput [formControl]="modificarControl"    >
            
        </mat-form-field>
    </div>
      <div class="button">
        <button  mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" (click)="sendObservacao()"   >
            <span class="mat-button-wrapper">
              
                  <span class="ml-2 mr-1">Enviar</span>
              </span>
              <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
              </span>
              <span class="mat-button-focus-overlay">
              </span>
            </button>
      </div>
</div>