import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { SquadsService } from './squads.service';
import { Squad } from 'app/core/models/admin/squads.model';
import { Farm } from 'app/core/models/admin/farms.model';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class FarmsService {

  private _farm: BehaviorSubject<Farm | null> = new BehaviorSubject(null);
  private _farms: BehaviorSubject<Farm[] | null> = new BehaviorSubject(null);
  private host: string;
  private farms: Farm[];
  private squads: Squad[];
  private farmList: Farm[] = [];
  isLoading: boolean=false;

  constructor(
    private _httpFarm: HttpClient,
    private _serviceSquad: SquadsService,
    private _fuseConfigService: FuseConfigService
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.custHost;
    });
  }

  get farm$(): Observable<Farm> {
    return this._farm.asObservable();
  }

  get farms$(): Observable<Farm[]> {
    return this._farms.asObservable();
  }


  getFarms(): Observable<Farm[]> {
    return this._httpFarm
      .get<Farm[]>(this.host + '/api/v1/farm?orderby=farm_name&order=asc')
      .pipe(
        take(1),
        tap(farms => {
          this.farms = farms.sort((a, b) => {
            if (
              (a.identity_name || a.farm_name).toLowerCase() >
              (b.identity_name || b.farm_name).toLowerCase()
            ) {
              return 1;
            }

            if (
              (a.identity_name || a.farm_name).toLowerCase() <
              (b.identity_name || b.farm_name).toLowerCase()
            ) {
              return -1;
            }

            return 0;
          });
          this._serviceSquad.getSquads().subscribe((products: Squad[]) => {
            this.squads = products;
          });
          this.farmList = farms;
          this._farms.next(farms);
        })
      );
  }

  getFarmsMemory(): Observable<Farm[]> {
    if (this.farmList.length > 0) {
      return of(this.farmList);
    }
    return this.getFarms();
  }

  searchFarms(search: string): Observable<Farm[]> {
    search = search.toLowerCase();
    const filteredsquad = this.squads
      .filter(squad => squad.code.toLowerCase().includes(search))
      .map(u => u.id.toString());
    let filtered = this.farms.filter(
      f =>
        f.farm_name.toLowerCase().includes(search) ||
        f.company_name?.toLowerCase().includes(search) ||
        f.identity_name?.toLowerCase().includes(search) ||
        f.cnpj?.includes(search) ||
        f.cpf?.includes(search) ||
        f.state_register?.includes(search) ||
        f.city?.toLowerCase().includes(search) ||
        f.address?.toLowerCase().includes(search) ||
        (f.squad_id ? filteredsquad.includes(f.squad_id.toString()) : '')
    );
    if (search.length > 0 && search[0] == '#') {
  
      filtered = this.farms.filter(
        f => f.erp_id?.toString() == search.substr(1, search.length)
      );
    }
    this._farms.next(filtered);
    return of(filtered);
  }

  searchFarmByUser(user_id: string): Observable<Farm[]> {
    return this._httpFarm
      .get<Farm[]>(this.host + '/api/v1/farm?user_id=' + user_id)
      .pipe(
        tap(farms => {
          this._farms.next(farms);
        })
      );
  }

  getFarmById(id: string): Observable<Farm> {
    if (id === 'new') {
      const farm: Farm = {
        id: 0,
        erp_id: '',
        company_type: 0,
        farm_name: '',
        identity_name: null,
        company_name: '',
        cnpj: '',
        cpf: '',
        state_register: '',
        address: '',
        city: '',
        state: '',
      };
      this._farm.next(farm);
      return of(farm);
    }
    return this._farms.pipe(
      take(1),
      map(farms => {

        const farm = farms.find(item => item.id.toString() === id) || null;

 
        this._farm.next(farm);


        return farm;
      }),
      switchMap(farm => {
        if (!farm) {
          return throwError('Could not found Farm with id of ' + id + '!');
        }

        return of(farm);
      })
    );
  }

  getFarmsByTerritoryId(territory_id: number): Observable<Farm[]> {
    return this._httpFarm.get<Farm[]>(
      this.host + `/api/v1/farm?territory_id=${territory_id}`
    );
  }

  convertObjectsToTableRows(objects: any[]): any[][] {
    const headers = Object.keys(objects[0]);
    const rows = objects.map(obj => headers.map(header => obj[header]));
    return [headers, ...rows];
  }

  private _autoFitColumns(
    dataArray: Array<Array<any>>,
    worksheet: XLSX.WorkSheet
  ) {
    const objectMaxLength: Array<number> = [];

    dataArray.map(rows => {
      rows.map((v, idx) => {
        const columnValue = `${v}`;
        objectMaxLength[idx] =
          objectMaxLength[idx] >= columnValue.length
            ? objectMaxLength[idx]
            : columnValue.length;
      });
    });

    const wscols = objectMaxLength.map(w => ({ width: w + 8 }));
    worksheet['!cols'] = wscols;
  }
  downloadFarms(objects: any[], onProgress: (progress: number) => void): void { 
    this.isLoading = true;
    const totalItems = objects.length;
    let processedItems = 0;

    const workBook = XLSX.utils.book_new();
    const rows = [];

    for (let i = 0; i < totalItems; i++) {
        const row = this.convertObjectsToTableRows([objects[i]]);
        rows.push(...row);

        processedItems++;
        const progress = Math.round((processedItems / totalItems) * 100);
        onProgress(progress); 
    }

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    this._autoFitColumns(rows, workSheet);
    XLSX.utils.book_append_sheet(workBook, workSheet);
    XLSX.writeFile(workBook, 'fazendas.xlsx', {});

    onProgress(100); 
    this.isLoading = false; 
}


  createFarm(farm: Farm): Observable<Farm> {
    return this.farms$.pipe(
      take(1),
      switchMap(farms =>
        this._httpFarm.post<Farm>(this.host + '/api/v1/farm/', farm).pipe(
          map(newFarm => {

            this.farms = [newFarm, ...farms];
            this._farms.next([newFarm, ...farms]);
            this.farmList = [];

            return newFarm;
          }),
          switchMap(createdFarm =>
            this.farm$.pipe(
              take(1),
              filter(item => item && item.id.toString() === '0'),
              tap(() => {
                this._farm.next(createdFarm);
                return createdFarm;
              })
            )
          )
        )
      )
    );
  }

  updateFarm(id: string, farm: Farm): Observable<Farm> {
    return this.farms$.pipe(
      take(1),
      switchMap(farms =>
        this._httpFarm.put<Farm>(this.host + '/api/v1/farm/' + id, farm).pipe(
          map(updatedFarm => {

            const index = farms.findIndex(item => item.id.toString() == id);

            farms[index] = updatedFarm;
            this.farms = farms;
            this.farmList = [];
            this._farms.next(farms);
            return updatedFarm;
          })
        )
      )
    );
  }

  deleteFarm(id: string): Observable<boolean> {
    return this.farms$.pipe(
      take(1),
      switchMap(farms =>
        this._httpFarm
          .delete(this.host + '/api/v1/farm/' + id, { responseType: 'text' })
          .pipe(
            map(value => {
              const index = farms.findIndex(item => item.id.toString() === id);

              farms.splice(index, 1);
              const indexFarms = this.farms.findIndex(
                item => item.id.toString() === id
              );
              this.farms.splice(indexFarms, 1);
              this.farmList = [];
              this._farms.next(farms);
              return true;
            })
          )
      )
    );
  }

  updateFarmTerritory(farmId, territoryId) {
    return this._httpFarm.put<any>(this.host + '/api/v1/farm/' + farmId, {
      territory_id: territoryId,
    });
  }

  setUserToFarm(farmId: string, userId: string): Observable<Farm> {
    return this._httpFarm
      .post<any>(this.host + '/api/v1/farm/' + farmId + '/user', [
        { user_id: userId },
      ])
      .pipe(
        tap(v => {
          this.farmList = [];
        })
      );
  }

  removeUserFromFarm(farmId: string, userId: string): Observable<Farm> {
    return this._httpFarm
      .delete<any>(this.host + '/api/v1/farm/' + farmId + '/user/' + userId)
      .pipe(
        tap(v => {
          this.farmList = [];
        })
      );
  }

  getFarmName(id: string): string {
    if (!this.farmList) {
      return '';
    }
    return this.farmList
      .filter(f => f.id.toString() == id)
      .map(fa =>
        fa.identity_name
          ? fa.identity_name + ' - ' + fa.farm_name
          : fa.farm_name
      )[0];
  }

  async addNewBioFactory(bioFactory) {
    let response = await firstValueFrom(
      this._httpFarm.post<any>(this.host + '/api/v1/biofactory/', bioFactory)
    );
  }

  async deletebiofactory(biofabId: string) {
    let response = await firstValueFrom(
      this._httpFarm.delete<any>(
        this.host + '/api/v1/biofactory/' + biofabId.toString()
      )
    );
  }
}
