<!-- fazenda-list.component.html -->

<div class="container">
  <div class="filter">
    <form [formGroup]="filtroForm" aria-label="Filtro de Fazendas" class="filtro-form">
      <div class="form-field">
        <label for="code">Código</label>
        <input type="text" id="code" formControlName="code" placeholder="Código" aria-label="Filtro por Código">
      </div>
      <div class="form-field">
        <label for="u_regional">Regional</label>
        <input type="text" id="u_regional" formControlName="u_regional" placeholder="Regional" aria-label="Filtro por Regional">
      </div>
      <div class="form-field">
        <label for="u_grandesContas">Grandes Contas</label>
        <select id="u_grandesContas" formControlName="u_grandesContas" aria-label="Filtro por Grandes Contas">
          <option value="">Todos</option>
          <option value="Sim">Sim</option>
          <option value="Não">Não</option>
        </select>
      </div>
      <div class="form-field">
        <label for="u_colaborador">Colaborador</label>
        <input type="text" id="u_colaborador" formControlName="u_colaborador" placeholder="Colaborador" aria-label="Filtro por Colaborador">
      </div>
      <button type="button" (click)="limparFiltros()" aria-label="Limpar Filtros" class="btn-limpar">Limpar Filtros</button>
    </form>
  </div>

  <div *ngIf="loading" class="loading">
    <span class="loading-icon">Carregando...</span>
  </div>
  <div *ngIf="error" class="error">
    <span class="error-icon">Erro ao carregar as fazendas. Tente novamente mais tarde.</span>
  </div>
  <div *ngIf="!loading && !error" class="fazenda-content">
    <div class="info-button-unselected" *ngIf="!fazendas || fazendas.length === 0">
      Informações (Não selecionado)
    </div>
    <div *ngIf="fazendasFiltradas && fazendasFiltradas.length > 0" class="farm_filter">
      <h2>Lista de Fazendas</h2>
      <ul class="fazenda-list">
        <li *ngFor="let fazenda of fazendasFiltradas" class="fazenda-item">
      <div class="fazenda-info">
        <div class="column">
          <strong>Código:</strong> {{ fazenda.Code || 'N/A' }}<br>
          <strong>Nome:</strong> {{ fazenda.Name || 'N/A' }}<br>
          <strong>Cancelada:</strong> {{ fazenda.Canceled }}<br>
          <strong>Usuário:</strong> {{ fazenda.UserSign || 'N/A' }}<br>
          <strong>Transferida:</strong> {{ fazenda.Transfered }}<br>
          <strong>Data de Criação:</strong> {{ fazenda.CreateDate | date }}<br>
          <strong>Fonte de Dados:</strong> {{ fazenda.DataSource || 'N/A' }}<br>
        </div>
        <div class="column">
          <strong>U_REG_TPT01:</strong> {{ fazenda.U_REG_TPT01 || '-Não definido-' }}<br>
          <strong>U_REG_TPT02:</strong> {{ fazenda.U_REG_TPT02 || '-Não definido-' }}<br>
          <strong>U_REG_TPT03:</strong> {{ fazenda.U_REG_TPT03 || '-Não definido-' }}<br>
          <strong>U_REG_TPT04:</strong> {{ fazenda.U_REG_TPT04 || '-Não definido-' }}<br>
          <strong>U_REG_TPT05:</strong> {{ fazenda.U_REG_TPT05 || '-Não definido-' }}<br>
          <strong>U_Grupo:</strong> {{ fazenda.U_Grupo || 'N/A' }}<br>
          <strong>U_REGIONAL:</strong> {{ fazenda.U_REGIONAL || 'N/A' }}<br>
          <strong>U_Resp_Captacao:</strong> {{ fazenda.U_Resp_Captacao || 'N/A' }}<br>
          <strong>U_Coordenadas:</strong> {{ fazenda.U_Coordenadas || 'N/A' }}<br>
          <strong>U_Capacidade:</strong> {{ fazenda.U_Capacidade || 'N/A' }}<br>
          <strong>U_DataChurn:</strong> {{ fazenda.U_DataChurn || 'N/A' }}<br>
          <strong>U_OnFarm:</strong> {{ fazenda.U_OnFarm || 'N/A' }}<br>
          <strong>U_GrandesContas:</strong> {{ fazenda.U_GrandesContas || 'N/A' }}<br>
          <strong>U_Motivo:</strong> {{ fazenda.U_Motivo || 'N/A' }}<br>
        </div>
      </div>
      <!-- Adicione mais campos conforme necessário -->
      <hr>
    </li>
  </ul>
</div>

<div *ngIf="fazendas && fazendas.length === 0" class="fazenda-list-empty">
  Nenhuma fazenda encontrada.
</div>
</div>
</div>