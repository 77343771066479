import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-pedido',
  templateUrl: './dialog-pedido.component.html',
  styleUrls: ['./dialog-pedido.component.scss']
})
export class DialogPedidoComponent implements OnInit {

  constructor(public dialogRef:MatDialogRef<DialogPedidoComponent>,public dialog:MatDialog,  @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  close(){
    
    this.dialogRef.close();
  }


}
