import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {
  apiUrl: string = ''; 
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.custHost;
  }
 novoEquipamento(requestBody:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/equipment`;
    return this.http.post<any>(url,requestBody);
   }
   equipamentoId(id:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/equipment/${id}`;
    return this.http.get<any>(url);
   }
   novaCategoria(requestBody:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/equipments/nameequipment`;
    return this.http.post<any>(url,requestBody);
   }

   novoTipo(requestBody:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/equipments/typeequipment`;
    return this.http.post<any>(url,requestBody);
   }
   
  getTipo(): Observable<any[]> {
    const url = `${this.apiUrl}/api/v1/equipments/typeequipment`;
    return this.http.get<any>(url);
   }
   getCategoria(): Observable<any[]> {
    const url = `${this.apiUrl}/api/v1/equipments/nameequipment`; 
    return this.http.get<any>(url);
  }
  getTipoId(id:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/equipments/typeequipment?name_equipment_id=${id}`;
    return this.http.get<any>(url);
  }

  getEquipamentos(): Observable<any[]> {
    const url = `${this.apiUrl}/api/v1/equipment`; 
    return this.http.get<any>(url);
  }
  deleteCategoria(id:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/equipments/nameequipment/${id}`;
    return this.http.delete<any>(url);
   }
   deleteTipo(id:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/equipments/nameequipment/${id}`;
    return this.http.delete<any>(url);
   }
   getEquipamentosFazenda(id:any): Observable<any[]> {
    const url = `${this.apiUrl}/api/v1/equipment?farm_id=${id}`;
    return this.http.get<any>(url); 
   }


   editEquipamento(requestBody:any,id:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/v1/equipment/${id}`;
    return this.http.put<any>(url,requestBody);
   }

   deleteEquipment(id:any): Observable<any[]> {
    const url = `${this.apiUrl}/api/v1/equipment/${id}`;
    return this.http.delete<any>(url); 
   }
   
      getEquipamentosQuestions(id:any,name:any): Observable<any[]> {
    const url = `${this.apiUrl}/api/v1/equipment?farm_id=${id}&name=${name}`;
    return this.http.get<any>(url); 
   }

}
