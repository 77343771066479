import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChecklistService } from '../../checklist.service';
import { map, startWith } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef ,MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../../alert/alert/alert.component';
import { Control } from 'leaflet';
import { EquipmentsService } from 'app/core/services/admin/equipments.service';
import { setValue } from '@ngneat/transloco';
@Component({
  selector: 'app-popup-question',
  templateUrl: './popup-question.component.html',
  styleUrls: ['./popup-question.component.scss']
})
export class PopupQuestionComponent implements OnInit {
  selectedEquipamento: any;
  loading=false;
  secoesFiltradas:any[] = [];
  secoes:any[] = [];
  equipamentos:any[] = [];
  equipamentosFiltrados:any[] = [];
  sectionIdSelecionado: string | null = null;
  IdControl=new FormControl();
  SectionControl=new FormControl();
  selectedSectionId: number | null = null;
  QuestionControl=new FormControl();
  ActiveControl=new FormControl();
  EquipmentControl=new FormControl();
  filtroForm: FormGroup;
  filteredSecao: any;
  editMode:boolean;
  constructor( private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder, 
    public dialogRef:MatDialogRef<PopupQuestionComponent>,
    private checklistService:ChecklistService,
    public dialog: MatDialog,
    private equipmentService:EquipmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any) { this.editMode = data.editMode;}

  ngOnInit(): void {
    this.carregarSecoes();
    this.carregarEquipamentos();
    this.disableId()


    this.filtroForm = this.fb.group([{id: [''],section:[''],group:[''],type:['']}]);

    this.filteredSecao = this.SectionControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value?.section), 
      map(nome => nome ? this._filterSecao(nome) : this.secoes.slice()) 
    );

    
    this.SectionControl.valueChanges.subscribe(value => {
      if (value && value.id) {
        this.selectedSectionId  = value.id;
       
      }
    });
    
    this.checklistService.carregarSecao().subscribe(secoes => {

    });

    if (this.editMode && this.data?.response) {
      const activeStatus = this.data.response.active ? 'true' : 'false';
      this.ActiveControl.setValue(activeStatus);
      this.IdControl.setValue(this.data.response.id);
      this.SectionControl.setValue(this.data.response.section_id);
      this.QuestionControl.setValue(this.data.response.question);
    
  
      const section = this.secoes.find(secao => secao.id === this.data.response.section_id);
      if (section) {
        this.SectionControl.setValue(section);
      }
      const equipamentoName=this.equipamentos.find(equipamento=>equipamento.id===this.data.response.name_equipment_id)
      if (equipamentoName) {
      this.EquipmentControl.setValue(equipamentoName)
      }
    }
  }
  

  private _filterSecao(nome: string) {
    const filterValue = nome.toLowerCase();
    return this.secoes.filter(secao => secao.section.toLowerCase().includes(filterValue));
  }



  carregarSecoes(): void {
    this.loading=true;
       this.checklistService.carregarSecao().subscribe(
         secoes => {
           if (secoes && secoes.length > 0) {  
            this.secoes = secoes;
             this.secoesFiltradas = [...this.secoes];
             this.filtrarSecoes(); 
             this.loading=false;
            
             if (this.editMode && this.data?.response?.section_id) {
              this.selecionarSecaoPorId(this.data.response.section_id);
            }
             this.changeDetectorRef.detectChanges();
             
           } 
           this.loading = false; 
         },
       );
     } 


     selecionarSecao(event: any): void {
      if (event.option && event.option.value) {
        const selectedSection = event.option.value;
        const selectedSectionId = selectedSection.id.toString(); 
        const secao = this.secoes.find(s => s.id.toString() === selectedSectionId);
    
        if (secao) {
          this.SectionControl.setValue(secao.section); 
        } 
      }
    }
    
    

    selecionarSecaoPorId(sectionId: string) {
      const secao = this.secoes.find(s => s.id === sectionId);  
    
      if (secao) {
        this.SectionControl.setValue(secao);  
        this.sectionIdSelecionado = secao.id; 
      } 
    }
  
   filtrarSecoes(): void {
  const { id, section, type } = this.filtroForm.value || {};  
  this.secoesFiltradas = this.secoes.filter(secao => {
    return (
      (id ? (secao.id || '').toString().toLowerCase().includes(id.toLowerCase()) : true) &&
      (section ? (secao.section || '').toLowerCase().includes(section.toLowerCase()) : true) &&
      (type ? (secao.type || '').toString().toLowerCase().includes(type.toLowerCase()) : true)
    );
  });

}

displaySecao(secao: any): string {
  return secao ? `${secao.id} - ${secao.section}` : '';
}
cancel(): void {
  this.dialogRef.close(true);
}


disableId(){
  this.IdControl.disable();
}

editarQuestion(){
const id=this.IdControl.value;
const requestBody: {
  question: any;
  section_id: number;
  active: any;
  type: any;
  expected_answer: number;
  name_equipment_id?: number | null;
} = {
  question: this.QuestionControl.value,
  section_id: this.selectedSectionId,
  active: this.ActiveControl.value,
  type: "boolean",
  expected_answer: 1,
  name_equipment_id: null ,
};

if (this.selectedEquipamento) {
  requestBody.name_equipment_id = this.selectedEquipamento.id; 
}
this.checklistService.editarQuestions(id ,requestBody).subscribe(
  response => { 
   
      const dialogRef = this.dialog.open(AlertComponent, {
        width: '250px',
        data: { message: 'Questão editada com sucesso !', showSuccessMessage: true }
      });
      dialogRef.afterClosed().subscribe(() => {
     
        this.cancel();
      
      });
  },
  error => {
     
      const dialogRef = this.dialog.open(AlertComponent, {
        width: '250px',
        data: { message: 'Erro ao enviar dados para a API ', showSuccessMessage: false }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.cancel();
     
        
      }); 
    }
);
 }

   enviarDadosParaApi(): void {

    const requestBody: {
      question: any;
      section_id: number;
      active: any;
      type: any;
      expected_answer: number;
      name_equipment_id?: number| null;
    } = {
      question: this.QuestionControl.value,
      section_id: this.selectedSectionId,
      active: this.ActiveControl.value,
      type: "boolean",
      expected_answer: 1,
    };
    if (this.selectedEquipamento) {
      requestBody.name_equipment_id = this.selectedEquipamento.id; 
  }
  
    this.checklistService.criarQuestion(requestBody).subscribe(
        response => {

           
            const dialogRef = this.dialog.open(AlertComponent, {
              width: '250px',
              data: { message: 'Questão criada com sucesso !', showSuccessMessage: true }
            });
            dialogRef.afterClosed().subscribe(() => {
              this.cancel();
              this.carregarSecoes();
            
              
            });
        },
        error => {
          
            const dialogRef = this.dialog.open(AlertComponent, {
              width: '250px',
              data: { message: 'Erro ao enviar dados para a API ', showSuccessMessage: false }
            });
            dialogRef.afterClosed().subscribe(() => {
              this.cancel();
           
              
            });
        }
    );
}


carregarEquipamentos(): void {
  this.loading = true;
  this.equipmentService.getCategoria().subscribe(
    equipamentos => {
      if (equipamentos && equipamentos.length > 0) {
        this.equipamentos = equipamentos;
        this.equipamentosFiltrados = [...this.equipamentos];
        this.filtrarEquipamentos();
        this.loading = false;

        if (this.editMode && this.data?.response?.name_equipment_id) {
          this.selecionarEquipamentoPorId(this.data.response.name_equipment_id);
        }

        this.changeDetectorRef.detectChanges();
      } else {
        this.loading = false;
      }
    },
    error => {
      console.error('Erro ao carregar equipamentos', error);
      this.loading = false;
    }
  );
}

filtrarEquipamentos(): void {
  const { id, name } = this.filtroForm.value;
  this.equipamentosFiltrados = this.equipamentos.filter(equipamento => {
    return (id ? equipamento.id.toString().toLowerCase().includes(id.toLowerCase()) : true) &&
           (name ? (equipamento.name || '').toLowerCase().includes(name.toLowerCase()) : true);
  });
}

selecionarEquipamentoPorId(equipamentoId: number | null): void {
  if (equipamentoId === null) {

    this.selectedEquipamento = null;
    this.EquipmentControl.setValue('Nenhum');

  } else {
  
    const equipamentoSelecionado = this.equipamentos.find(equip => equip.id === equipamentoId);

    if (equipamentoSelecionado) {
      this.selectedEquipamento = equipamentoSelecionado;
      this.EquipmentControl.setValue(equipamentoSelecionado); 
      
    } 
  }
}


displayEquipamento(equipamento: any): string {
  if (equipamento === null) {
    return 'Nenhum'; 
  }
  return equipamento && equipamento.name ? equipamento.name : '';
}




}
