import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-editar',
  templateUrl: './dialog-editar.component.html',
  styleUrls: ['./dialog-editar.component.scss']
})
export class DialogEditarComponent implements OnInit {

  constructor(public dialogRef:MatDialogRef<DialogEditarComponent>,public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }
  close(){
    
    this.dialogRef.close(true);
  }

  fechar(){
    this.dialogRef.close(false)
  }
}
