//src/app/modules/admin/sales/open-sales/open-sales.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable,throwError, of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';

import { Cadastro,Item } from '../Types';


@Injectable({
  providedIn: 'root'
})
export class OpenSalesService {
  apiUrl: string = ''; 
  apiUrlOnFarm: string = ''; 
  
  constructor(private http: HttpClient) { 
    this.apiUrl = environment.apiSap;
    this.apiUrlOnFarm = environment.operHost;
  }

  async enviarPedido(requestBody: any): Promise<Observable<any>> {
    const url = `${this.apiUrl}/api/orcamentos/cadastrar`;
    return await this.http.post<any>(url,requestBody);
  }

  cabecalho: any[] = []
  
  carregarOrcamentos(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaOrcamentos').pipe(
      map(response => {
        return response.listaOrcamentos || [];
      }),
      catchError(error => {
        console.error('Erro ao carregar orçamentos:', error);
        return throwError(error);
      })
    );
  }

  envioPedido(requestBody:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/orders/cadastrar `;
    return this.http.post<any>(url,requestBody);
   }
     envioEmail(DocEntry:string): Observable<any> {
    const url = `${this.apiUrl}/api/consulta/pedido/${DocEntry}/pdf`;
   return this.http.get<any>(url);

  }

enviarPDf(DocEntry:number): Observable<any[]>{
  const url = `${this.apiUrl}/api/consulta/orcamento/${DocEntry}/pdf`;
  return this.http.get<any>(url);
}
  orcamentoDetails(DocEntry: number): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/orcamento/${DocEntry}`;
    return this.http.get<any>(url);
  }
  
 orcamentoCancelar(DocEntry: number): Observable<any> {
    const url = `${this.apiUrl}/api/orcamentos/cancelar/${DocEntry}`;
    const requestBody = { DocEntry }; 
    return this.http.post<any>(url, requestBody);
  }


  pagamentoForma():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaFormaPagamento').pipe(map(response=>{
      return response.listaFormaPagamento || [];
  
    }), 
    catchError(error => {
      console.error('Erro ao carregar formas de pagamento :', error);
      return throwError(error);
    })
  );
  
  }

  condicaoPagamento():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaCondicaoPagamento').pipe(
      map(response=>{
      return response.listaCondicaoPagamento || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar condições de pagamento :', error);
      return throwError(error);
    })
  );
  
  }

obterVendedores():Observable<any[]>{
  return this.http.get<any>(this.apiUrl+ '/api/consulta/listaVendedores').pipe(
    map(response=>{
    return response.listaVendedores || [];
  }), 
  catchError(error => {
    console.error('Erro ao carregar lista de vendedores :', error);
    return throwError(error);
  })
);
}
   

obterClientes():Observable<any[]>{
  return this.http.get<any>(this.apiUrl+ '/api/consulta/listaClientes').pipe(
    map(response=>{
    return response.listaClientes || [];
  }), 
  catchError(error => {
    console.error('Erro ao carregar lista de clientes:', error);
    return throwError(error);
  })
);
}
filtrarPorNomeUsuario(nomeVendedor: string): Observable<any[]> {
  const url = `${this.apiUrl}/api/consulta/listaOrcamentos?nomeVendedor='${nomeVendedor}'`;
  return this.http.get<any>(url).pipe(
    map(response => {
      return response.listaOrcamentos || [];
    })
    
  );
}

obterMetodoPagamento():Observable<any[]>{
  return this.http.get<any>(this.apiUrl+ '/api/consulta/listaFormaPagamento').pipe(
    map(response=>{
    return response.listaFormaPagamento || [];
  }), 
  catchError(error => {
    console.error('Erro ao carregar lista de vendedores :', error);
    return throwError(error);
  })
);
}
editarOrcamento(requestBody: any, DocEntry: string): Observable<any> {
  const url = `${this.apiUrl}/api/orcamentos/editar/${DocEntry}`;
 const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'docentry': DocEntry
  });  

  return this.http.patch(url, requestBody);
}

carregarCdsEntrega(){
  return this.http.get<any>(this.apiUrl+ '/api/consulta/listaFiliais').pipe(
    map(response=>{
    return response.listaFiliais || [];
  }), 
  catchError(error => {
    console.error('Erro ao carregar lista de vendedores :', error);
    return throwError(error);
  })
);
}
carregarItens(): Observable<any[]> {
  return this.http.get<any>(`${this.apiUrl}/api/consulta/listaItems`).pipe(
    map(response => {
      return response.listaItems || [];
    }),
    catchError(error => {
      console.error('Erro ao carregar lista de itens:', error);
      return throwError(error);
    })
  );
}
carregarFrete(){
  return this.http.get<any>(this.apiUrl+ '/api/consulta/listaFrete').pipe(
    map(response=>{
    return response.listaFrete || [];
  }), 
  catchError(error => {
    console.error('Erro ao carregar lista de vendedores :', error);
    return throwError(error);
  })
);
}
buscarDados(parameters: string):Observable<any[]> {
  const url=`${this.apiUrl}${parameters}`
  return this.http.get<{ listaOrcamentos: any[] }>(url).pipe(
    map(response => {
      return response.listaOrcamentos || [];
    })
    
  );
}

carregarCultura(): Observable<any[]> {
  return this.http.get<any>(`${this.apiUrl}/api/consulta/listaMeioCultura`).pipe(
    map(response => {
      return response.listaMeioCultura || [];
    }),
    catchError(error => {
      console.error('Erro ao carregar lista de Meio de Cultura:', error);
      return throwError(error);
    })
  );
}

obterAtCard(){
  return this.http.get<any>(this.apiUrl+ '/api/consulta/listaPedidos?ultimo=true').pipe(
    map(response => {
      return response.listaPedidos || [];
    }),
    catchError(error => {
      console.error('Erro ao carregar orçamentos:', error);
      return throwError(error);
    })
  );
}
filtrarPorCardCode(CardCode: any): Observable<any[]> {
  if (!CardCode) {
    // Se o CardCode for inválido (null, undefined, ou ''), retorna um Observable vazio
    return of([]);
  }

  const url = `${this.apiUrl}/api/consulta/listaOrcamentos?CardCode='${CardCode}'`;
  return this.http.get<any>(url).pipe(
    map(response => {
      return response.listaOrcamentos || [];
    }),
    catchError(error => {
      console.error('Erro ao filtrar orçamentos por CardCode:', error);
      return throwError(error);
    })
  );
}

obterRegrasDeCalculo(): Observable<any[]> {
  const url = `${this.apiUrlOnFarm}/api/v1/product_rules`;
  return this.http.get<any[]>(url).pipe(
    map(response => response || []),
    catchError(error => {
      console.error('Erro ao carregar regras de cálculo dos produtos:', error);
      return throwError(error);
    })
  );
}

obterFaixasDeCompra(): Observable<any[]> {
  const url = `${this.apiUrlOnFarm}/api/v1/consumption_range`;
  return this.http.get<any[]>(url).pipe(
    map(response => 
      response.map(faixa => ({
        ...faixa,
        description: `Até ${faixa.description.replace(/\D/g, '')}k` // Extrai apenas os números e adiciona "Até" no início e "k" no final
      }))
    ),
    catchError(error => {
      console.error('Erro ao carregar faixas de compra mínima:', error);
      return throwError(error);
    })
  );
}


obterPrecosInoculums(): Observable<any[]> {
  const url = `${this.apiUrlOnFarm}/api/v1/inoculums/prices`;
  return this.http.get<any[]>(url).pipe(
    catchError(error => {
      console.error('Erro ao carregar preços dos inoculums:', error);
      return throwError(error);
    })
  );
}

obterPrecosCultureMedia(): Observable<any[]> {
  const url = `${this.apiUrlOnFarm}/api/v1/culture_media/prices`;
  return this.http.get<any[]>(url).pipe(
    catchError(error => {
      console.error('Erro ao carregar preços dos meios de cultura:', error);
      return throwError(error);
    })
  );
}

obterListaInoculums(): Observable<any[]> {
  const url = `${this.apiUrlOnFarm}/api/v1/inoculum`;
  return this.http.get<any[]>(url).pipe(
    catchError(error => {
      console.error('Erro ao buscar lista de Inoculums:', error);
      return throwError(error);
    })
  );
}

obterMeiosDeCultura(): Observable<any[]> {
  const url = `${this.apiUrlOnFarm}/api/v1/culture_media`;
  return this.http.get<any[]>(url).pipe(
    map(response => {
      console.log('Resposta da API (Meios de Cultura):', response);
      return response; // Garante que é um array
    }),
    catchError(error => {
      console.error('Erro ao buscar meios de cultura:', error);
      return throwError(error);
    })
  );
}


}



