import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));



export const sortByProperty = (property: string) => {
  return function (a, b) {
    if (b[property] < a[property]) {
      return 1;
    } else if (b[property] > a[property]) {
      return -1;
    }
    return 0;
  };
};
