import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'app/core/services/auth/user.service';
import { User } from 'app/core/models/auth/user.model';
import { UsersService } from 'app/core/services/admin/users.service';
import {
  filter,
  tap,
  takeUntil,
  debounceTime,
  map,
  delay,
  take,
} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
@Component({
  selector: 'user-search',
  templateUrl: './user-search.component.html',
})
export class UserSearchComponent implements OnInit, OnChanges {
 
  @Input() public label: string;
  @Input() public controlName: string;
  @Input() public formGroup: FormGroup;
  @Input() public filterJobtitle: number[];
  @Input() public showNone = false;

  public defaultClass = 'flex-auto gt-xs:pr-3';

  @ViewChild('singleSelectUser') singleSelectUser: MatSelect;

  public userServerSideFilteringCtrl: FormControl = new FormControl();

 
  public searching: boolean = false;


  public filteredServerSideUsers: ReplaySubject<User[]> = new ReplaySubject<
    User[]
  >(1);


  protected _onDestroy = new Subject<void>();

  protected users: User[] = [];

  constructor(
    private _usersService: UsersService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this._usersService
      .getUsersMemory()
      .pipe(take(1))
      .subscribe((users: User[]) => {
        if (this.filterJobtitle == null) {
          this.users = users;
        } else {
          this.users = users.filter(u =>
            this.filterJobtitle.includes(parseInt(u.job_type, 10))
          );
        }

        this.filteredServerSideUsers.next(this.users.slice());

        this.setInitialValue();
      });

   
    this.userServerSideFilteringCtrl.valueChanges
      .pipe(
      
        tap(() => {
          this.searching = true;
        }),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.users) {
            return [];
          }
         
          search = search.toLowerCase();
          const filtered = this.users.filter(
            user =>
              search == '' ||
              user.firstname.toLowerCase().includes(search) ||
              user.lastname?.toLowerCase().includes(search) ||
              (
                user.firstname?.toLowerCase() +
                ' ' +
                user.lastname?.toLowerCase()
              ).includes(search)
          );
          return filtered;
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        filteredUsers => {
          this.searching = false;
          this.filteredServerSideUsers.next(filteredUsers);
        },
        error => {
       
          this.searching = false;
       
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.filterJobtitle?.currentValue) {
      this.filterJobtitle = changes.filterJobtitle.currentValue;
      this._usersService
        .getUsersMemory()
        .pipe(take(1))
        .subscribe((users: User[]) => {
          this.users = this.filterJobtitle
            ? users.filter(u =>
                this.filterJobtitle.includes(parseInt(u.job_type, 10))
              )
            : users;
          this.filteredServerSideUsers.next(this.users.slice());
        });
    }
  }


  protected setInitialValue(): void {
    this.filteredServerSideUsers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
    
      });
  }

  public getImage(photo): any {
    return this._userService.getImage(photo);
  }

  public onSelect(value) {}
}
