<div class=" home ">
  <div *ngIf="loading" class="loading-overlay">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
  </div>
  <div class="header">
  <div class="title"> Pedido De Vendas <br>
 
     
      <div  class="title-cabecalho">
           Pedidos Aprovados
        </div>
</div>

<button
routerLink="/sales"
class="px-4 py-2 border border-gray-200 bg-slate-100 hover:bg-slate-200 rounded-full font-bold text-gray-600">
Voltar
</button>

  </div>
  <div class=" header-content">
    <div class="content">
      <mat-form-field class="campos" appearance="outline">
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
        <mat-label class="label">Cliente</mat-label>
     
        <input type="text" matInput [formControl]="pedidoControl" (input)="filtrarPedidosPorCliente()">
      </mat-form-field>


      <div class="table-vendas" appearance="">
        <table>
          <thead>
            <tr>
              <th>N Cliente</th>
              <th>
                <div class="tab-arrow">
                  <button mat-icon-button (click)="ordenarPorCardName()">
                    <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z"/>
                      </svg>
                    </mat-icon>
                  </button>  
                  <span>Cliente</span>
                </div>
              </th>
                    <th>
                    <div class="tab-arrow">
                      <button mat-icon-button (click)="ordenarPorData()">
                        <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">
                          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                            <path d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z"/>
                          </svg>
                        </mat-icon>
                      </button>  
                      <span>Data Venda</span>
                    </div>
                  </th>
              <th>
                <div class="tab-arrow">
                  <button mat-icon-button (click)="ordenarPorDocNum()">
                    <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z"/>
                      </svg>
                    </mat-icon>
                  </button>  
                  <span>N Pedido</span>
                </div>
              </th>
                <th>Status</th>
            
            </tr>
          </thead>
          <tbody>
            
            <tr *ngFor="let pedido of pedidosFiltrados" (click)="selecionarLinha(pedido)"  [class.selected]="linhasSelecionadas === pedido">
              <td>{{ pedido.CardCode }}</td>
              <td>{{  pedido.CardName }}</td>
              <td>{{  pedido.DocDate| date:'dd/MM/yyyy'  }}</td>
              <td>{{  pedido.DocNum }}</td>
              <td [style.color]="getStatusColor(pedido.DocStatus,pedido.CANCELED)" >
                {{ getStatus(pedido.DocStatus, pedido.CANCELED) }}</td>
             
            </tr>
          </tbody>
        </table>
      </div>

  </div>
