import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Item } from '../Types';
import { FormControl,FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SalesService } from '../sales.service';
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';
import { UserService,User } from 'app/core/services/auth/user.service';
import { Console } from 'console';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-sales-dialog',
  templateUrl: './sales-dialog.component.html',
  styleUrls: ['./sales-dialog.component.scss']
})
export class SalesDialogComponent implements OnInit {



  codClienteControl=new FormControl();
  cpfControl = new FormControl();
  telefoneControl = new FormControl();
  fazendaControl = new FormControl();
  enderecoControl=new FormControl();
  meioPagamentoControl=new FormControl();
  codPedidoControl=new FormControl();
  codControl=new FormControl();
  clienteControl=new FormControl();
  localEntregaControl=new FormControl();
  freteControl=new FormControl();
  observacoescadControl=new FormControl();
  textoControl=new FormControl();
  pagamentoControl=new FormControl();
  inscricaoControl=new FormControl();
  dataVencimentoControl=new FormControl();
  itemControl = new FormControl();
  
  produtoControl=new FormControl();
  
  vendedorControl=new FormControl();
  
  CdEntregaControl=new FormControl();
  cliente:any = null;

  formaspagamento:any[]=[];
  clientes:any[]=[];
  clientesFiltrados:any[]=[];
  observacoesControl=new FormControl();
  numeroOCControl=new FormControl();
  quantidadeControl=new FormControl();
  descontoControl=new FormControl();
  aplicacaoControl = new FormControl();
  nItemControl = new FormControl();
  descricaoProdutoControl = new FormControl();
  precoUnitarioControl = new FormControl();
  dataEntregaControl=new FormControl();
  culturaControl=new FormControl();
  inscricaoMunicipalControl=new FormControl();
  condPagamentoControl=new FormControl();
  loading: boolean = false;
  filtroForm: FormGroup;
  vendedoresFiltrados:any[]=[];
  vendedores:any[]=[];
  linhasSelecionadas:  any;
 dadosTabela:any[]=[];
 cardcodeSelecionado: number | null = null;
 SlpCodeSelecionado: number | null = null;
 PayMethCodSelecionado:number | null = null;
 vendedor:any=null;
 formapagamento:any=null;
condicaopagamento:any=null;
 filteredVendedores:any;
 filteredClientes: any;
 currentUser: User;
 isClient = false;
 isComercial = false;
 isAdmin = false;
 filteredformaspagamento:any;
 formaspagamentoFiltradas:any[]=[];
 condicaopagamentoFiltradas:any[]=[];
condicoespagamento:any;
fretes: any[] = [];
freteCodigo:any = null;
fretesFiltrados: any[] = [];
   constructor(    public dialogRef: MatDialogRef<SalesDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private changeDetectorRef: ChangeDetectorRef, 
     private salesService:SalesService,
     private currencyPipe: CurrencyPipe,
     private userAuth: UserService) { }
 
     async ngOnInit(): Promise<void> {


    await this.carregarClientes();
    await this.carregarVendedores();
    await this.carregarMetodoPagamento();
    await this.carregarCondicaoPagamento();
    await this.carregarFretes();
    this.clienteControl = new FormControl();
    this.fazendaControl = new FormControl();
    this.telefoneControl = new FormControl();


    this.filtroForm = new FormGroup({
      CardCode: new FormControl(''),
      Nome_do_PN: new FormControl(''),
      CNPJ_CPF: new FormControl(''),
      Telefone: new FormControl(''),
      U_CADF_Fazenda: new FormControl(''),
    });


  this.clienteControl.valueChanges.pipe(
    startWith(''),
    map(value => typeof value === 'string' ? value : value.Nome_do_PN),
    map(nome => {
      const cardCode = this.data.pedido.CardCode || '';
      return this._filterClientes(nome, cardCode);
    })
  ).subscribe(clientesFiltrados => {
    this.clientesFiltrados = clientesFiltrados;
  });




    this.filteredformaspagamento = this.pagamentoControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.Descript),
      map(nome => {
        const PayMethodCode = this.data.pedido.PaymentMethod || '';
        return this._filterPagamentos(nome, PayMethodCode);
      })
    );

    this.pagamentoControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.Descript),
      map(nome => {
        const PayMethodCode = this.data.pedido.PaymentMethod || '';
        return this._filterPagamentos(nome, PayMethodCode);
      })
    ).subscribe((formaspagamentoFiltradas: any[]) => {
      this.formaspagamentoFiltradas = formaspagamentoFiltradas;
    });
  this.filteredVendedores = this.vendedorControl.valueChanges.pipe(
    startWith(''), 
    map(value => typeof value === 'string' ? value : value.SlpName),
    map(nome => {
      const salesPersonCode = this.data.pedido.SalesPersonCode || ''; 
      return this._filterVendedores(nome, salesPersonCode);
    })
  );
  this.vendedorControl.valueChanges.pipe(
    startWith(''),
    map(value => typeof value === 'string' ? value : value.SlpName),
    map(nome => {

         
      const salesPersonCode = this.data.pedido.SalesPersonCode || ''; 
      return this._filterVendedores(nome, salesPersonCode);
    })
  ).subscribe((vendedoresFiltrados: any[]) => {
    this.filteredVendedores = vendedoresFiltrados;
  });

  

  if (this.data) {

    this.codClienteControl.setValue(this.data.pedido.CardCode);
    this.clienteControl.setValue(this.data.pedido.CardName);

    this.observacoescadControl.setValue(this.data.pedido.Comments)
    this.dataVencimentoControl.setValue(this.data.pedido.DocDueDate);
 
    this.pagamentoControl.setValue(this.data.pedido.PaymentMethod);
    this.condPagamentoControl.setValue(this.data.pedido.PaymentGroupCode);
    this.meioPagamentoControl.setValue(this.data.pedido.U_SOLU_MEIO_PAG);
    this.codPedidoControl.setValue(this.data.pedido.DocEntry);
   
    this.CdEntregaControl.setValue(this.data.pedido.BPLName);
    this.enderecoControl.setValue(this.data.pedido.Address);
    this.inscricaoControl.setValue(this.data.pedido.TaxExtension.inscricao_estadual);
    this.freteControl.setValue(this.data.pedido.TaxExtension.Incoterms);
  
    if (this.data.pedido.DocumentLines && Array.isArray(this.data.pedido.DocumentLines)) {
      this.data.pedido.DocumentLines.forEach((line: any) => {
        this.adicionarItemFromAPI(line);
      });
    }
    
    const salesPersonCode = this.data.pedido.SalesPersonCode;
  const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);
  if (vendedor) {
    this.vendedorControl.setValue(vendedor ? vendedor.SlpName : '');
    
  } 
  const codigoFrete = this.data.pedido.TaxExtension.Incoterms;
  const freteSelecionado = this.fretes.find(frete => frete.CODIGO === codigoFrete);
  
  if (freteSelecionado) {

    this.freteControl.setValue(freteSelecionado ? freteSelecionado.DESCRICAO : '');
  }
  const cardCode = this.data.pedido.CardCode;
  const cliente = this.clientes.find(c => c.CardCode === cardCode);
  if (cliente) {
    this.clienteControl.setValue(cliente.Nome_do_PN);
    this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
    this.telefoneControl.setValue(cliente.Telefone);
    this.localEntregaControl.setValue(cliente.U_CADF_Fazenda);
    this.cpfControl.setValue(cliente.CNPJ_CPF)

  }

    
      
      const CondPgMeth=this.data.pedido.PaymentGroupCode;
      const condicaoPg=this.condicoespagamento.find(c=>c.GroupNum === CondPgMeth);
      if (condicaoPg) {
        this.condPagamentoControl.setValue(condicaoPg? condicaoPg.PymntGroup : '');

      } 

    }


    
 
   }
  async carregarUser(): Promise<void> {
    const user = await this.userAuth.get().toPromise();
      this.currentUser = user;
      this.isClient = user.roles.some(r => r.scope === 'cliente');
      this.isComercial = user.roles.some(r => r.scope === 'comercial');
      this.isAdmin = user.roles.some(r => r.scope ==='admin');


  }
    private _filterClientes(nome: string, cardCode: string): any[] {
      const filterValue = nome.toLowerCase();
      return this.clientes.filter(cliente =>
        (cardCode ? cliente.CardCode.toLowerCase().includes(cardCode.toLowerCase()) : true) &&
        cliente.Nome_do_PN.toLowerCase().includes(filterValue)
      );
    }
   
private _filterVendedores(nome: string, salesPersonCode: string): any[] {
  const filterValue = nome.toLowerCase();
  return this.vendedores.filter(vendedor => 
    (salesPersonCode ? vendedor.SlpCode.toString().toLowerCase().includes(salesPersonCode.toString().toLowerCase()) : true) &&
    vendedor.SlpName.toLowerCase().includes(filterValue)
  );
}


private _filterPagamentos(nome: string, PayMethodCode: string): any[] {
  const filterValue = nome.toLowerCase();
  return this.formaspagamento.filter(option =>
    option.Descript.toLowerCase().includes(filterValue) ||
    option.PayMethCod.toString().includes(filterValue) ||
    option.PayMethCod.toString() === PayMethodCode
  );
}
  

carregarVendedores(): void {
  this.loading = true;
  this.salesService.obterVendedores().subscribe(
    vendedores => {
      this.vendedores = vendedores;
      this.vendedoresFiltrados = [...this.vendedores];
      this.loading = false;

      const salesPersonCode = this.data.pedido.SalesPersonCode;
      this.selecionarVendedorPorCodigo(salesPersonCode);
    },
    error => {
     
      this.loading = false;
    }
  );
}
filtrarVendedores():void{ 
  const {SlpCode,SlpName}=this.filtroForm.value;  
    this.vendedoresFiltrados=this.vendedores.filter(vendedor=>{
    return(SlpCode ? vendedor.SlpCode.toString().toLowerCase().includes(SlpCode.toLowerCase()):true)&&
          (SlpName ?(vendedor.SlpName || ''). toLowerCase().includes(SlpName.toLowerCase()):true);
    })
}

selecionarVendedor(event: any) {
  const selectedSlpCode = event.option.value;
  this.data.pedido.SalesPersonCode = selectedSlpCode;   

  const vendedor = this.vendedores.find(v => v.SlpCode.toString() === selectedSlpCode);
  if (vendedor) {
    this.vendedorControl.setValue(vendedor.SlpName);
    this.SlpCodeSelecionado = vendedor.SlpCode;

  } else {

  }
}



selecionarVendedorPorCodigo(salesPersonCode: string) {
  const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);  
  if (vendedor) {
    this.vendedorControl.setValue(vendedor.SlpName);  
    this.SlpCodeSelecionado = vendedor.SlpCode;       
  }
}

carregarMetodoPagamento(): void {
  this.loading = true;
  this.salesService.obterMetodoPagamento().subscribe(
    formaspagamento => {
      this.formaspagamento = formaspagamento;
      this.formaspagamentoFiltradas = [...this.formaspagamento];
      this.loading = false;
    },
    error => {
    
      this.loading = false;
    }
  );
}

filtrarMetodoPagamento(): void {
  const { FPAGAMENTO } = this.filtroForm.value;
  this.formaspagamentoFiltradas = this.formaspagamento.filter(formapagamento => {
    return (FPAGAMENTO ? formapagamento.FPAGAMENTO.toString().toLowerCase().includes(FPAGAMENTO.toLowerCase()) : true) 
          
  });
}
selecionarMetodoPagamento(event: any) {
  const PayMethodCodeSelecionado = event.option.value;
  this.data.pedido.PaymentMethod = PayMethodCodeSelecionado;

  const formapagamento = this.formaspagamento.find(f => f.PayMethCod.toString() === PayMethodCodeSelecionado);
  if (formapagamento) {
    this.pagamentoControl.setValue(formapagamento.Descript);
    this.PayMethCodSelecionado = formapagamento.PayMethCod;
 
  } 
}
selecionarFormaPagamentoPorCodigo(PayMethCod: string) {
  const formapagamento = this.formaspagamento.find(f => f.PayMethCod === PayMethCod);
  if (formapagamento) {
    this.pagamentoControl.setValue(formapagamento.Descript);
    this.PayMethCodSelecionado = formapagamento.PayMethCod;
  }
}

carregarFretes(): void {
  this.loading = true;
  this.salesService.carregarFrete().subscribe(
    fretes => {
      this.fretes = fretes;
      this.fretesFiltrados = [...this.fretes];
      this.loading = false;
      const freteCod = this.data.pedido.TaxExtension.Incoterms;
      this.selecionarFrete(freteCod);
    },
    error => {
      console.error('Erro ao carregar lista de fretes: ', error);
      this.loading = false;
    }
  );
}


filtrarFretes(): void {
  const {CODIGO, DESCRICAO } = this.filtroForm.value;

  this.fretesFiltrados = this.fretes.filter(frete => {
    return (
      (CODIGO ? frete.CODIGO.toString().toLowerCase().includes(CODIGO.toLowerCase()) : true) &&
      (DESCRICAO ? (frete.DESCRICAO || '').toLowerCase().includes(DESCRICAO.toLowerCase()) : true)
    );
  });
}
selecionarFrete(frete:any): void {
  const codigoFreteSelecionado = this.data.pedido.TaxExtension.Incoterms; 
  const freteSelecionado = this.fretes.find(frete => frete.CODIGO === codigoFreteSelecionado); 

  if (freteSelecionado) {
    this.freteControl.setValue(freteSelecionado.DESCRICAO); 
    this.freteCodigo = freteSelecionado.CODIGO; 

  }
}

carregarCondicaoPagamento(): void {
  this.loading = true;
  this.salesService.obterCondicaoPagamento().subscribe(
    condicoespagamento => {
      this.condicoespagamento = condicoespagamento;
      this.condicaopagamentoFiltradas = [...this.condicoespagamento];
      this.loading = false;
      const PayCondCode = this.data.pedido.PaymentGroupCode;
      this.selecionarCondicaoPagamentoPorCodigo(PayCondCode);
    },
    error => {
      this.loading = false;
    }
  );
}


filtrarCondicoesPagamento(): void {
  const {GroupNum, PymntGroup } = this.filtroForm.value;
  this.condicaopagamentoFiltradas = this.condicoespagamento.filter(condicaopagamento => {
    return (GroupNum ? condicaopagamento.PayMethCod.toString().toLowerCase().includes(GroupNum.toLowerCase()) : true) &&
           (PymntGroup ? (condicaopagamento.PymntGroup || '').toLowerCase().includes(PymntGroup.toLowerCase()) : true);
  });
}

selecionarCondicaoPagamento(event: any) {
  const PayMethodCodeSelecionado = event.option.value;
  this.data.pedido.PaymentGroupCode = PayMethodCodeSelecionado;

  const condicaopagamento = this.condicoespagamento.find(c => c.GroupNum.toString() === PayMethodCodeSelecionado);
  if (condicaopagamento) {
    this.condPagamentoControl.setValue(condicaopagamento.PymntGroup);
    this.PayMethCodSelecionado = condicaopagamento.PayMethCod;
  } 
}
selecionarCondicaoPagamentoPorCodigo(PayMethCod: string) {
  const condicaopagamento = this.condicoespagamento.find(c => c.GroupNum ===PayMethCod);
  if (condicaopagamento) {
    this.condPagamentoControl.setValue(condicaopagamento.PymntGroup);
    this.PayMethCodSelecionado = condicaopagamento.PayMethCod;

  } 
}

async carregarClientes(): Promise<void> {
  try {
    const clientes = await this.salesService.obterClientes().toPromise();
    this.clientes = clientes;
  
    const cardCode = this.data.pedido.CardCode;
    if (cardCode) {
      this.selecionarClientePorCardCode(cardCode);
    } else {
  
    }
  } catch (error) {

  }
} 

filtrarClientes(): void {
  const { U_CADF_Fazenda, Nome_do_PN, CNPJ_CPF, Telefone, CardCode} = this.filtroForm.value;
  this.clientesFiltrados = this.clientes.filter(cliente => {
    return (U_CADF_Fazenda ? cliente.U_CADF_Fazenda.toLowerCase().includes(U_CADF_Fazenda.toLowerCase()) : true) &&
      (Nome_do_PN ? (cliente.Nome_do_PN || '').toLowerCase().includes(Nome_do_PN.toLowerCase()) : true) &&
      (CardCode ? (cliente.CardCode|| '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
      (CNPJ_CPF ? (cliente.CNPJ_CPF || '').toLowerCase().includes(CNPJ_CPF.toLowerCase()) : true) &&
      (Telefone ? (cliente.Telefone || '').toLowerCase().includes(Telefone.toLowerCase()) : true);
  });

}

selecionarCliente(event: any) {
  const cardcodeSelecionado = event.option.value;
  this.data.pedido.CardCode = cardcodeSelecionado;
  const cliente = this.clientes.find(c => c.CardCode === cardcodeSelecionado);
  if (cliente) {
    this.clienteControl.setValue(cliente.Nome_do_PN);
    this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
    this.telefoneControl.setValue(cliente.Telefone);
    this.cpfControl.setValue(cliente.CNPJ_CPF);
    this.cardcodeSelecionado = cliente.CardCode;

  } else {

  }
}

selecionarClientePorCardCode(cardCode: string) {
  const cliente = this.clientes.find(c => c.CardCode === cardCode);
  if (cliente) {
    this.filtroForm.patchValue({
      CardCode: cliente.CardCode,
      Nome_do_PN: cliente.Nome_do_PN,
      CNPJ_CPF: cliente.CNPJ_CPF,
      Telefone: cliente.Telefone,
      U_CADF_Fazenda: cliente.U_CADF_Fazenda,
    });
    this.clienteControl.setValue(cliente.Nome_do_PN)
    this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
    this.localEntregaControl.setValue(cliente.U_CADF_Fazenda);
    this.telefoneControl.setValue(cliente.Telefone);
    this.cpfControl.setValue(cliente.CNPJ_CPF);
    this.cardcodeSelecionado = cliente.CardCode;
  } else {

  }
}

adicionarItemFromAPI(data: any): void {

  const nItem = data.ItemCode;
  const descricaoProduto = data.ItemDescription;
  const quantidade = data.Quantity;
  const quantidadeemaberto=data.RemainingOpenQuantity;
  const desconto = data.DiscountPercent;
  const aplicacao = data.U_SOLU_TP_Aplicacao;
  const cod_venda=data.Usage;
  const cultura = data.U_SOLU_Cultura;
  const precoUnitario = `${data.Currency} ${data.Price}`;
  const precoDesconto=`${data.Currency} ${data.Price}`;
  const total=`${data.Currency} ${data.LineTotal}`
  const dataEntrega = data.ShipDate;
  const observacoes = data.observacoes;
  const totalvalue= parseFloat(total.replace(/[^\d.-]/g, ''));
  const totalformat=this.currencyPipe.transform(totalvalue , 'BRL', 'symbol', '1.2-2') ;
  const valorNumerico = parseFloat(precoUnitario.replace(/[^\d.-]/g, ''));
  const value=parseFloat(precoDesconto.replace(/[^\d.-]/g, ''));
  const valueFormat= this.currencyPipe.transform(value , 'BRL', 'symbol', '1.2-2') ;
  const precoUnitarioFormatado = this.currencyPipe.transform(valorNumerico, 'BRL', 'symbol', '1.2-2') ;


  const item: Item = {
  
    nItem: nItem,
    descricaoProduto: descricaoProduto,
    quantidade: quantidade,
    desconto: desconto,
    aplicacao: aplicacao,
    cultura: cultura,
    precoUnitario:precoUnitarioFormatado,
    precoDesconto:valueFormat,
    total:totalformat,
    data: dataEntrega,
    observacoes: observacoes,
    cod_venda:cod_venda,
    numeroOC: 0
  };

  this.dadosTabela.push(item);

}

   cancel(): void {
    this.dialogRef.close();
  }

  salvarAlteracoes(): void {
  
    this.dialogRef.close();
  }

}
