import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable,throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
@Injectable({
  providedIn: 'root'
})
export class OrdersOpenService {

  apiUrl: string = '';
  
  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiSap;
   }



   pedidosDetails(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaOrcamentos').pipe(
      map(response => {
        return response.listaOrcamentos || [];
      }),
      catchError(error => {
        console.error('Erro ao carregar orçamentos:', error);
        return throwError(error);
      })
    );
  }
  carregarItems(): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/api/consulta/listaItems`).pipe(
      map(response => response.listaItems)
    );
  }

  orcamentoCancelar(DocEntry: number): Observable<any> {
    const url = `${this.apiUrl}/api/orcamentos/cancelar/${DocEntry}`;
    const requestBody = { DocEntry }; 
    return this.http.post<any>(url, requestBody);
  }

  carregarMeioCultura(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/consulta/listaMeioCultura`)
      .pipe(
        catchError(error => {
          console.error('Erro ao carregar culturas:', error);
          return throwError(error);
        })
      );
    
  }

   envioPedido(requestBody:any):Observable<any[]>{
    const url = `${this.apiUrl}/api/orders/cadastrar `;
    return this.http.post<any>(url,requestBody);
   }
   
   obterVendedores():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaVendedores').pipe(
      map(response=>{
      return response.listaVendedores || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar lista de vendedores :', error);
      return throwError(error);
    })
  );
   }

   buscarDataComercial(parameters:any,emailVendedor:any): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/listaOrcamentos?emailVendedor='${emailVendedor}'`;

    return this.http.get<any>(url).pipe(
      map(response => {
        return response.listaOrcamentos || [];
      })
    );
    }
    
   buscarPedidoDataComercial(parameters:any,emailVendedor:any): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/analiticoPV?EMAIL_VENDEDOR='${emailVendedor}'`;
    return this.http.get<any>(url).pipe(
      map(response => {
        return response.analiticoPV || [];
      })
    );
    }

    buscarPedidoComercial(emailVendedor:any): Observable<any[]> {
      const url = `${this.apiUrl}/api/consulta/analiticoPV?EMAIL_VENDEDOR='${emailVendedor}'`;
      return this.http.get<any>(url).pipe(
        map(response => {
          return response.analiticoPV || [];
        })
      );
      }
  

   carregarCdsEntrega(){
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaFiliais').pipe(
      map(response=>{
      return response.listaFiliais || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar lista de vendedores :', error);
      return throwError(error);
    })
  );
  }

  obterClientes():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaClientes').pipe(
      map(response=>{
      return response.listaClientes || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar lista de clientes:', error);
      return throwError(error);
    })
  );
  }

  orcamentoDetails(DocEntry: number): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/orcamento/${DocEntry}`;
    return this.http.get<any>(url);
  }

  obterMetodoPagamento():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaFormaPagamento').pipe(map(response=>{
      return response.listaFormaPagamento || [];
  
    }), 
    catchError(error => {
      console.error('Erro ao carregar formas de pagamento :', error);
      return throwError(error);
    })
  );
  
  }

  condicaoPagamento():Observable<any[]>{
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaCondicaoPagamento').pipe(
      map(response=>{
      return response.listaCondicaoPagamento || [];
    }), 
    catchError(error => {
      console.error('Erro ao carregar condições de pagamento :', error);
      return throwError(error);
    })
  );
  
  }

  filtrarPedidoPorVendedor(emailVendedor: any): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/listaPedidosCompras?emailVendedor='${emailVendedor}'`;
    return this.http.get<any>(url).pipe(
      map(response => {
        return response.listaPedidos || [];
      })
      
    );
  }
  


  envioEmail(DocEntry:string): Observable<any> {
    const url = `${this.apiUrl}/api/consulta/pedido/${DocEntry}/pdf`;
   return this.http.get<any>(url);

  }

  filtrarPorNomeUsuario(emailVendedor: string): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/listaOrcamentos?emailVendedor='${emailVendedor}'`;
    return this.http.get<any>(url).pipe(
      map(response => {
        return response.listaOrcamentos || [];
      })
      
    );
  }

  filtrarPorCardCode(CardCode: any): Observable<any[]> {
    if (!CardCode) {
      // Se o CardCode for inválido (null, undefined, ou ''), retorna um Observable vazio
      return of([]);
    }
  
    const url = `${this.apiUrl}/api/consulta/listaOrcamentos?CardCode='${CardCode}'`;
    return this.http.get<any>(url).pipe(
      map(response => {
        return response.listaOrcamentos || [];
      }),
      catchError(error => {
        console.error('Erro ao filtrar orçamentos por CardCode:', error);
        return throwError(error);
      })
    );
  }


  obterAtCard():Observable<any[]> {
    return this.http.get<any>(this.apiUrl+ '/api/consulta/listaPedidos?ultimo=true').pipe(
      map(response => {
        return response.listaPedidos || [];
      }),
      catchError(error => {
        console.error('Erro ao carregar orçamentos:', error);
        return throwError(error);
      })
    );
  }

  editarOrcamento(requestBody: any, docEntry: number): Observable<any> {
    const url = `${this.apiUrl}/api/orcamentos/editar/${docEntry}`;
    return this.http.patch(url, requestBody);
  }
  
 
}