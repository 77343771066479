import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-dialog-error-cancelar',
  templateUrl: './dialog-error-cancelar.component.html',
  styleUrls: ['./dialog-error-cancelar.component.scss']
})
export class DialogErrorCancelarComponent implements OnInit {

  constructor(public dialogRef:MatDialogRef<DialogErrorCancelarComponent>,public dialog:MatDialog) { }

  ngOnInit(): void {
  }
  close(){
    
    this.dialogRef.close(true);
  }

}
