<div class="pedido overflow-y-scroll w-full h-full meu-dialog pt-0 pr-0">
  <div *ngIf="loading" class="loading-overlay">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
  </div>
  <!-- Header -->
  <div
      class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
      <div class="text-lg font-medium">Orçamento de Venda </div>
      <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
          <mat-icon
              class="text-current"
              [svgIcon]="'heroicons_outline:x'"></mat-icon>
      </button>
  </div>

      <div class="header">
 
      <div  class="title-cabecalho">
        Cabeçalho
      
  
  
        <mat-form-field class="campos_cod" appearance="outline">
          <mat-label class="label">Cod Pedido</mat-label>
          <input type="text" matInput [formControl]="codPedidoControl"  [readonly]="codControl.value !== null"  >
         
        </mat-form-field>
    
    </div>
         <!--codigo que autocompleta durante digitação-->
    </div>
  <div class=" cadastro">
    <div class="contents1">
      <!--codigo que autocompleta durante digitação-->
      <mat-form-field class="campos_cod" appearance="outline">
        <mat-label class="label">Cod Cliente</mat-label>
        <input type="text" matInput [formControl]="codClienteControl"  [readonly]="codClienteControl.value !== null"   >
        
      </mat-form-field>
  
      <mat-form-field class="campos" appearance="outline">
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
        <mat-label class="label">Cliente</mat-label>
        
        <!-- Campo de entrada para autocompletar -->
        <input type="text" matInput [formControl]="clienteControl" [readonly]="clienteControl.value !== null">
        
        <!-- Autocomplete dropdown -->
        
      </mat-form-field>
      <mat-form-field
      class="fuse-mat-no-subscript w-full sm:flex-auto sm:max-w-40 mr-2"  appearance="outline">
      <mat-label>Data de Vencimento</mat-label>
      <input
          matInput 
          [formControl]="dataVencimentoControl"
          [matDatepicker]="pickerScheduled"/>
      <mat-datepicker-toggle
          matSuffix
          [for]="pickerScheduled"></mat-datepicker-toggle>
      <mat-datepicker #pickerScheduled></mat-datepicker>
  </mat-form-field>
      </div>
      <div class="contents1">
  <mat-form-field class="campos_cod" appearance="outline">
    <mat-label class="label">CPF/CNPJ</mat-label>
    <input type="text" matInput [formControl]="cpfControl"   [readonly]="!editMode">
   
  </mat-form-field>
  
  <mat-form-field class="campos_tel" appearance="outline">
    <mat-label class="label">Telefone</mat-label>
    <input type="text" matInput [formControl]="telefoneControl"    [readonly]="!editMode">
  
  </mat-form-field>
  <mat-form-field class="campos" appearance="outline">
    <mat-label class="label">Fazenda</mat-label>
    <input type="text" matInput [formControl]="fazendaControl"  [readonly]="fazendaControl.value !== null">
  
  </mat-form-field>

  <mat-form-field class="campos" appearance="outline">
    <mat-label class="label">Inscrição Estadual</mat-label>
    <input type="text" matInput [formControl]="inscricaoControl" [readonly]="!editMode">

  </mat-form-field>
  
  </div>
  
  
              <div class="contents3">
                <mat-form-field class="campos" appearance="outline">
                  <mat-label class="label">Inscrição Municipal</mat-label>
                  <input type="text" matInput [formControl]="inscricaoMunicipalControl"  [readonly]="!editMode">
                  
                </mat-form-field>
            <mat-form-field class="example-container accent" appearance="outline">
              <mat-label>Frete</mat-label>
              <input type="text" matInput   [formControl]="freteControl" [readonly]="freteControl.value !== null">
            </mat-form-field>
  
              <mat-form-field class="campos" appearance="outline">
                <mat-label class="label">Endereço</mat-label>
                <input type="text" matInput [formControl]="enderecoControl"  [readonly]="enderecoControl.value !== null">

              </mat-form-field>
                
              
              
              <mat-form-field class="campos-local" appearance="outline">
                <mat-label class="label">Local de Entrega</mat-label>
                <!--Preenchido Automatico pela Busca de Cliente-->
                <input type="text" matInput [formControl]="fazendaControl"  [readonly]="fazendaControl.value !== null">
               
              
              </mat-form-field>
 
                    
              </div>    
    
              <div class="contents34">
                <mat-form-field class="example-container cdEntrega accent" appearance="outline">
                  <mat-label>Cd.Entrega</mat-label>
                  <input type="text" matInput [formControl]="CdEntregaControl" [readonly]="CdEntregaControl.value !== null">
                  
                </mat-form-field>
  
                <mat-form-field class="campo_vendedor" appearance="outline">
                  <mat-label class="label">Vendedor</mat-label>
                  <input type="text" matInput [formControl]="vendedorControl" [readonly]="vendedorControl.value !== null" >
          

          </mat-form-field>
          
       <mat-form-field class="campo-fin" appearance="outline">
        <mat-label class="label">Condições de Pagamento</mat-label>
  
        <!--Preenchido Automatico pela Busca de Cliente-->
  
        <input type="text" matInput [formControl]="pagamentoControl" [readonly]="pagamentoControl.value !== null"  >
     
      </mat-form-field>
   
             <mat-form-field class="campo-fin" appearance="outline">
               <mat-label class="label">Forma de Pagamento</mat-label>

  
               <input type="text" matInput [formControl]="formaPagamentoControl" [readonly]="formaPagamentoControl.value !== null" >

             </mat-form-field>
  
      </div>
          
        <div class="contents4">
          
          <mat-form-field class="camposObs" appearance="outline">
            <mat-label class="label">Observações</mat-label>
            <input matInput placeholder="Placeholder" [formControl]="observacoescadControl" [readonly]="!editMode">
          </mat-form-field>
          
  
  
        </div>
              
  </div>
   
    
  <div class="header-itens">
    
</div>

        <div class="header-itens">
          <div class="title1">Itens do Pedido <br></div>
          
        </div>
          <div class="itens">

            <div class="table-vendas" appearance="">
              <table>
                <thead>
                  <tr>
                    <th>N Item</th>
                    <th>Cod Item</th>
                    <th>Descricao Do Produto</th>
                    <th>Quantidade</th>
                    <th>Desconto</th>
                    <th>Aplicação</th>
                    <th>Cultura</th>
                    <th>Preço Unit.</th>
                    <th>Data de Entrega</th>
                    <th>Cod venda</th>
                    <!-- Adicione mais colunas conforme necessário -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dadosTabela; let i = index" (click)="selecionarLinha(item)"   >
                    <td>{{ item.nindice }}</td>
                    <td>{{ item.nItem }}</td>
                    <td>{{ item.descricaoProduto }}</td>
                    <td>{{ item.quantidade  }}</td>
                    <td>{{ item.desconto| number: '1.0-0' }}%</td>
                    <td>{{ item.aplicacao }}</td>
                    <td>{{item.cultura}}</td>
                    <td>{{ item.precoUnitario}}</td>
                    <td>{{item.data | date:'dd/MM/yyyy'  }}</td>
                    <td>{{ item.cod_venda }}</td>
                  </tr>
                    <!-- Exiba mais colunas conforme necessário -->
                    <tr *ngIf="dadosTabela.length === 0">
                      <td colspan="2">Nenhum dado disponível</td>
                </tbody>
  
              </table>
            </div>
  
  
            <div class="button">
              <button mat-flat-button="" 
              class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-'text-green-200 bg-green-350 dark:text-green-50 dark:bg-green-500' ng-star-inserted" 
              ng-reflect-color="primary"  (click)="enviarPedido()">
                <span class="mat-button-wrapper">
              
                    
                    <span class="ml-2 mr-1 text-white">
                       Aprovar</span>
                </span>
                <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
                      
                </span>
                <span class="mat-button-focus-overlay">
                </span>
              </button>
              
                <button  mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" (click)="modificarOrcamento(selecionado)"   >
                  <span class="mat-button-wrapper">
                    <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:plus" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline">        
                      <svg
                        id="edit-2"
                        x="912"
                        y="144"
                        viewBox="0 0 24 24">
                        <path
                            fill="none"
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            stroke-width="2"
                            stroke="currentColor"
                            d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>
                    </svg>
                        </mat-icon>
                        <span class="ml-2 mr-1">Modificar</span>
                    </span>
                    <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
                    </span>
                    <span class="mat-button-focus-overlay">
                    </span>
                  </button>
                 
                <button mat-flat-button="" class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary ng-star-inserted" ng-reflect-color="primary" style="background-color: #6c757d; color: white;" (click)="cancelarOrcamento(codPedidoControl.value)"   [class.selected]="orcamentoSelecionado === codPedidoControl.value"  >
                  <span class="mat-button-wrapper">
                      <span class="ml-2 mr-1">Cancelar Pedido</span>
                  </span>
                  <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
                  </span>
                  <span class="mat-button-focus-overlay">
                  </span>
              </button>
            </div>
            
          </div>
  </div>
  
