import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { CompactLayoutModule } from 'app/layout/layouts/vertical/compact/compact.module';
import { SharedModule } from 'app/shared/shared.module';
import { FuseAlertModule } from '@fuse/components/alert';

const layoutModules = [

  EmptyLayoutModule,

  CompactLayoutModule,
];

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    MatIconModule,
    MatTooltipModule,
    FuseDrawerModule,
    SharedModule,
    FuseAlertModule,
    ...layoutModules,
  ],
  exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule {}
