
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';
import { DateTime } from 'aws-sdk/clients/devicefarm';
import { TipoFrete,TipoAplicacao,TipoVenda,Cadastro, Item } from '../../Types';
import { MAT_DIALOG_DATA, MatDialogClose,MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { Dialog } from '@angular/cdk/dialog';
import { AfterViewInit, Component, OnInit, ViewChild,Input} from '@angular/core';
import { MatSidenav, MatSidenavContainer} from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SalesService } from '../../sales.service';
@Component({
  selector: 'app-closed-sales-order',
  templateUrl: './closed-sales-order.component.html',
  styleUrls: ['./closed-sales-order.component.scss']
})
export class ClosedSalesOrderComponent implements OnInit {

  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
    
  clientes:any[]=[];
  clientesFiltrados:any[]=[];
  filtroForm: FormGroup;
  apiUrl: string = ''; 
  loading: boolean = false; 
  error: boolean = false; 
  
produto:any = null;
cliente:any = null;
vendedor:any=null;
cdEntrega:any = null;
dataVencimentoControl=new FormControl();
codClienteControl=new FormControl();
cpfControl = new FormControl();
telefoneControl = new FormControl();
fazendaControl = new FormControl();
enderecoControl=new FormControl();

codPedidoControl=new FormControl();
codControl=new FormControl();
clienteControl=new FormControl();
localEntregaControl=new FormControl();
freteControl=new FormControl('ENTREGA');
observacoescadControl=new FormControl();
textoControl=new FormControl();
pagamentoControl=new FormControl();
inscricaoControl=new FormControl();

itemControl = new FormControl();

produtoControl=new FormControl();

vendedorControl=new FormControl();

CdEntregaControl=new FormControl();

observacoesControl=new FormControl();
numeroOCControl=new FormControl();
quantidadeControl=new FormControl();
descontoControl=new FormControl();
aplicacaoControl = new FormControl();
nItemControl = new FormControl();
descricaoProdutoControl = new FormControl();
precoUnitarioControl = new FormControl();
dataEntregaControl=new FormControl();
culturaControl=new FormControl();
inscricaoMunicipalControl=new FormControl();
items: any[] = []; 
itemsFiltrados: any[] = []; 

vendedores:any[]=[];
vendedoresFiltrados:any[]=[];
cdsEntrega:any[]=[];
cdsEntregaFiltrados:any[]=[];

filteredItems: any;
filteredClientes: any;
filteredVendedores:any;
filteredCdEntrega:any;

culturasFiltradas:any[]=[];
culturas:any[]=[];

linhasSelecionadas:  any;
dadosTabela:any[]=[];

cabecalho:any[]=[];

contadorPedido: number = 1;
SlpCodeSelecionado: number | null = null;
cardcodeSelecionado: number | null = null;

formapagamento:any=null;
PayMethCodSelecionado:number | null = null;
filteredformaspagamento:any;
formaspagamentoFiltradas:any[]=[];
formaspagamento:any[]=[];
editarAtivo: boolean = false;

    ngAfterViewInit() {
      this.sidenavContainer.scrollable.elementScrolled().subscribe(() =>Dialog);
    }
  constructor(
    public matDialogRef: MatDialogRef<any>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectorRef: ChangeDetectorRef,
    private http:HttpClient, private salesService:SalesService)
    {
       
      this.apiUrl = environment.apiSap ;

        let items: Item[] = [];
        
       let cabecalho:Cadastro[]=[];

    }

    async ngOnInit(): Promise<void> {

      await this.carregarClientes();
      await this.carregarItems();
      await this.carregarVendedores();
      await this.carregarCdsEntrega();
      await this.carregarMeioCultura();
      await this.carregarMetodoPagamento();

    

      this.clienteControl = new FormControl();
      this.fazendaControl = new FormControl();
      this.telefoneControl = new FormControl();
      this.localEntregaControl = new FormControl();


    this.filtroForm = new FormGroup({
      CardCode: new FormControl(''),
      Nome_do_PN: new FormControl(''),
      CNPJ_CPF: new FormControl(''),
      Telefone: new FormControl(''),
      U_CADF_Fazenda: new FormControl(''),
    });
  
      
      this.filteredformaspagamento = this.pagamentoControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Descript),
        map(nome => {
          const PayMethodCode = this.data.pedido.PaymentMethod || '';
          return this._filterPagamentos(nome, PayMethodCode);
        })
      );
  
      this.pagamentoControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Descript),
        map(nome => {
          const PayMethodCode = this.data.pedido.PaymentMethod || '';
          return this._filterPagamentos(nome, PayMethodCode);
        })
      ).subscribe((formaspagamentoFiltradas: any[]) => {
        this.formaspagamentoFiltradas = formaspagamentoFiltradas;
      });

      this.filteredClientes = this.clienteControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Nome_do_PN),
        map(nome => {
          const cardCode = this.data.pedido.CardCode || ''; 
          return this._filterClientes(nome, cardCode);
        })
      );
  
      this.clienteControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Nome_do_PN),
        map(nome => {
          const cardCode = this.data.pedido.CardCode || ''; 
          return this._filterClientes(nome, cardCode);
        })
      ).subscribe((clientesFiltrados: any[]) => {
        this.clientesFiltrados = clientesFiltrados;
      });

  
      this.filteredItems = this.itemControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.ItemName),
        map(nome => nome ? this._filterItems(nome) : this.items.slice()) 
      );
      this.filteredVendedores = this.vendedorControl.valueChanges.pipe(
        startWith(''), 
        map(value => typeof value === 'string' ? value : value.SlpName),
        map(nome => {
          const salesPersonCode = this.data.orcamento.SalesPersonCode || ''; 
          return this._filterVendedores(nome, salesPersonCode);
        })
      );
      
    
    
      this.nItemControl.valueChanges.subscribe(value => {
      
        const produtoSelecionado = this.itemsFiltrados.find(produto => produto.nItem === value);
        if (produtoSelecionado) {
          this.descricaoProdutoControl.setValue(produtoSelecionado.DescricaoDoProduto);
        }
      });

      this.descricaoProdutoControl.valueChanges.subscribe(value => {
        const produtoSelecionado = this.itemsFiltrados.find(produto => produto.DescricaoDoProduto === value);
        if (produtoSelecionado) {
          this.nItemControl.setValue(produtoSelecionado.ItemCode);
        }
      });
    
      this.CdEntregaControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.BPLName),
        map(nome => nome ? this._filterCdsEntrega(nome) : this.cdsEntrega.slice())
      ).subscribe((cdsEntregaFiltrados: any[]) => {
        this.filteredCdEntrega = cdsEntregaFiltrados;
      });


 
        this.vendedorControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.SlpName),
          map(nome => {
    

            const salesPersonCode = this.data.orcamento.SalesPersonCode || ''; 
            return this._filterVendedores(nome, salesPersonCode);
          })
        ).subscribe((vendedoresFiltrados: any[]) => {
          this.filteredVendedores = vendedoresFiltrados;
        });

        if (this.data) {
        
          this.codClienteControl.setValue(this.data.pedido.CardCode);
          this.clienteControl.setValue(this.data.pedido.CardName);
          this.cpfControl.setValue(this.data.pedido.TaxExtension.CPF);
         
         
          this.dataVencimentoControl.setValue(this.data.pedido.DocDueDate);
          this.codPedidoControl.setValue(this.data.pedido.DocNum);
          this.pagamentoControl.setValue(this.data.pedido.PaymentMethod);
          this.CdEntregaControl.setValue(this.data.pedido.BPLName);
          this.enderecoControl.setValue(this.data.pedido.Address);
          this.inscricaoControl.setValue(this.data.pedido.TaxExtension.inscricao_estadual);
          this.observacoescadControl.setValue(this.data.pedido.Comments);
         

          
          
          if (this.data.pedido.DocumentLines && Array.isArray(this.data.pedido.DocumentLines)) {
            this.data.pedido.DocumentLines.forEach((line: any) => {
              this.adicionarItemFromAPI(line);
            });
          }
          const cardCode = this.data.pedido.CardCode;
          const cliente = this.clientes.find(c=>c.CardCode === cardCode);
          if (cliente) {
            this.clienteControl.setValue(cliente ? cliente.Nome_do_PN: '');
            this.telefoneControl.setValue(cliente? cliente.Telefone: '');
            this.fazendaControl.setValue(cliente? cliente.U_CADF_Fazenda: '');
            this.localEntregaControl.setValue(cliente? cliente.U_CADF_Fazenda: '');
          
          
              }
          const salesPersonCode = this.data.pedido.SalesPersonCode;
          const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);
          if (vendedor) {
            this.vendedorControl.setValue(vendedor ? vendedor.SlpName : '');
            
          } 
          const PayMethCod = this.data.pedido.PaymentMethod;
          const formapagamento = this.formaspagamento.find(f=> f.PayMethCod === PayMethCod);
          if (formapagamento) {
            this.pagamentoControl.setValue(formapagamento? formapagamento.Descript : '');
           
          } 
    
        }
      }

      adicionarItemFromAPI(data: any): void {
     
        const nItem = data.ItemCode;
        const cod_venda=data.Usage;
        const descricaoProduto = data.ItemDescription;
        const quantidade = data.Quantity;
        const quantidadeemaberto=data.RemainingOpenQuantity;
        const desconto = data.DiscountPercent;
        const aplicacao = data.U_SOLU_TP_Aplicacao;
        const cultura = data.U_SOLU_Cultura;
        const precoUnitario = data.Quantity;
        const dataEntrega = data.ShipDate;
        const observacoes = data.observacoes;
      
     
        const item: Item = {
          nItem: nItem,
          descricaoProduto: descricaoProduto,
          quantidade: quantidade,
          quantidadeemaberto:quantidadeemaberto,
          desconto: desconto,
          aplicacao: aplicacao,
          cod_venda:cod_venda,
          cultura: cultura,
          precoUnitario: precoUnitario,
          data: dataEntrega,
          observacoes: observacoes,
          numeroOC: 0
        };
      
       
        this.dadosTabela.push(item);
      }
      

      
      private _filterClientes(nome: string, cardCode: string): any[] {
        const filterValue = nome.toLowerCase();
        return this.clientes.filter(cliente =>
          (cardCode ? cliente.CardCode.toLowerCase().includes(cardCode.toLowerCase()) : true) &&
          cliente.Nome_do_PN.toLowerCase().includes(filterValue)
        );
      }

    private _filterItems(nome: string): any[] {
      const filterValue = nome.toLowerCase();
      return this.items.filter(item => 
        (item.ItemCode.toLowerCase().includes(filterValue) || (item.DescricaoDoProduto || '').toLowerCase().includes(filterValue))
      );
    }
  
    private _filterVendedores(nome: string, salesPersonCode: string): any[] {
      const filterValue = nome.toLowerCase();
      return this.vendedores.filter(vendedor => 
        (salesPersonCode ? vendedor.SlpCode.toString().toLowerCase().includes(salesPersonCode.toString().toLowerCase()) : true) &&
        vendedor.SlpName.toLowerCase().includes(filterValue)
      );
    }

    private _filterCdsEntrega(nome: string): any[] {
      const filterValue = nome.toLowerCase();
      return this.cdsEntrega.filter(cdEntrega => cdEntrega.BPLName.toLowerCase().includes(filterValue));
        
      }
      private _filterPagamentos(nome: string, PayMethodCode: string): any[] {
        const filterValue = nome.toLowerCase();
        return this.formaspagamento.filter(option =>
          option.Descript.toLowerCase().includes(filterValue) ||
          option.PayMethCod.toString().includes(filterValue) ||
          option.PayMethCod.toString() === PayMethodCode
        );
      }
   
   

 
  async carregarClientes():Promise<void> {
    this.loading = true;
    try {
      const clientes = await this.salesService.obterClientes().toPromise();
      this.clientes = clientes;
      this.clientesFiltrados = [...this.clientes];
      this.loading = false;
 
  
      const cardCode = this.data.pedido.CardCode;
  
  
      if (cardCode) {
        this.selecionarClientePorCardCode(cardCode);
      }
    } catch (error) {
     
      this.loading = false;
    }
  }
  
    filtrarClientes(): void {
      const { U_CADF_Fazenda, Nome_do_PN, CNPJ_CPF, Telefone, CardCode} = this.filtroForm.value;
      this.clientesFiltrados = this.clientes.filter(cliente => {
        return (U_CADF_Fazenda ? cliente.U_CADF_Fazenda.toLowerCase().includes(U_CADF_Fazenda.toLowerCase()) : true) &&
          (Nome_do_PN ? (cliente.Nome_do_PN || '').toLowerCase().includes(Nome_do_PN.toLowerCase()) : true) &&
          (CardCode ? (cliente.CardCode|| '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
          (CNPJ_CPF ? (cliente.CNPJ_CPF || '').toLowerCase().includes(CNPJ_CPF.toLowerCase()) : true) &&
          (Telefone ? (cliente.Telefone || '').toLowerCase().includes(Telefone.toLowerCase()) : true);
      });
     
    }
  
  
    selecionarCliente(event: any) {
      const cardcodeSelecionado = event.option.value;
      this.data.pedido.CardCode = cardcodeSelecionado;
  
    
      const cliente = this.clientes.find(c => c.CardCode === cardcodeSelecionado);
     
      if (cliente) {
      
        this.clienteControl.setValue(cliente.Nome_do_PN);
        this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
        this.telefoneControl.setValue(cliente.Telefone);
    
        this.cardcodeSelecionado = cliente.CardCode;
    
      } 
    }
  
  
    selecionarClientePorCardCode(cardCode: string) {
    
      const cleanedCardCode = cardCode.trim().toLowerCase();
    
      const cliente = this.clientes.find(c => {
        const clienteCardCode = c.CardCode.trim().toLowerCase();
       
        return clienteCardCode === cleanedCardCode;
      });
    
      if (cliente) {
     
        this.filtroForm.patchValue({
          CardCode: cliente.CardCode,
          Nome_do_PN: cliente.Nome_do_PN,
          CNPJ_CPF: cliente.CNPJ_CPF,
          Telefone: cliente.Telefone,
          U_CADF_Fazenda: cliente.U_CADF_Fazenda,
        });
        this.clienteControl.setValue(cliente.Nome_do_PN)
        this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
        this.localEntregaControl.setValue(cliente.U_CADF_Fazenda);
        this.telefoneControl.setValue(cliente.Telefone);
        this.cpfControl.setValue(cliente.CNPJ_CPF)
        this.cardcodeSelecionado = cliente.CardCode;
       
      } 
    }

carregarMetodoPagamento(): void {
  this.loading = true;
  this.salesService.obterMetodoPagamento().subscribe(
    formaspagamento => {
      this.formaspagamento = formaspagamento;
      this.formaspagamentoFiltradas = [...this.formaspagamento];
      this.loading = false;
      const PayMethodCode = this.data.pedido.PaymentMethod;
      this.selecionarFormaPagamentoPorCodigo(PayMethodCode);
      
    },
    error => {
    
      this.loading = false;
    }
  );
}


filtrarMetodoPagamento(): void {
  const { PayMethCod, Descript } = this.filtroForm.value;
  this.formaspagamentoFiltradas = this.formaspagamento.filter(formapagamento => {
    return (PayMethCod ? formapagamento.PayMethCod.toString().toLowerCase().includes(PayMethCod.toLowerCase()) : true) &&
           (Descript ? (formapagamento.Descript || '').toLowerCase().includes(Descript.toLowerCase()) : true);
  });
}

selecionarFormaPagamentoPorCodigo(PayMethCod: string) {
  const formapagamento = this.formaspagamento.find(f => f.PayMethCod === PayMethCod);
  if (formapagamento) {
    this.pagamentoControl.setValue(formapagamento.Descript);
    this.PayMethCodSelecionado = formapagamento.PayMethCod;
   
  } 
}
  
  limparFiltros(): void {
    this.filtroForm.reset();
    this.clientesFiltrados= [...this.clientes];
  }
  
  cancel(): void {
    this.matDialogRef.close();
  }

    adicionarItem() {
  
  const nItem = this.nItemControl.value;
  const descricaoProduto = this.descricaoProdutoControl.value;
  const quantidade = this.quantidadeControl.value;
  const cod_venda=this.codControl.value;
  const numeroOC=this.numeroOCControl.value;
  const desconto = this.descontoControl.value;
  const aplicacao = this.aplicacaoControl.value;
  const cultura=this.culturaControl.value;
  const precoUnitario = this.precoUnitarioControl.value;
  const data = this.dataEntregaControl.value;
  const observacoes = this.observacoesControl.value;

      const item: Item = {
        nItem: nItem,
        descricaoProduto: descricaoProduto,
        numeroOC: numeroOC,
        quantidade: quantidade,
        desconto: desconto,
        aplicacao: aplicacao,
        cod_venda:cod_venda,
        cultura: cultura,
        precoUnitario: precoUnitario,
        data: data,
        observacoes: observacoes,
       
      };
    
      
      this.items.push(item);
      this.dadosTabela.push(item);
      this.changeDetectorRef.detectChanges();


      this.limparCampos();
    }
  
     limparCampos() {
      this.nItemControl.reset();
      this.descricaoProdutoControl.reset();
      this.quantidadeControl.reset();
      this.descontoControl.reset();
      this.aplicacaoControl.reset();
      this.culturaControl.reset();
      this.precoUnitarioControl.reset();
      this.dataEntregaControl.reset();
      this.observacoesControl.reset();
    }

   


  async carregarItems(): Promise<void> {
    try {
      this.loading = true;
      const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaItems').toPromise();
      this.items = response.listaItems;
      this.itemsFiltrados = [...this.items];
      this.loading = false;

    } catch (error) {
     
      this.loading = false;
    }
  }


filtrarItens(): void {
  const { nItemControl, descricaoProdutoControl } = this.filtroForm.value;
  this.itemsFiltrados = this.items.filter(item => {
    const itemCodeMatches = nItemControl ? item.ItemCode.toLowerCase().includes(nItemControl.toLowerCase()) : true;
    const descricaoMatches = descricaoProdutoControl ? (item.DescricaoDoProduto || '').toLowerCase().includes(descricaoProdutoControl.toLowerCase()) : true;
    return itemCodeMatches && descricaoMatches;
  });
}

displayItem(produto: any): string {
  return produto ? (produto.ItemCode && produto.DescricaoDoProduto ? `${produto.ItemCode} - ${produto.DescricaoDoProduto}` : produto.ItemCode || produto.DescricaoDoProduto || '') : '';

}
selecionarProduto(event: any) {
  const itemSelecionado = this.itemsFiltrados.find(item => item.ItemCode===event.option.value || item.ItemName=== event.option.value);
  if (itemSelecionado) {
    this.nItemControl.setValue(itemSelecionado.ItemCode);
    this.descricaoProdutoControl.setValue(itemSelecionado.ItemName);
    this.precoUnitarioControl.setValue(itemSelecionado.Price);
  }
  
}


async carregarVendedores():Promise<void>{
  try {
    this.loading = true;
    const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaVendedores').toPromise();
    this.vendedores = response.listaVendedores;
    this.vendedoresFiltrados = [...this.vendedores];
    this.loading = false;

    const salesPersonCode = this.data.pedido.SalesPersonCode;
    this.selecionarVendedorPorCodigo(salesPersonCode);
  } catch (error) {
   
    this.loading = false;
  }
}

filtrarVendedores():void{
  const {SlpCode,SlpName}=this.filtroForm.value;
    this.vendedoresFiltrados=this.vendedores.filter(vendedor=>{
    return(SlpCode ? vendedor.SlpCode.toLowerCase().includes(SlpCode.toLowerCase()):true)&&
          (SlpName ?(vendedor.SlpName || ''). toLowerCase().includes(SlpName.toLowerCase()):true);
    })
}

selecionarVendedor(event: any) {
  const selectedSlpCode = event.option.value;
  this.vendedor = event.option.value;
  this.data.orcamento.SalesPersonCode = selectedSlpCode; 

  const vendedor = this.vendedores.find(v => v.SlpCode.toString() === selectedSlpCode);
  if (vendedor) {
    this.vendedorControl.setValue(vendedor.SlpName);
    this.SlpCodeSelecionado = vendedor.SlpCode;
  }
  this.vendedorControl.setValue(this.vendedor.SlpName);
  
}

selecionarVendedorPorCodigo(salesPersonCode: string) {
  const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);  
  if (vendedor) {
    this.vendedorControl.setValue(vendedor.SlpName);  
    this.SlpCodeSelecionado = vendedor.SlpCode;       
   
  }
}


        async carregarCdsEntrega(): Promise<void> {
          try {
            this.loading = true;
            const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaFiliais').toPromise();
            this.cdsEntrega = response.listaFiliais;
            this.cdsEntregaFiltrados = [...this.cdsEntrega];
            this.loading = false;
          
          } catch (error) {
          
            this.loading = false;
          }
        }



        filtrarCdEntrega():void{
          const {BPLName,TaxIdNum}=this.filtroForm.value;
            this.cdsEntregaFiltrados=this.cdsEntrega.filter(cdEntrega=>{
            return(TaxIdNum ? cdEntrega.TaxIdNum.toLowerCase().includes(TaxIdNum.toLowerCase()):true)&&
                  (BPLName ?(cdEntrega.BPLName || '').toLowerCase().includes(BPLName.toLowerCase()):true);
            })

        }
      
          

          selecionarCdEntrega(event: any) {
            this.cdEntrega = event.option.value;
            
            this.CdEntregaControl.setValue(this.cdEntrega.BPLName);
            
        }
        async carregarMeioCultura(): Promise<void> {
          try {
            this.loading = true;
            const response = await this.http.get<any>(this.apiUrl + '/api/consulta/listaMeioCultura').toPromise();
            this.culturas = response.listaMeioCultura;
            this.culturasFiltradas = [...this.culturas];
            this.loading = false;
          } catch (error) {

            this.loading = false;
          }
        }



        filtrarCultura():void{
          const {Name,Code}=this.filtroForm.value;
            this.culturasFiltradas=this.culturas.filter(cultura=>{
            return(Code ? cultura.Code.toLowerCase().includes(Code.toLowerCase()):true)&&
                  (Name ?(cultura.Name || '').toLowerCase().includes(Name.toLowerCase()):true);
            })

        }

gerarCodPedido(event: any){

const opcaoSelecionada = event.value;
const partes = opcaoSelecionada.split(' '); 
const codigoVenda = partes[1]; 
const codigoPedido = codigoVenda + ' - ' +  this.contadorPedido; 

this.codPedidoControl.setValue(codigoPedido); 
this.contadorPedido++;
}


adicionarCadastro() {

const codPedido=this.codPedidoControl.value;
const codCliente=this.codClienteControl.value;
const cliente= this.clienteControl.value;
const cpf_cnpj = this.cpfControl.value;
const telefone = this.telefoneControl.value;
const fazenda = this.fazendaControl.value;
const inscricaoEstadual=this.inscricaoControl.value;
const endereco=this.enderecoControl.value;
const cod = this.codControl.value;
const vendedor = this.vendedorControl.value;
const formaPg=this.pagamentoControl.value;
const condicaoPg=this.pagamentoControl.value;
const localEntrega=this.fazendaControl.value;
const frete=this.freteControl.value;
const cdEntrega=this.CdEntregaControl.value;
const observacoes=this.observacoescadControl.value;
const texto=this.textoControl.value;



const cadastro: Cadastro = {
codPedido:codPedido,
codCliente:codCliente,
cliente:cliente,
cpf_cnpj:cpf_cnpj,
telefone:telefone,
fazenda:fazenda,
inscricaoEstadual:inscricaoEstadual,
endereco:endereco,
cod: cod,
vendedor:vendedor,
formaPg:formaPg,
condicaoPg:condicaoPg,
localEntrega:localEntrega,
frete:'ENTREGA',
cdEntrega:cdEntrega,
observacoes:observacoes,

};

this.cabecalho.push(cadastro);
this.changeDetectorRef.detectChanges();

}


}

