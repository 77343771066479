<div class="cancelar">

   
    <div class="mensagem" mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions>
        
      <button mat-button *ngIf="!data.showSuccessMessage"(click)="fechar()">Não</button>
      <button mat-button *ngIf="data.showSuccessMessage" (click)="close()">Fechar</button>
      <button mat-button *ngIf="!data.showSuccessMessage" (click)="close()"[mat-dialog-close]="true">Sim</button>
    </div>
    
</div>
