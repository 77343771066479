<div class="home">
  <div *ngIf="loading" class="loading-overlay">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
  </div>
    <div class="header">
        <div class="title">
            Orçamento Pendentes 
        </div>
      
        <div class="buttons">

          <button
        routerLink="/dashboard"
      class="px-4 py-2 border border-gray-200 bg-slate-100 hover:bg-slate-200 rounded-full font-bold text-gray-600">
    Voltar
  </button>
</div>
      
  </div>
  <div class="table-vendas" appearance="">
    <table>
      <thead>
        <tr>
          <th>Cliente</th>
          <th>Vencimento Orçamento</th>
          <th>Cod. Orçamento</th>
          <th>Status Orçamento</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let orcamento of orcamentosFiltrados" [hidden]="orcamento.DocStatus !== 'O'" (click)="selecionarLinha(orcamento)" [class.selected]="linhasSelecionadas === orcamento">
          <td>{{ orcamento.CardName }}</td>
          <td>{{ orcamento.DocDueDate | date:'dd/MM/yyyy' }}</td>
          <td>{{ orcamento.DocEntry }}</td>
          <td [style.color]="getStatusColor(orcamento.DocStatus, orcamento.CANCELED, orcamento.modificacoesSolicitadas)">
            {{ getStatus(orcamento.DocStatus, orcamento.CANCELED, orcamento.modificacoesSolicitadas) }}
          </td>
        </tr>
        <tr *ngIf="!loading && OrcamentosFiltradosVazio()">
          <td colspan="4" class="no-data">Nenhum Orçamento pendente disponível</td>
      </tr>   
      </tbody>
    </table>
  </div>
  </div>