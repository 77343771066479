<div class="pedido relative overflow-y-scroll w-full h-ful meu-dialog pt-0">
  <div *ngIf="loading" class="loading-overlay">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
  </div>
    <div class=" sidebar flex flex-0 items-center justify-between pt-0 h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">Pedido de Vendas</div>
        <button mat-icon-button="" class="mat-focus-indicator mat-icon-button mat-button-base" tabindex="-1"
        (click)="cancel()">
        <span class="mat-button-wrapper">
            <mat-icon role="img" class="mat-icon notranslate text-current mat-icon-no-color" aria-hidden="true" ng-reflect-svg-icon="heroicons_outline:x" data-mat-icon-type="svg" data-mat-icon-name="x" data-mat-icon-namespace="heroicons_outline">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">

        </path>
    </svg>
</mat-icon>
</span>
<span matripple="" 
class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]">

</span><span class="mat-button-focus-overlay">

</span>
</button>
</div>
    <div class="header">

    <div  class="title-cabecalho">
      Cabeçalho
      <mat-form-field class="campos_cod" appearance="outline">
        <mat-label class="label">Cod Pedido</mat-label>
        <input type="text" matInput [formControl]="codPedidoControl" [matAutocomplete]="autocodPedido" [readonly]="codPedidoControl.value!==null "   >
        <mat-autocomplete #autocodPedido="matAutocomplete" >
        
        </mat-autocomplete>
      </mat-form-field>
  
  </div>

  </div>
<div class=" cadastro">
  <div class="contents1">
    
    <mat-form-field class="campos_cod" appearance="outline">
      <mat-label class="label">Cod Cliente</mat-label>
      <input type="text" matInput [formControl]="codClienteControl" [matAutocomplete]="autocodCliente" [readonly]="codClienteControl.value!==null "   >
      <mat-autocomplete #autocodCliente="matAutocomplete" >
      
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="campos" appearance="outline">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <mat-label class="label">Cliente</mat-label>
   
      <input type="text" matInput [formControl]="clienteControl"[readonly]="clienteControl.value!==null ">
 
      
    </mat-form-field>
    <mat-form-field
    class="fuse-mat-no-subscript w-full sm:flex-auto sm:max-w-40 mr-2"  appearance="outline">
    <mat-label>Data de Vencimento</mat-label>
    <input
        matInput 
        [formControl]="dataVencimentoControl"
        [matDatepicker]="pickerScheduled"/>
    <mat-datepicker-toggle
        matSuffix
        [for]="pickerScheduled"></mat-datepicker-toggle>
    <mat-datepicker #pickerScheduled></mat-datepicker>
</mat-form-field>

</div>
<div class="contents2">
  <mat-form-field class="campos_cod" appearance="outline">
    <mat-label class="label">CPF/CNPJ</mat-label>
    <input type="text" matInput [formControl]="cpfControl"  [readonly]="cpfControl.value !== null">
  
  </mat-form-field>
  
  <mat-form-field class="campos_tel" appearance="outline">
    <mat-label class="label">Telefone</mat-label>
    <input type="text" matInput [formControl]="telefoneControl" [matAutocomplete]="autoTelefone"   [readonly]="telefoneControl.value !== null">
    <mat-autocomplete #autoTelefone="matAutocomplete" >
      <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente.Telefone">
        {{ cliente.Telefone }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
<mat-form-field class="campos" appearance="outline">
  <mat-label class="label">Fazenda</mat-label>
  <input type="text" matInput [formControl]="fazendaControl" [matAutocomplete]="autoFazenda" [readonly]="fazendaControl.value !== null">
  <mat-autocomplete #autoFazenda="matAutocomplete" >
    <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente.Fazenda">
      {{ cliente.U_CADF_Fazenda}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
  
<mat-form-field class="campos" appearance="outline">
  <mat-label class="label">Inscrição Estadual</mat-label>
  <input type="text" matInput [formControl]="inscricaoControl" [matAutocomplete]="autoFazenda" [readonly]="inscricaoControl.value ">
  <mat-autocomplete #autoFazenda="matAutocomplete" >
    <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente.Fazenda">
      {{ cliente.inscricao_estadual}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

           </div>

            <div class="contents3">
              <mat-form-field class="campos" appearance="outline">
                <mat-label class="label">Inscrição Municipal</mat-label>
                <input type="text" matInput [formControl]="inscricaoMunicipalControl" [matAutocomplete]="autoFazenda" [readonly]="fazendaControl.value ">
                <mat-autocomplete #autoFazenda="matAutocomplete" >
                  <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente.Fazenda">
                    {{ cliente.U_CADF_Fazenda}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              
              <mat-form-field class="campos" appearance="outline">
                <mat-label class="label">Endereço</mat-label>
                <input type="text" matInput [formControl]="enderecoControl" [matAutocomplete]="autoFazenda" [readonly]="enderecoControl.value !== null">
                <mat-autocomplete #autoFazenda="matAutocomplete" >
                  <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente.Fazenda">
                    {{ cliente.U_CADF_Fazenda}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>


              <mat-form-field class="campos-local" appearance="outline">
                <mat-label class="label">Local de Entrega</mat-label>
                <input type="text" matInput [formControl]="localEntregaControl" [matAutocomplete]="autoFazenda" [readonly]="fazendaControl.value !== null">
               <mat-autocomplete #autoFazenda="matAutocomplete" >
           
              </mat-autocomplete>

              </mat-form-field>

          <mat-form-field class="example-container accent" appearance="outline">
            <mat-label>Frete</mat-label>
            <input type="text" matInput [formControl]="freteControl"  [readonly]="freteControl.value !== null">

      </mat-form-field>
             </div>    
  
            <div class="contents34">
              <mat-form-field class="example-container cdEntrega accent" appearance="outline">
                <mat-label>Cd.Entrega</mat-label>
                <input type="text" matInput [formControl]="CdEntregaControl" [matAutocomplete]="autoCdEntrega" [readonly]="CdEntregaControl.value!==null ">
                <mat-autocomplete #autoCdEntrega="matAutocomplete" (optionSelected)="selecionarCdEntrega($event)" >
               <mat-option *ngFor="let cdEntrega of filteredCdEntrega" [value]="cdEntrega">
           {{ cdEntrega.BPLName}}
             </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field class="campo_vendedor" appearance="outline">
                <mat-label class="label">Vendedor</mat-label>
                <input type="text" matInput [formControl]="vendedorControl" [matAutocomplete]="autoVendedor" [readonly]="vendedorControl.value!==null ">
       <mat-autocomplete #autoVendedor="matAutocomplete" (optionSelected)="selecionarVendedor($event)">
         <mat-option *ngFor="let vendedor of filteredVendedores " [value]="vendedor">
           {{ vendedor.SlpName }}
         </mat-option>
       </mat-autocomplete>
     </mat-form-field>
           <mat-form-field class="campo-fin" appearance="outline">
             <mat-label class="label">Forma de Pagamento</mat-label>
             <input type="text" matInput [formControl]="pagamentoControl" [matAutocomplete]="autoPagamento" [readonly]="pagamentoControl.value==null ">
            <mat-autocomplete #autoPagamento="matAutocomplete" >
           <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente.Pagamento">
       {{ cliente.PymntGroup}}
     </mat-option>
   </mat-autocomplete>
           </mat-form-field> 
           <mat-form-field class="campo-fin" appearance="outline">
            <mat-label class="label">Condições de Pagamento</mat-label>
            <input type="text" matInput [formControl]="" [matAutocomplete]="autoPagamento" [readonly]="pagamentoControl.value==null ">
           <mat-autocomplete #autoPagamento="matAutocomplete" >
          <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente.Pagamento">
      {{ cliente.PymntGroup}}
    </mat-option>
  </mat-autocomplete>
          </mat-form-field>
    </div>
      <div class="contents4">
        <mat-form-field class="camposObs" appearance="outline">
          <mat-label class="label">Observações</mat-label>
          <input matInput placeholder="Placeholder" [formControl]="observacoescadControl" [readonly]="observacoescadControl.value!==null ">
        </mat-form-field>
        <mat-form-field class="camposObs" appearance="outline">
          <mat-label class="label">Texto Livre(Max. 100 caracteres)</mat-label>
          <input matInput placeholder="Placeholder" [formControl]="textoControl" [readonly]="textoControl.value!==null "> 
        </mat-form-field>
      </div>   
</div>
     <div class="header-itens">
      <div class="title1">Itens do Pedido<br></div>

    </div>
   
        <div class="itens">
          
            <div class="table-vendas" appearance="">
            <table>
              <thead>
                <tr>
                  <th>N Item</th>
                  <th>Descricao Do Produto</th>
                  <th>Quantidade</th>
                  <th>Quantidade em Aberto</th>
                  <th>Desconto</th>
                  <th>Aplicação</th>
                  <th>Cultura</th>
                  <th>Preço Unit.</th>
                  <th>Data de Entrega</th>
                  <th>Observações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of dadosTabela; let i = index" (click)="selecionarLinha(item)"  [class.selected]="linhasSelecionadas === item" >
                  <td>{{ item.nItem }}</td>
                  <td>{{ item.descricaoProduto }}</td>
                  <td>{{ item.quantidade  }}</td>
                  <td>{{item.quantidadeemaberto}}</td>
                  <td>{{ item.desconto }}</td>
                  <td>{{ item.aplicacao }}</td>
                  <td>{{item.cultura}}</td>
                  <td>{{ item.precoUnitario }}</td>
                  <td>{{item.data | date:'dd/MM/yyyy'  }}</td>
                  <td>{{ item.observacoes }}</td>
                </tr>
                  <tr *ngIf="dadosTabela.length === 0">
                    <td colspan="2">Nenhum dado disponível</td>
              </tbody>
            </table>
          </div>

        </div>
</div>
