import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'app/core/models/auth/user.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { FuseConfigService } from './../../../../@fuse/services/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userId: string = null;
  public userData: User;
  public userAdmin: boolean;
  public userDashboard: boolean;
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
  private host: string;

  constructor(
    private _httpClient: HttpClient,
    private _fuseConfigService: FuseConfigService,
    private domSanitizer: DomSanitizer
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.authHost;
    });
  }

  set user(value: User) {
 
    this.userId = value.id;
    this.userData = value;
    this.userAdmin = this.userData.roles.find(
      r => r.scope == 'admin' || r.scope == 'coordenador'
    );
    this.userDashboard = this.userData.roles.find(
      r =>
        r.scope == 'admin' ||
        r.scope == 'coordenador' ||
        r.scope == 'solubio' ||
        r.scope == 'comercial' ||
        r.scope == 'cliente'

    );

    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  get users$() {
    return this._users.asObservable();
  }

  get(): Observable<User> {
    return this._httpClient
      .get<User>(this.host + '/api/v1/user/' + this.userId)
      .pipe(
        tap(user => {
          this._user.next(user);
        })
      );
  }

  getUsers(
    offsetNumber: number,
    query: string = null,
    reset = false
  ): Observable<User[]> {
    let resetArray = reset;
    let paramFilter = {
      orderby: 'firstname',
      order: 'asc',
    };
    if (!query && offsetNumber >= 0) {
      paramFilter = { ...paramFilter, ...{ limit: 10, offset: offsetNumber } };
    }
    if (query) {
      offsetNumber = 0;
      resetArray = true;
      paramFilter = {
        ...paramFilter,
        ...{
          attr: 'firstname',
          like: query,
        },
      };
    }
    return this.users$.pipe(
      take(1),
      startWith([]),
      switchMap(usersList =>
        this._httpClient
          .get<User[]>(this.host + '/api/v1/user', {
            params: paramFilter,
          })
          .pipe(
            tap(users => {
              users = users.filter(u => !u.deleted_at);
              if (!resetArray && usersList) {
                users = [...usersList, ...users];
              }
              this._users.next(users);
            })
          )
      )
    );
  }

  updateUser(userId: string, user: User): Observable<any> {
    return this._httpClient
      .put<User>(this.host + '/api/v1/user/' + userId, user)
      .pipe(
        map(response => {
          this._user.next(response);
        })
      );
  }

  changePassword(data: any): Observable<any> {
    return this._httpClient.post<any>(this.host + '/auth/changepass', data);
  }

  createUser(user: any): Observable<any> {
    return this._httpClient
      .post<any>(this.host + '/auth/register', { user })
      .pipe(
        map(response => {
          this._user.next(response.user);
        })
      );
  }

  // searchUsers(firstname: string): Observable<User[]> {
  // 	let paramFilter = {};
  // 	if (firstname !== '') {
  // 		paramFilter = {
  // 			attr: 'firstname',
  // 			like: firstname,
  // 		};
  // 	}
  // 	return (
  // 		this._httpClient
  // 			.get<User[]>(this.host + '/api/v1/user?orderby=firstname&order=asc', {
  // 				params: paramFilter,
  // 			})
  // 			.pipe(
  // 				tap((users) => {
  // 					users = users.filter((u) => !u.deleted_at);
  // 					this._users.next(users);
  // 				})
  // 			)
  // 	);
  // }

  public getImage(photo): any {
    return (
      'data:image/jpg;base64,' +
      (this.domSanitizer.bypassSecurityTrustResourceUrl(photo) as any)
        .changingThisBreaksApplicationSecurity
    );
  }

  async getAllUsers() {
    let response = await this._httpClient
      .get<any>(this.host + '/api/v1/user?no_pict=')
      .toPromise();
    return response;
  }
}

export { User };
