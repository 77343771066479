import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { Message } from 'app/layout/common/messages/messages.types';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  private _messages: ReplaySubject<Message[]> = new ReplaySubject<Message[]>(1);

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------


  get messages$(): Observable<Message[]> {
    return this._messages.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

 
  getAll(): Observable<Message[]> {
    return this._httpClient.get<Message[]>('api/common/messages').pipe(
      tap(messages => {
        this._messages.next(messages);
      })
    );
  }

  /**
   * Create a message
   *
   * @param message
   */
  create(message: Message): Observable<Message> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages =>
        this._httpClient.post<Message>('api/common/messages', { message }).pipe(
          map(newMessage => {

            this._messages.next([...messages, newMessage]);

            return newMessage;
          })
        )
      )
    );
  }

  /**
   * Update the message
   *
   * @param id
   * @param message
   */
  update(id: string, message: Message): Observable<Message> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages =>
        this._httpClient
          .patch<Message>('api/common/messages', {
            id,
            message,
          })
          .pipe(
            map((updatedMessage: Message) => {

              const index = messages.findIndex(item => item.id === id);


              messages[index] = updatedMessage;

              this._messages.next(messages);
              return updatedMessage;
            })
          )
      )
    );
  }

  /**
   * Delete the message
   *
   * @param id
   */
  delete(id: string): Observable<boolean> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages =>
        this._httpClient
          .delete<boolean>('api/common/messages', { params: { id } })
          .pipe(
            map((isDeleted: boolean) => {

              const index = messages.findIndex(item => item.id === id);
              messages.splice(index, 1);
              this._messages.next(messages);
              return isDeleted;
            })
          )
      )
    );
  }


  markAllAsRead(): Observable<boolean> {
    return this.messages$.pipe(
      take(1),
      switchMap(messages =>
        this._httpClient
          .get<boolean>('api/common/messages/mark-all-as-read')
          .pipe(
            map((isUpdated: boolean) => {
              messages.forEach((message, index) => {
                messages[index].read = true;
              });
              this._messages.next(messages);
              return isUpdated;
            })
          )
      )
    );
  }
}
