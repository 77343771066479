
<div class="form">
<div *ngIf="loading" class="loading-overlay">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
  </div>
  <div class="actions">
  <div class="voltar">
    <button  *ngIf="!loading" mat-icon-button (click)="sair()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
 
  <div class="sair">

    <button *ngIf="!loading" mat-icon-button   (click)="sair()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
  <h2 class="Title">CheckList de Manutenção</h2>
  <div  class="Farm"   *ngIf="!selectedFarm; else selectedFarmTemplate">
    <mat-form-field class="camposObs" appearance="outline">
      <mat-label>Selecione a Fazenda</mat-label>
      <input matInput 
             [formControl]="farmControl" 
             [matAutocomplete]="auto" 
             (focus)="showOptions = true" 
             (blur)="onInputBlur($event)">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFarmName" (optionSelected)="onFarmSelected($event)">
        <mat-option *ngFor="let farm of filteredFarms$ | async" [value]="farm">
          {{ farm.farm_name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  
  
  <ng-template #selectedFarmTemplate>
    <p class="selected-farm" >{{ selectedFarm?.farm_name }}</p>
  </ng-template>
  
  <div *ngIf="selectedFarm" class="questions">
   
   
    <h2 class="question">Pergunta {{ currentQuestionIndex + 1 }} de {{ questionsFiltradas.length }}</h2>
    <div *ngIf="selectedFarm && questionsFiltradas.length > 0; else questions">
        <div>
            <h2 class="Secoes">{{nameSection}}</h2>

            <h3>{{ getCurrentQuestion()?.question }}</h3>

            <div class="respostas">
              
 
              <button mat-button 
                      [class.selected]="selectedAnswer === '1'" 
                      (click)="selectAnswer('1')">Sim</button>
              <button mat-button 
                      [class.selected]="selectedAnswer === '2'" 
                      (click)="selectAnswer('2')">Não</button>
              <button mat-button 
                      [class.selected]="selectedAnswer === '0'" 
                      (click)="selectAnswer('0')">Não Possui</button>

                      <br>


                      <mat-form-field   class="campotype" *ngIf="showEquipamentoSelect">
                        <mat-label class="campotype" appearance="outline">Equipamento:</mat-label>
                        <input type="text" matInput [formControl]="EquipmentControl" [matAutocomplete]="autoEquipment"  [value]="equipamento?.name">
                        <mat-autocomplete #autoEquipment="matAutocomplete"  [displayWith]="displayEquipamento">
                          <mat-option *ngIf="equipamentosFiltrados.length === 0" disabled>
                            Não existem equipamentos cadastrados.
                          </mat-option>
                          <mat-option *ngFor="let equipamento of equipamentosFiltrados" [value]="equipamento" (click)="selecionarEquipamentoPorId(equipamento.id)">
                            {{equipamento.inventory_number}} - {{equipamento.name}} - {{equipamento.type}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      
                    
                    <br>
                    
            </div>
            
                <div class="adicionais">
                  <div class="toggle-button" (click)="toggleView()">

                    <div class="toggle-handle" [class.active]="showUploadPhoto"></div>
                    <div class="toggle-label">
                      {{ showUploadPhoto ?  'Mostrar Observações':'Mostrar Upload de Foto'  }}
                    </div>
                    <mat-icon class="icon-camera" *ngIf="showUploadPhoto">photo_camera</mat-icon>
             
                    <mat-icon class="icon-pencil" *ngIf="!showUploadPhoto">edit</mat-icon>
                  </div>

              
                  <div class="file-upload-container" *ngIf="showUploadPhoto">
                    <label for="fileInput" class="file-upload-label">
                      <span class="file-upload-text">Anexar Foto</span>
                      <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/*" class="file-input">
                    </label>
                   
                    <span *ngIf="selectedFileName" class="file-upload-file-name">{{ selectedFileName }}</span>
                    
                    <div class="photo" *ngIf="question.photo">
                      <button mat-icon-button class="remove-photo-button" (click)="removePhoto()">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </div>
            
                      
                      
                    <mat-form-field class="camposObs" appearance="outline" *ngIf="!showUploadPhoto">
                        <mat-label class="label">Observações</mat-label>
                        <textarea matInput placeholder="Digite suas observações" [formControl]="form.get('observacoes')"></textarea>
                    </mat-form-field>
                </div>
                </div>
       
        
        
        <div class="navigation-buttons">
            <button mat-button class="ant" (click)="prevQuestion()" [disabled]="currentQuestionIndex === 0">Anterior</button>
    
    <button mat-flat-button=""
    class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary text-white bg-primary dark:text-white dark:bg-primary ng-star-inserted"
    ng-reflect-color="primary" (click)="nextQuestion()"> {{ currentQuestionIndex === questionsFiltradas.length - 1 ? 'Finalizar' : 'Próximo' }}
    <span class="mat-button-wrapper">
</span>
    <span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]">
    </span>
    <span class="mat-button-focus-overlay">
    </span>
</button>
        </div>
    </div>
    <div *ngIf="!loading && questionsFiltradas.length < 0">
    <ng-template #noQuestions>
        <p>Nenhuma pergunta encontrada.</p>
    </ng-template>
</div>
</div>
</div>