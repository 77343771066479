import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClosedSalesOrderComponent } from './closed-sales-order/closed-sales-order.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { SalesService } from '../sales.service';

@Component({
  selector: 'app-closed-sales',
  templateUrl: './closed-sales.component.html',
  styleUrls: ['./closed-sales.component.scss']
})
export class ClosedSalesComponent implements OnInit {
    pedidoControl=new FormControl();
    filteredPedidos:Observable<any[]>;
    loading: boolean = false;
    pedidos:any[]=[];
    pedidosFiltrados:any[]=[];
  filtroForm: FormGroup;
  ordenacaoCrescente:boolean = true;
  ordenacaoCrescenteDocNum:boolean = true;
  ordenacaoCrescenteCardName:boolean = true;
  linhasSelecionadas:  any;
  editarAtivo: boolean = false;
  
  clicavel = true;


  constructor(public dialog: MatDialog, private salesService: SalesService, private fb: FormBuilder) { }

  async ngOnInit(): Promise<void> {
    await this.carregarPedidos();
    
    this.filtroForm.valueChanges.subscribe(() => {
      this.filtrarPedidos();
    });

  
    this.filteredPedidos = this.pedidoControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value?.CardName),
      map(nome => nome ? this._filterPedidos(nome) : this.pedidos.slice())
    );
 


    this.filtroForm = this.fb.group({
      DocNum: [''],
      DocDate: [''],
      CardName: [''],
      CardCode: [''],
      DocStatus: [''],
      CANCELED: [''],
      DocEntry: [''],
      pedidoControl: this.pedidoControl
    });
  }
  
  async carregarPedidos(): Promise<void> {
    this.loading = true;
    try {
      const pedidos = await this.salesService.carregarVendas().toPromise();
      this.pedidos = pedidos.filter(pedido => pedido.DocStatus === 'O');
      this.pedidosFiltrados = [...this.pedidos];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
  
  private _filterPedidos(nome: string): any[] {
    const filterValue = nome ? nome.toLowerCase() : '';
    return this.pedidos.filter(pedido => pedido.CardName.toLowerCase().includes(filterValue));
  }
  
  filtrarPedidosPorCliente(): void {
    const clienteFiltro = this.pedidoControl.value;
    const filterValue = clienteFiltro ? clienteFiltro.toLowerCase() : '';
    if (filterValue && typeof filterValue === 'string') {
      this.pedidosFiltrados = this.pedidos.filter(pedido => pedido.CardName && pedido.CardName.toLowerCase().includes(filterValue));
    } else {
      this.pedidosFiltrados = [...this.pedidos];
    }
  }
  
  filtrarPedidos(): void {
    const { DocNum, DocDate, CardName, CardCode, DocStatus, CANCELED, DocEntry } = this.filtroForm.value;
    this.pedidosFiltrados = this.pedidos.filter(pedido => {
      return (DocNum ? pedido.DocNum.toLowerCase().includes(DocNum.toLowerCase()) : true) &&
             (DocDate ? pedido.DocDate.toLowerCase().includes(DocDate.toLowerCase()) : true) &&
             (CardName ? (pedido.CardName || '').toLowerCase().includes(CardName.toLowerCase()) : true) &&
             (CardCode ? (pedido.CardCode || '').toLowerCase().includes(CardCode.toLowerCase()) : true) &&
             (DocStatus ? (pedido.DocStatus || '').toLowerCase().includes(DocStatus.toLowerCase()) : true) &&
             (CANCELED ? (pedido.CANCELED || '').toLowerCase().includes(CANCELED.toLowerCase()) : true) &&
             (DocEntry ? (pedido.DocEntry || '').toLowerCase().includes(DocEntry.toLowerCase()) : true)&&
             pedido.DocStatus === 'O';
             
           });
          
         }
  selecionarLinha(linha: any): void {
   
    this.linhasSelecionadas = linha;
  
    if (linha && linha.DocNum ) { 
      
        this.editarAtivo=true;
     
      const pedidoSelecionado = this.pedidosFiltrados.find(pedido => pedido.DocNum === linha.DocNum);
  
    
      if (pedidoSelecionado ) {
        this.editarAtivo = true;
        this.openDialog(pedidoSelecionado);
      }
    
  }
  }

  openDialog(pedido): void {
    if (this.clicavel) {
      this.editarAtivo = true;
      this.clicavel = false; 
      this.loading=true;

      this.salesService.vendaDetails(pedido.DocEntry).subscribe(
        detalhes => {

          const dialogRef= this.dialog.open(ClosedSalesOrderComponent, {
            data: detalhes
          });
     
         
          dialogRef.afterClosed().subscribe(() => {
            this.editarAtivo = false;
            this.clicavel = true; 
          });
          this.loading = false;
        },
        error => {
         
          this.clicavel = true; 
          this.loading = false;
        }
      );
    }
  }
  
 
  ordenarPorData():void {
    if (this.ordenacaoCrescente) {
      
      this.pedidosFiltrados = this.pedidos.sort((a, b) => new Date(b.DocDate).getTime() - new Date(a.DocDate).getTime());
      
    } else {
      
      this.pedidosFiltrados = this.pedidos.sort((a, b) => new Date(a.DocDate).getTime() - new Date(b.DocDate).getTime());
    }
   
    this.ordenacaoCrescente = !this.ordenacaoCrescente;
  }

  ordenarPorCardName(): void {
    
   
    if (this.ordenacaoCrescenteCardName) {
      
      this.pedidosFiltrados = this.pedidos.sort((a, b) => new String(b.CardName).localeCompare(a.CardName));
    
    } else {
     
      this.pedidosFiltrados = this.pedidos.sort((a, b) => new String(a.CardName).localeCompare(b.CardName));
    }

    this.ordenacaoCrescenteCardName = !this.ordenacaoCrescenteCardName;

}


ordenarPorDocNum(): void {
 
  if (this.ordenacaoCrescenteDocNum) {
 
    this.pedidosFiltrados = this.pedidos.sort((a, b) => a.DocNum - b.DocNum);
  } else {

    this.pedidosFiltrados = this.pedidos.sort((a, b) => b.DocNum - a.DocNum);
  }

  this.ordenacaoCrescenteDocNum = !this.ordenacaoCrescenteDocNum;
}

getStatus(status: string, CANCELED: string): string {
  if (status === 'C') {
    if (CANCELED === 'Y') {
      return 'Cancelado';
    } else if (CANCELED === 'N') {
      return 'Concluído';
    } else {
      return 'Desconhecido';
    }
  } else if (status === 'O') {
    return 'Saldo a Entregar';
  } else {
    return 'Desconhecido';
  }
}

getStatusColor(status: string, CANCELED: string): string  {
  if (status === 'C') {
    if (CANCELED === 'Y') {
      return 'red';
    } else if (CANCELED === 'N') {
      return 'green';
    } else {
      return 'gray';
    }
  } else if (status === 'O') {
    return 'blue';
  } else {
    return 'Desconhecido';
  }
}
}
