import { tap, takeUntil, debounceTime, map, delay, take } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';

type Option = {
  value: string | number;
  label: string;
};

@Component({
  selector: 'select-search',
  templateUrl: './select-search.component.html',
})
export class SelectSearchComponent implements OnInit {
  @Input() initialOptions: Option[] = [];
  @Input() controlName: string;
  @Input() formGroup: FormGroup;
  @Input() label: string;
  @ViewChild('singleSelect') singleSelect: MatSelect;

  optionServerSideFilteringCtrl: FormControl = new FormControl();
  searching: boolean = false;
  filteredServerSideOptions: ReplaySubject<Option[]> = new ReplaySubject<
    Option[]
  >(1);

  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.filteredServerSideOptions.next(this.initialOptions.slice());
    this.setInitialValue();

    this.optionServerSideFilteringCtrl.valueChanges
      .pipe(
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.initialOptions) return [];

          return this.initialOptions.filter(
            option =>
              search == '' ||
              option.label.toLowerCase().includes(search.toLowerCase())
          );
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        filteredOptions => {
          this.searching = false;
          this.filteredServerSideOptions.next(filteredOptions);
        },
        () => (this.searching = false)
      );
  }

  protected setInitialValue(): void {
    this.filteredServerSideOptions
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {});
  }
}
